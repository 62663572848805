import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Col, Row, Spinner } from "reactstrap";
import * as Api from "@/api";
import ConfirmModalTypes, {
  ActionType,
} from "@/components/Common/ConfirmModalTypes";
import PageContainer from "@/components/Common/PageContainer";
import { useLocalizedMessage } from "@/helpers/hooks";
import AdminRow from "@/pages/MenuPermission/AdminRow";
import GroupRow, { Group } from "@/pages/MenuPermission/GroupRow";

type AdminAction = {
  type: ActionType;
  admin?: Api.Response.PermissionAdmin;
};

export const TABLE_HEADERS = [
  { key: "no", label: "No" },
  { key: "name", label: "권한명" },
  { key: "select", label: "선택" },
  { key: "modifier", label: "수정자" },
  { key: "modDate", label: "수정일자" },
];

const BREADCRUMB_ITEMS = [
  { title: "System Management", link: "#" },
  { title: "Permission Management", link: "#" },
];

const MenuPermission = () => {
  const getLocalizedMessage = useLocalizedMessage();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAdmins, setIsLoadingAdmins] = useState(false);

  const [selectedParent, setSelectedParent] =
    useState<Api.Response.GroupItem | null>(null);

  const [groupList, setGroupList] = useState<Api.Response.GroupItem[]>([]);
  const [newGroup, setNewGroup] = useState<Api.Request.AddGroup>({ name: "" });
  const [selectedGroup, setSelectedGroup] =
    useState<Api.Response.GroupItem | null>(null);
  const [originalGroupData, setOriginalGroupData] = useState<
    Api.Response.GroupItem[]
  >([]);
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>("");
  const [adminAction, setAdminAction] = useState<AdminAction>({
    type: "" as ActionType,
  });

  const [adminsList, setAdminsList] = useState<Api.Response.PermissionAdmin[]>(
    [],
  );
  const [selectedAdmin, setSelectedAdmin] = useState<number | null>(null);
  const [newAdmin, setNewAdmin] = useState<Api.Request.Admin>({
    roleIdx: null,
    adminIdx: null,
  });

  const getGroupList = async () => {
    setIsLoading(true);

    try {
      const response = await Api.MenuPermissionGroup.getList();
      setGroupList(response.data);
      setOriginalGroupData(response.data);
    } catch (error) {
      console.error("Failed fetching Group List");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getGroupList();
  }, []);

  const handleGroupClick = async (group: Api.Response.GroupItem) => {
    setSelectedParent(group);
  };

  const getAdminsList = async (roleIdx: number) => {
    setIsLoadingAdmins(true);
    try {
      const response = await Api.MenuPermissionAdmin.getList(roleIdx);
      setAdminsList(response.data);
    } catch (error) {
      console.error("Failed to fetch admins list:", error);
    } finally {
      setIsLoadingAdmins(false);
    }
  };

  useEffect(() => {
    if (selectedParent) {
      getAdminsList(selectedParent.roleIdx);
    }
  }, [selectedParent]);

  const handleChange = (updatedGroup: Group) => {
    if ("roleIdx" in updatedGroup) {
      setGroupList((prevList) =>
        prevList.map((group) =>
          group.roleIdx === updatedGroup.roleIdx
            ? { ...group, ...updatedGroup }
            : group,
        ),
      );
    } else {
      setNewGroup(updatedGroup);
    }
  };

  const openModal = (
    actionType: ActionType,
    group?: Api.Response.GroupItem,
  ) => {
    setAction(actionType);
    if (group) {
      setSelectedGroup(group);
    }
    setIsOpen(true);
  };

  const handleAdminModalOpen = (
    actionType: ActionType,
    admin?: Api.Response.PermissionAdmin,
  ) => {
    setAdminAction({ type: actionType, admin });
    setIsOpen(true);
  };

  const handleSaveGroup = async () => {
    setIsLoading(true);
    try {
      let response;
      if (action === "save") {
        response = await Api.MenuPermissionGroup.add({
          name: newGroup.name,
        });
        setNewGroup({ name: "" });
      } else if (action === "edit" && selectedGroup) {
        response = await Api.MenuPermissionGroup.update({
          roleIdx: selectedGroup.roleIdx,
          name: selectedGroup.name,
        });
      }

      const successMessage = getLocalizedMessage(response, "success");
      toast.success(successMessage, {
        autoClose: 3000,
      });
      await getGroupList();
      if (selectedParent) {
        getAdminsList(selectedParent.roleIdx);
      }
    } catch (error) {
      const errMessage = getLocalizedMessage(error, "error");
      toast.error(errMessage, {
        autoClose: 3000,
      });
      console.error("Failed to save group:", error);
      handleModalClose();
    } finally {
      setIsLoading(false);
      setIsOpen(false);
      setSelectedGroup(null);
      setAction("");
    }
  };

  const handleDeleteGroup = async () => {
    if (!selectedGroup?.roleIdx) return;
    setIsLoading(true);

    try {
      const response = await Api.MenuPermissionGroup.delete(
        selectedGroup?.roleIdx,
      );
      const successMessage = getLocalizedMessage(response, "success");
      toast.success(successMessage, { autoClose: 3000 });
      await getGroupList();
    } catch (error) {
      const errMessage = getLocalizedMessage(error, "error");
      console.error("Failed to delete group:", error);
      toast.error(errMessage, { autoClose: 3000 });
    } finally {
      setIsLoading(false);
      setIsOpen(false);
      setAction("");
    }
  };

  const handleModalClose = () => {
    setIsOpen(false);
    if (action === "save" || action === "edit" || action === "delete") {
      // Original group modal close logic
      setGroupList(originalGroupData);
      setSelectedGroup(null);
      setNewGroup({ name: "" });
    } else {
      // Admin modal close logic
      setNewAdmin({
        roleIdx: null,
        adminIdx: null,
      });
      setSelectedAdmin(null);
    }
    setAdminAction({ type: "" });
    setAction("");
  };

  const handleSave = async () => {
    if (action === "save" || action === "edit") {
      // Original group save logic
      await handleSaveGroup();
    } else if (adminAction.type === "save") {
      // Admin save logic
      setIsLoadingAdmins(true);
      try {
        const response = await Api.MenuPermissionAdmin.add({
          roleIdx: newAdmin.roleIdx,
          adminIdx: newAdmin.adminIdx,
        });

        const successMessage = getLocalizedMessage(response, "success");
        toast.success(successMessage, { autoClose: 3000 });
        if (newAdmin.roleIdx) {
          await getAdminsList(newAdmin.roleIdx);
        }
        setNewAdmin({
          adminIdx: null,
          roleIdx: null,
        });
        setSelectedAdmin(null);
        getGroupList();
      } catch (error) {
        const errMessage = getLocalizedMessage(error, "error");
        toast.error(errMessage, { autoClose: 3000 });
      } finally {
        setIsLoadingAdmins(false);
        setIsOpen(false);
        setAction("");
      }
    }
  };

  const handleDelete = async () => {
    if (action === "delete" && selectedGroup) {
      // Original group delete logic
      await handleDeleteGroup();
    } else if (adminAction.type === "delete" && adminAction.admin) {
      // Admin delete logic
      setIsLoadingAdmins(true);
      try {
        const response = await Api.MenuPermissionAdmin.delete({
          roleIdx: adminAction.admin.roleIdx,
          adminIdx: adminAction.admin.adminIdx,
        });

        const successMessage = getLocalizedMessage(response, "success");
        toast.success(successMessage, { autoClose: 3000 });
        if (selectedParent) {
          await getAdminsList(selectedParent.roleIdx);
        }
        getGroupList();
      } catch (error) {
        const errMessage = getLocalizedMessage(error, "error");
        toast.error(errMessage, { autoClose: 3000 });
      } finally {
        setIsLoadingAdmins(false);
        setIsOpen(false);
        setAction("");
      }
    }
  };

  return (
    <PageContainer
      breadcrumbItems={BREADCRUMB_ITEMS}
      title="Permission Management"
    >
      <Row>
        <Col className="col-12">
          <div className="card card-body mb-1">
            <h4>권한목록</h4>
            <div
              className="table-responsive overflow-row"
              style={{
                height: "400px",
                overflowY: "auto",
                boxShadow: "inset 0 -10px 5px -5px rgba(0, 0, 0, 0.1) ",
                scrollBehavior: "smooth",
              }}
            >
              <table
                className="table table-hover mb-0 text-center align-middle"
                style={{
                  minWidth: "33.125rem",
                }}
              >
                <thead
                  className="table-light"
                  style={{ position: "sticky", top: 0, zIndex: 1 }}
                >
                  <tr>
                    {TABLE_HEADERS.map((header) => (
                      <th key={header.key}>{header.label}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <GroupRow
                    data={newGroup}
                    onChange={handleChange}
                    openModal={() => openModal("save")}
                  />
                  {isLoading ? (
                    <tr
                      style={{
                        position: "absolute",
                        top: "90%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                      }}
                    >
                      <td>
                        <Spinner className="me-2" color="secondary" />
                      </td>
                    </tr>
                  ) : (
                    groupList.map((groupData) => (
                      <GroupRow
                        key={groupData.roleIdx}
                        data={groupData}
                        onChange={handleChange}
                        isExistingMenu
                        openModal={(action) => openModal(action, groupData)}
                        onRowClick={() => handleGroupClick(groupData)}
                        isSelected={
                          selectedParent?.roleIdx === groupData.roleIdx
                        }
                      />
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Row>
      <AdminRow
        data={adminsList}
        setNewAdmin={setNewAdmin}
        selectedParent={selectedParent}
        selectedAdmin={selectedAdmin}
        setSelectedAdmin={setSelectedAdmin}
        openModal={handleAdminModalOpen}
        isLoading={isLoadingAdmins}
      />
      <ConfirmModalTypes
        isLoading={isLoading}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        action={action || adminAction.type}
        handleSave={handleSave}
        handleDelete={handleDelete}
        onCancel={handleModalClose}
      />
      <ToastContainer />
    </PageContainer>
  );
};

export default MenuPermission;
