import React, { useState } from "react";
import { AvField } from "availity-reactstrap-validation";
import classNames from "classnames";
import { Button, Col, InputGroup, Label } from "reactstrap";

import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  .mb-3 {
    width: 100%; /* Ensures outer div respects full width */
  }
  position: relative;
`;

const CustomBtn = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

interface FormInputProps {
  label: string;
  placeholder?: string;
  min?: number;
  id: string;
  type: "text" | "number" | "email" | "password"; // Add other valid types if needed
  value: string | number;
  onChange?: (e: any) => void;
  onValidationError?: (id: string, error: string) => void;
  validate?: any;
  isRequired?: boolean;
  isFullWidthLabel?: boolean;
  isButton?: boolean;
  handleClick?: () => void;
  isDisabled?: boolean;
  initialErrorMessage?: string;
}

const FormInputTypes: React.FC<FormInputProps> = ({
  label,
  placeholder,
  min,
  id,
  type,
  value,
  onChange,
  onValidationError = () => {},
  validate,
  isRequired = false,
  isFullWidthLabel,
  isButton = false,
  handleClick,
  isDisabled = false,
  initialErrorMessage,
}) => {
  const [errorMessage, setErrorMessage] = useState(initialErrorMessage);

  const validateInput = (value: any) => {
    // First check if the field is required and empty
    if (isRequired && (!value || value.toString().trim() === "")) {
      const error = initialErrorMessage || "This field is required";
      setErrorMessage(error);
      onValidationError(id, error);
      return false;
    }

    // For number type, check the minimum value
    if (type === "number" && min !== undefined) {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue) && numericValue < min) {
        const error = `Minimum value is ${min}`;
        setErrorMessage(error);
        onValidationError(id, error);
        return false;
      }
    }

    setErrorMessage("");
    onValidationError(id, "");
    return true;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    validateInput(newValue);

    if (onChange) {
      onChange({
        target: {
          name: id,
          value: type === "number" ? parseFloat(newValue) || "" : newValue,
        },
      });
    }
  };

  return (
    <>
      <Label
        htmlFor={id}
        className={classNames("col-md-2", "col-form-label", {
          ["w-100"]: isFullWidthLabel,
        })}
      >
        {label}
        {isRequired && (
          <>
            (<span className="text-danger">*</span>)
          </>
        )}
      </Label>
      <Col>
        <InputGroup>
          <Container>
            <AvField
              min={min}
              data-type={type}
              type={type}
              placeholder={placeholder}
              id={id}
              name={id}
              value={value || ""}
              onChange={handleChange}
              validate={{
                ...validate,
                required: {
                  value: isRequired,
                  errorMessage: errorMessage,
                },
                min: {
                  value: min,
                  errorMessage: `Minimum value is ${min}`,
                },
              }}
              className={`form-control flex-grow-1 ${
                isDisabled ? "bg-light text-muted" : ""
              }`}
              disabled={isDisabled}
            />
            {isButton && (
              <CustomBtn onClick={handleClick}>
                <Button color="danger">
                  <i className="mdi mdi-reload"></i>
                  재발급
                </Button>
              </CustomBtn>
            )}
          </Container>
        </InputGroup>
      </Col>
    </>
  );
};

export default FormInputTypes;
