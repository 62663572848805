import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import useStore from "../zustandStore";

type AppRouteProps = {
  children: ReactNode;
  location?: Location;
};

const AppRoute = (props: AppRouteProps) => {
  const token = useStore((state: { token: string }) => state.token);

  if (!token) {
    return (
      <Navigate
        to={{
          pathname: "/login",
        }}
        state={{ from: props.location }}
      />
    );
  }
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default AppRoute;
