import { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import * as Api from "@/api";
import CommonControlPanel from "@/components/Common/CommonControlPanel";
import CustomTableContainerTypes, {
  CustomColumn,
} from "@/components/Common/CustomTableContainerTypes";
import useQueryParams, { useStateRef } from "@/helpers/hooks";
import { ImageCellTypes } from "@/pages/Utility/CustomCellsType";
import PageContainer from "../../components/Common/PageContainer";
import useAppTypesStore from "../../zustandStore/appTypesStore";
import { ClickableCell, DisplayCell } from "../Utility/CustomCells";
import { SHOW_OPTIONS } from "../Utility/Options";

const BREADCRUMB_ITEMS = [
  { title: "App Type Management", link: "#" },
  { title: "List", link: "#" },
];

const AppType = () => {
  const { appTypes, setAppTypes, loading, setLoading } = useAppTypesStore();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams: queryString.ParsedQuery<string> = queryString.parse(
    location.search,
  );

  const { pageSize, page, keyword, dateRange, parseQueryParam } =
    useQueryParams();

  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalApps, setTotalApps] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const searchKeywordRef = useStateRef(searchKeyword);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(dateRange);

  const [selectedShow, setSelectedShow] = useState(() => {
    const parsedShow = parseQueryParam(queryParams.show);
    return parsedShow !== null ? parsedShow : SHOW_OPTIONS[0].value;
  });
  const [clearTrigger, setClearTrigger] = useState(false);

  const handleRowClick = useCallback(
    (item: { applicationsIdx: number }) => {
      navigate(`/app-type/${item.applicationsIdx}${window.location.search}`);
    },
    [navigate],
  );

  const handleAddAppType = () => {
    navigate(`/app-type/new${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: selectedDates[0],
      endDt: selectedDates[1],
      show: selectedShow,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    selectedDates,
    searchKeyword,
    selectedShow,
    location.pathname,
    navigate,
  ]);

  const fetchAppTypes = useCallback(async () => {
    setLoading(true);

    try {
      const searchKeyword = searchKeywordRef.current;
      const requestData: Api.Request.GetApplicationList = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        displayFlag: selectedShow,
        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.Application.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalApps(response.paging.totalRecord);
      setAppTypes(response.data);
    } catch (error) {
      console.error("Error fetching App Types", error);
    } finally {
      setLoading(false);
    }
  }, [
    currentPage,
    customPageSize,
    selectedShow,
    selectedDates[1],
    clearTrigger,
  ]);

  useEffect(() => {
    fetchAppTypes();
  }, [fetchAppTypes]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchAppTypes();
  };

  const handleShowSearch = (newShow: number | string) => {
    setSelectedShow(newShow as number);
    setCurrentPage(0);
  };

  const handleDateSearch = (newDateRange: [string, string]) => {
    setSelectedDates(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setSelectedShow(SHOW_OPTIONS[0].value);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const selectProps = [
    {
      selectTitle: "표시여부",
      options: SHOW_OPTIONS,
      selectedOption: selectedShow,
      setSelectedOption: handleShowSearch,
    },
  ];

  const columns: CustomColumn<Api.Response.ApplicationItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "applicationsIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "이미지",
        accessor: "imgPath",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
        }: CellProps<Api.Response.ApplicationItem>) => (
          <ImageCellTypes value={value} />
        ),
      },
      {
        Header: "타입명",
        accessor: "name",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
          row,
        }: CellProps<Api.Response.ApplicationItem>) => (
          <ClickableCell
            value={value}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "표시여부",
        accessor: "displayFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
        }: CellProps<Api.Response.ApplicationItem>) => (
          <DisplayCell value={value} />
        ),
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
    ],
    [handleRowClick],
  );

  return (
    <PageContainer
      breadcrumbItems={BREADCRUMB_ITEMS}
      title="App Type Management"
    >
      <CommonControlPanel
        placeholder="어플리케이션 타입명, 등록자, 수정자명으로 검색해주세요."
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        clearFilters={clearFilters}
        onSearch={handleSearch}
        dateRange={selectedDates}
        setDateRange={handleDateSearch}
        select={selectProps}
      />
      <CustomTableContainerTypes
        btnTitle="타입 등록"
        sortByIdx="applicationsIdx"
        handleAddItem={handleAddAppType}
        columns={columns || []}
        data={appTypes || []}
        customPageSize={customPageSize}
        totalPage={totalPage}
        totalRecord={totalApps}
        setCustomPageSize={setCustomPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoading={loading}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
      />
    </PageContainer>
  );
};

export default AppType;
