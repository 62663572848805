import { ReactNode, useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import { Spinner } from "reactstrap";
import * as Api from "@/api";
import ChartBase from "./ChartBase";
import { formatNumber, getDate } from "./utils";

type ConnectionChartProps = {
  data: Api.Response.ModelProcessing | null;
  selectedDays?: number;
  onDaysSelection?: (days: number) => void;
  isHeader?: boolean;
  isFullWidth?: boolean;
  isLoading?: boolean;
  children?: ReactNode;
};

const DailyModelProcessing = ({
  data,
  selectedDays,
  onDaysSelection,
  isHeader,
  isFullWidth = false,
  isLoading,
  children,
}: ConnectionChartProps) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstanceRef = useRef<ApexCharts | null>(null);

  useEffect(() => {
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!chartRef.current || !data || isLoading) return;

    const chartOptions = {
      series: [
        {
          name: "모델요청수",
          data: data?.cntList,
        },
      ],
      chart: {
        type: "area",
        height: 290,
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 7,
          left: 1,
          blur: 3,
          color: ["#000", "#000"],
          opacity: 0.1,
        },
      },
      grid: {
        show: true,
        borderColor: "#f1f1f1",
        strokeDashArray: 3,
      },
      colors: ["rgb(12,86,216)", "rgba(64, 125, 240, 0.05)"],
      fill: {
        type: ["gradient"],
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.4,
          opacityTo: 0.1,
          stops: [0, 90, 100],

          colorStops: [
            [
              {
                offset: 0,
                color: "rgba(64, 125, 240, 0.05)",
                opacity: 1,
              },
              {
                offset: 75,
                color: "rgba(64, 125, 240, 0.05)",
                opacity: 1,
              },
              {
                offset: 100,
                color: "rgba(64, 125, 240, 0.05)",
                opacity: 1,
              },
            ],
          ],
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "35%",
          borderRadius: "4",
        },
      },
      stroke: {
        curve: "smooth",
        width: [1.5, 1.5],
        lineCap: "round",
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: "top",
        offsetX: 0,
        offsetY: 8,
        markers: {
          width: 8,
          height: 8,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        Show: true,
        labels: {
          show: true,
          formatter: formatNumber,
        },
      },
      xaxis: {
        show: false,
        type: "day",
        categories: data?.labelList,
        labels: {
          formatter: getDate,
        },
        axisBorder: {
          show: false,
          color: "rgba(119, 119, 142, 0.05)",
          offsetX: 0,
          offsetY: 0,
        },
      },
    };

    // If chart instance exists, update it instead of creating a new one
    if (chartInstanceRef.current) {
      chartInstanceRef.current.updateOptions(chartOptions, true, true);
      chartInstanceRef.current.updateSeries([{ data: data?.cntList }], true);
    } else {
      // Create new chart instance if it doesn't exist
      chartInstanceRef.current = new ApexCharts(chartRef.current, chartOptions);
      chartInstanceRef.current.render();
    }
  }, [data]);

  return (
    <ChartBase
      title="일자별 모델처리현황"
      tooltipId="modelProcessing"
      tooltipContent="선택기간동안 처리된 모델의 수입니다."
      selectedDays={selectedDays}
      onDaysSelection={onDaysSelection}
      dayOptions={[30, 60, 90]}
      redirectUrl="/daily-model-processing"
      minHeight="20.625rem"
      isHeader={isHeader}
      isFullWidth={isFullWidth}
    >
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center position-absolute top-0 start-0 end-0 bottom-0">
          <Spinner className="me-2" color="secondary" />
        </div>
      )}
      <div ref={chartRef}></div>
      {children}
    </ChartBase>
  );
};

export default DailyModelProcessing;
