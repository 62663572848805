import { create } from "zustand";
import * as Api from "@/api";

type MaterialStoreState = {
  materials: Api.Response.MaterialItem[];
  loading: boolean;
  error: string | null;
  setMaterials: (materials: Api.Response.MaterialItem[]) => void;
  setLoading: (loading: boolean) => void;
};

const useMaterialStore = create<MaterialStoreState>((set) => ({
  materials: [],
  loading: false,
  error: null,
  setMaterials: (materials) => set({ materials }),
  setLoading: (loading) => set({ loading }),
}));

export default useMaterialStore;
