import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import EmailExpiration from "@/pages/EmailExpiration";
import EmailTemplate from "@/pages/EmailTemplate";
import EmailTemplateForm from "@/pages/EmailTemplate/EmailTemplateForm";
import LoginRestriction from "@/pages/LoginRestriction";
import MaterialManufacturer from "@/pages/MaterialManufacturer";
import MaterialManufacturerForm from "@/pages/MaterialManufacturer/MaterialManufacturerForm";
import MenuControl from "@/pages/MenuControl";
import MenuPermission from "@/pages/MenuPermission";
import Categories from "@/pages/NoticeBoard/Category";
import Faq from "@/pages/Operations/Faq";
import FaqForm from "@/pages/Operations/Faq/FaqForm";
import Qna from "@/pages/Operations/Qna";
import QnaDetails from "@/pages/Operations/Qna/QnaDetails";
import TermsConditions from "@/pages/Operations/TermsConditions";
import TermsConditionsForm from "@/pages/Operations/TermsConditions/TermsConditionsForm";
import PermissionMapping from "@/pages/PermissionMapping/Index";
import PrinterManufacturer from "@/pages/PrinterManufacturer";
import PrinterManufacturerForm from "@/pages/PrinterManufacturer/PrinterManufacturerForm";
import SendEmail from "@/pages/SendEmail";
import EmailDetails from "@/pages/SendEmail/EmailDetails";
import EmailList from "@/pages/SendEmail/EmailList";
import ActiveUserRateStatus from "@/pages/Statistics/ActiveUserRateStatus";
import ActiveUserWeekRateStatus from "@/pages/Statistics/ActiveUserWeekRateStatus";
import ApiRequestStatus from "@/pages/Statistics/ApiRequestStatus";
import ConnectionByDay from "@/pages/Statistics/ConnectionByDay";
import ConnectionByKoreaRegion from "@/pages/Statistics/ConnectionByKoreaRegion";
import ConnectionBySeoulRegion from "@/pages/Statistics/ConnectionBySeoulRegion";
import ConnectionByTime from "@/pages/Statistics/ConnectionByTime";
import ConnectionStatus from "@/pages/Statistics/ConnectionStatus";
import ModelProcessing from "@/pages/Statistics/ModelProcessing";
import RecentUsersList from "@/pages/Statistics/RecentUsersList";
import RegistrationStatus from "@/pages/Statistics/RegistrationStatus";
import RequestingCountries from "@/pages/Statistics/RequestingCountries";
import RequestingRegions from "@/pages/Statistics/RequestingRegions";
import RequestModelRanking from "@/pages/Statistics/RequestModelRanking";
import RequestRegionRanking from "@/pages/Statistics/RequestRegionRanking";
import TopUserRanking from "@/pages/Statistics/TopUserRanking";
import UsageByFunctionStatus from "@/pages/Statistics/UsageByFunction";
import Account from "../pages/Account";
import AccountForm from "../pages/Account/AccountForm";
import AppEquipment from "../pages/AppEquipment";
import AppEquipmentForm from "../pages/AppEquipment/AppEquipmentForm";
import AppType from "../pages/AppType";
import AppTypeForm from "../pages/AppType/AppTypeForm";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import Calendar from "../pages/Calendar/Calendar";
import Chat from "../pages/Chat/Chat";
import Customers from "../pages/Customers";
import CustomerForm from "../pages/Customers/CustomerForm";
import Dashboard from "../pages/Dashboard/index";
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct";
import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index";
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index";
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail";
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index";
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index";
import EditRequest from "../pages/EditRequest";
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormElements from "../pages/Forms/FormElements";
import FormMask from "../pages/Forms/FormMask";
import FormUpload from "../pages/Forms/FormUpload";
import FormValidations from "../pages/Forms/FormValidations";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";
import DripiIcons from "../pages/Icons/DripiIcons";
import FontAwesome from "../pages/Icons/FontAwesome";
import MaterialDesign from "../pages/Icons/MaterialDesign";
import RemixIcons from "../pages/Icons/RemixIcons";
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";
import Material from "../pages/Material";
import MaterialForm from "../pages/Material/MaterialForm";
import Notice from "../pages/NoticeBoard/Notice";
import NoticeForm from "../pages/NoticeBoard/Notice/NoticeForm";
import Printer from "../pages/Printer";
import PrinterForm from "../pages/Printer/PrinterForm";
import PrintingProfile from "../pages/PrintingProfile";
import PrintingProfileForm from "../pages/PrintingProfile/PrintingProfileForm";
import Project from "../pages/Project";
import SlicingRequest from "../pages/Slicing";
import SupportProfile from "../pages/SupportProfile";
import SupportProfileForm from "../pages/SupportProfile/SupportProfileForm";
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import EditableTables from "../pages/Tables/EditableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import UiNotifications from "../pages/Ui/ui-notifications";
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiRating from "../pages/Ui/UiRating";
import UIRoundSlider from "../pages/Ui/UIRoundSlider";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import Users from "../pages/Users";
import UsersDashboard from "../pages/Users/Dashboard";
import UserForm from "../pages/Users/UserForm";
import CommingSoon from "../pages/Utility/CommingSoon";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";
import FAQs from "../pages/Utility/FAQs";
import Maintenance from "../pages/Utility/Maintenance";
import Pricing from "../pages/Utility/Pricing";
import StarterPage from "../pages/Utility/StarterPage";
import Timeline from "../pages/Utility/Timeline";
import Version from "../pages/Version";
import VersionForm from "../pages/Version/VersionForm";

type RouteConfig = {
  path: string;
  component: ReactNode;
  exact?: boolean;
};

const authProtectedRoutes: RouteConfig[] = [
  //Users
  { path: "/users", component: <Users /> },
  { path: "/users/new", component: <UserForm /> },
  { path: "/users/:userIdx", component: <UserForm isDetailView /> },
  { path: "/users/dashboard/:userIdx", component: <UsersDashboard /> },
  //Material
  { path: "/material", component: <Material /> },
  { path: "/material/new", component: <MaterialForm /> },
  { path: "/material/:materialIdx", component: <MaterialForm isDetailView /> },
  //Printer
  { path: "/printer", component: <Printer /> },
  { path: "/printer/new", component: <PrinterForm /> },
  { path: "/printer/:printerIdx", component: <PrinterForm isDetailView /> },
  //Printing Profile
  { path: "/printing-profile", component: <PrintingProfile /> },
  { path: "/printing-profile/new", component: <PrintingProfileForm /> },
  {
    path: "/printing-profile/:printingIdx",
    component: <PrintingProfileForm isDetailView />,
  },
  //Support Profile
  { path: "/support-profile", component: <SupportProfile /> },
  { path: "/support-profile/new", component: <SupportProfileForm /> },
  {
    path: "/support-profile/:supportIdx",
    component: <SupportProfileForm isDetailView />,
  },
  //App Type
  { path: "/app-type", component: <AppType /> },
  { path: "/app-type/new", component: <AppTypeForm /> },
  {
    path: "/app-type/:applicationsIdx",
    component: <AppTypeForm isDetailView />,
  },

  //Material Mafufacturer
  { path: "/material-manufacturer", component: <MaterialManufacturer /> },
  {
    path: "/material-manufacturer/new",
    component: <MaterialManufacturerForm />,
  },
  {
    path: "/material-manufacturer/:mmIdx",
    component: <MaterialManufacturerForm isDetailView />,
  },

  //Printer Mafufacturer
  { path: "/printer-manufacturer", component: <PrinterManufacturer /> },
  {
    path: "/printer-manufacturer/new",
    component: <PrinterManufacturerForm />,
  },
  {
    path: "/printer-manufacturer/:pmIdx",
    component: <PrinterManufacturerForm isDetailView />,
  },

  //Version Management
  { path: "/app-version", component: <Version /> },
  { path: "/app-version/new", component: <VersionForm /> },
  { path: "/app-version/:versionIdx", component: <VersionForm isDetailView /> },

  // Login Restriction
  { path: "/login-restriction", component: <LoginRestriction /> },

  // Email Expiration
  { path: "/email-expiration", component: <EmailExpiration /> },

  //Customer
  { path: "/customer", component: <Customers /> },
  { path: "/customer/new", component: <CustomerForm /> },
  { path: "/customer/:clientIdx", component: <CustomerForm isDetailView /> },

  //App-Equipment
  { path: "/app-equipment", component: <AppEquipment /> },
  { path: "/app-equipment/new", component: <AppEquipmentForm /> },
  {
    path: "/app-equipment/:appIdx",
    component: <AppEquipmentForm isDetailView />,
  },

  //Slicing Requests
  { path: "/slicing-request", component: <SlicingRequest /> },

  //Statistics - Charts
  { path: "/connection-status", component: <ConnectionStatus /> },
  { path: "/registration-status", component: <RegistrationStatus /> },
  { path: "/api-request-status", component: <ApiRequestStatus /> },
  { path: "/request-model-ranking", component: <RequestModelRanking /> },
  { path: "/connection-by-time", component: <ConnectionByTime /> },
  { path: "/connection-by-day", component: <ConnectionByDay /> },
  { path: "/connection-seoul-region", component: <ConnectionBySeoulRegion /> },
  { path: "/connection-korea-region", component: <ConnectionByKoreaRegion /> },
  { path: "/request-region-ranking", component: <RequestRegionRanking /> },
  { path: "/top-user-ranking", component: <TopUserRanking /> },
  { path: "/active-user-rate", component: <ActiveUserRateStatus /> },
  { path: "/active-user-week-rate", component: <ActiveUserWeekRateStatus /> },
  { path: "/recent-users", component: <RecentUsersList /> },
  { path: "/daily-model-processing", component: <ModelProcessing /> },
  { path: "/requesting-countries", component: <RequestingCountries /> },
  { path: "/requesting-regions", component: <RequestingRegions /> },

  { path: "/usage-by-function", component: <UsageByFunctionStatus /> },

  //Project Management
  { path: "/project", component: <Project /> },

  //Edit Request Management
  { path: "/edit-request", component: <EditRequest /> },

  //Account Management
  { path: "/account", component: <Account /> },
  { path: "/account/new", component: <AccountForm /> },
  { path: "/account/:adminIdx", component: <AccountForm isDetailView /> },

  // Faqs
  { path: "/faq", component: <Faq /> },
  { path: "/faq/new", component: <FaqForm /> },
  { path: "/faq/:faqIdx", component: <FaqForm isDetailView /> },

  //Q&A Management
  { path: "/qna", component: <Qna /> },
  { path: "/qna/:qnaIdx", component: <QnaDetails /> },

  //Terms & Conditions Management
  { path: "/terms-conditions", component: <TermsConditions /> },
  { path: "/terms-conditions/new", component: <TermsConditionsForm /> },
  {
    path: "/terms-conditions/:termsIdx",
    component: <TermsConditionsForm isDetailView />,
  },

  // Send Email
  { path: "/send-email", component: <SendEmail /> },
  { path: "/email-history", component: <EmailList /> },
  { path: "/email-history/:histIdx", component: <EmailDetails /> },

  // Email Template Management
  { path: "/email-template", component: <EmailTemplate /> },
  { path: "/email-template/new", component: <EmailTemplateForm /> },
  {
    path: "/email-template/:tempIdx",
    component: <EmailTemplateForm isDetailView />,
  },

  // Notice  Management
  { path: "/category", component: <Categories /> },
  { path: "/notice-board", component: <Notice /> },
  { path: "/notice-board/new", component: <NoticeForm /> },
  { path: "/notice-board/:boardIdx", component: <NoticeForm isDetailView /> },

  // Menu Management
  { path: "/menu", component: <MenuControl /> },

  // Permission Management
  { path: "/permission", component: <MenuPermission /> },

  // Permission Mapping Management
  { path: "/permission-mapping", component: <PermissionMapping /> },

  // Tables
  { path: "/basic-tables", component: <BasicTables /> },
  { path: "/datatable-table", component: <DatatableTables /> },
  { path: "/responsive-table", component: <ResponsiveTables /> },
  { path: "/editable-table", component: <EditableTables /> },

  // Ui
  { path: "/ui-alerts", component: <UiAlert /> },
  { path: "/ui-buttons", component: <UiButtons /> },
  { path: "/ui-cards", component: <UiCards /> },
  { path: "/ui-carousel", component: <UiCarousel /> },
  { path: "/ui-dropdowns", component: <UiDropdown /> },
  { path: "/ui-general", component: <UiGeneral /> },
  { path: "/ui-grid", component: <UiGrid /> },
  { path: "/ui-images", component: <UiImages /> },
  { path: "/ui-lightbox", component: <UiLightbox /> },
  { path: "/ui-modals", component: <UiModal /> },
  // {path: "/ui-offcanvas", component: <UiDrawer/>},
  { path: "/ui-progressbars", component: <UiProgressbar /> },
  { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
  { path: "/ui-typography", component: <UiTypography /> },
  { path: "/ui-video", component: <UiVideo /> },
  { path: "/ui-session-timeout", component: <UiSessionTimeout /> },
  { path: "/ui-rating", component: <UiRating /> },
  { path: "/ui-rangeslider", component: <UiRangeSlider /> },
  { path: "/ui-notifications", component: <UiNotifications /> },
  { path: "/ui-roundslider", component: <UIRoundSlider /> },

  // Forms
  { path: "/form-elements", component: <FormElements /> },
  { path: "/form-advanced", component: <FormAdvanced /> },
  { path: "/form-editors", component: <FormEditors /> },
  { path: "/form-mask", component: <FormMask /> },
  { path: "/form-file-upload", component: <FormUpload /> },
  { path: "/form-wizard", component: <FormWizard /> },
  { path: "/form-validation", component: <FormValidations /> },
  { path: "/form-xeditable", component: <FormXeditable /> },

  //Utility
  { path: "/starter", component: <StarterPage /> },
  { path: "/timeline", component: <Timeline /> },
  { path: "/faqs", component: <FAQs /> },
  { path: "/pricing", component: <Pricing /> },

  //Icons
  { path: "/icons-remix", component: <RemixIcons /> },
  { path: "/material-design", component: <MaterialDesign /> },
  { path: "/dripicons", component: <DripiIcons /> },
  { path: "/font-awesome-5", component: <FontAwesome /> },

  // Maps
  { path: "/google-maps", component: <MapsGoogle /> },
  { path: "/vector-maps", component: <MapsVector /> },

  //Email
  { path: "/email-inbox", component: <EmailInbox /> },
  { path: "/email-read", component: <EmailRead /> },

  //Ecommerce

  { path: "/ecommerce-products", component: <EcommerceProducts /> },
  {
    path: "/ecommerce-product-detail/:id",
    component: <EcommerceProductDetail />,
  },
  { path: "/ecommerce-orders", component: <EcommerceOrders /> },
  { path: "/ecommerce-customers", component: <EcommerceCustomers /> },
  { path: "/ecommerce-cart", component: <EcommerceCart /> },
  { path: "/ecommerce-checkout", component: <EcommerceCheckout /> },
  { path: "/ecommerce-shops", component: <EcommerceShops /> },
  { path: "/ecommerce-add-product", component: <EcommerceAddProduct /> },

  //chat
  { path: "/chat", component: <Chat /> },

  //calendar
  { path: "/calendar", component: <Calendar /> },

  { path: "/dashboard", component: <Dashboard /> },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
];

const publicRoutes: RouteConfig[] = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/lock-screen", component: <AuthLockScreen /> },

  // Authentication Inner
  { path: "/auth-login", component: <Login1 /> },
  { path: "/auth-register", component: <Register1 /> },
  { path: "/auth-recoverpw", component: <ForgetPwd1 /> },

  { path: "/maintenance", component: <Maintenance /> },
  { path: "/comingsoon", component: <CommingSoon /> },
  { path: "/404", component: <Error404 /> },
  { path: "/500", component: <Error500 /> },
];

export { authProtectedRoutes, publicRoutes };
