import * as Api from "@/api";
import DailyModelProcessing from "@/pages/Dashboard/DailyModelProcessing";
import StatisticsBase from "./StatisticsBase";

const ModelProcessing = () => {
  return (
    <>
      <StatisticsBase
        title="Daily Model Processing"
        defaultRange={30}
        columns={[
          { key: "label", name: "Date", label: "일자" },
          { key: "count", name: "Number of requests", label: "요청수" },
        ]}
        ChartComponent={DailyModelProcessing}
        errorMessage="Failed fetching Api Request Daily Model Processing data"
        onRequestData={Api.DailyModelProcessing.get}
        getDataList={(data) => data?.list || []}
        getDataForExport={(data) => data?.list || []}
      />
    </>
  );
};

export default ModelProcessing;
