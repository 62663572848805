import React, { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";

import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import { Button } from "reactstrap";
import * as Api from "@/api";
import CommonControlPanel from "@/components/Common/CommonControlPanel";
import CustomTableContainerTypes, {
  CustomColumn,
} from "@/components/Common/CustomTableContainerTypes";
import useQueryParams from "@/helpers/hooks";
import { ImageCellTypes } from "@/pages/Utility/CustomCellsType";
import PageContainer from "../../components/Common/PageContainer";
import useUserStore from "../../zustandStore/userStore";
import "../../components/Common/customStyles.css";

import { ClickableCell } from "../Utility/CustomCells";
import UsageActivityModal from "./components/UsageActivityModal";

export const MEMBER_STATUS_OPTIONS = [
  { label: "전체", value: -1 },
  { label: "정상", value: 0 },
  { label: "탈퇴", value: 1 },
  { label: "정지", value: 2 },
];

const REGISTRATION_OPTIONS = [
  { label: "전체", value: -1 },
  { label: "자체가입", value: 0 },
  { label: "관리자등록", value: 1 },
];

export const DateCell = ({ value }: { value: string }) => (
  <span>{format(new Date(value), "yyyy-MM-dd")}</span>
);

export const StatusCell = ({ value }: { value: number }) => {
  let statusColor = "";
  switch (value) {
    case 0: // 정상
      statusColor = "#1cbb8c";
      break;
    case 1: // 탈퇴
      statusColor = "#ff3d5f";
      break;
    case 2: // 정지
      statusColor = "#75788d";
      break;
    default:
      statusColor = "black";
      break;
  }

  return (
    <span style={{ color: statusColor }}>
      {MEMBER_STATUS_OPTIONS.find((option) => option.value === value)?.label}
    </span>
  );
};

const UserRegTypeCell = ({ value }: { value: number }) => {
  let regTypeColor = "";
  switch (value) {
    case 0: // 자체가입
      regTypeColor = "black";
      break;
    case 1: // 관리자등록
      regTypeColor = "#5664d2";
      break;
    default:
      regTypeColor = "black";
      break;
  }

  return (
    <span style={{ color: regTypeColor }}>
      {REGISTRATION_OPTIONS.find((option) => option.value === value)?.label}
    </span>
  );
};

const BREADCRUMB_ITEMS = [
  { title: "Members", link: "#" },
  { title: "List", link: "#" },
];

const Users = () => {
  const { users, setUsers, loading, setLoading } = useUserStore();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const { pageSize, page, keyword, dateRange, parseQueryParam } =
    useQueryParams();
  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(dateRange);
  const [selectedStatus, setSelectedStatus] = useState(
    parseQueryParam(queryParams.status) ?? MEMBER_STATUS_OPTIONS[0].value,
  );
  const [selectedRegType, setSelectedRegType] = useState(() => {
    const parsedRegType = parseQueryParam(queryParams.regType);
    return parsedRegType !== null
      ? parsedRegType
      : REGISTRATION_OPTIONS[0].value;
  });
  const [clearTrigger, setClearTrigger] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<number | null>(null);
  const [searchTrigger, setSearchTrigger] = useState(0);

  const handleRowClick = useCallback(
    (item: { userIdx: number }) => {
      navigate(`/users/${item.userIdx}${window.location.search}`);
    },
    [navigate],
  );

  const handleAddUser = () => {
    navigate(`/users/new${window.location.search}`);
  };

  const openUserActivity = (item: Api.Response.UserItem) => {
    setSelectedUser(item?.userIdx);
    setIsModalOpen(true);
  };

  const moveToDashBoard = (item: Api.Response.UserItem) => {
    navigate(`/users/dashboard/${item.userIdx}${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: selectedDates[0],
      endDt: selectedDates[1],
      status: selectedStatus,
      regType: selectedRegType,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    searchKeyword,
    selectedDates,
    selectedStatus,
    selectedRegType,
    navigate,
    location.pathname,
  ]);

  const fetchUsers = useCallback(async () => {
    setLoading(true);

    try {
      const requestData = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        stateFlag: selectedStatus,
        regFlag: selectedRegType,
        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };
      const response = await Api.User.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalUsers(response.paging.totalRecord);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching Users", error);
    } finally {
      setLoading(false);
    }
  }, [
    currentPage,
    customPageSize,
    selectedRegType,
    selectedStatus,
    selectedDates[1],
    searchTrigger,
    clearTrigger,
  ]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const urlKeyword = (queryParams.keyword as string) || "";

    if (urlKeyword !== searchKeyword) {
      setSearchKeyword(urlKeyword);
      setSearchTrigger((prev) => prev + 1); // Force a fetch on URL keyword change
    }
  }, [location.search]);

  const handleSearch = () => {
    setCurrentPage(0);
    setSearchTrigger((prev) => prev + 1);
  };

  const handleStatusSearch = (newStatus: number | string) => {
    setSelectedStatus(newStatus as number);
    setCurrentPage(0);
  };

  const handleRegTypeSearch = (newRegType: number | string) => {
    setSelectedRegType(newRegType as number);
    setCurrentPage(0);
  };

  const handleDateSearch = (newDateRange: [string, string]) => {
    setSelectedDates(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = async () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setSelectedStatus(MEMBER_STATUS_OPTIONS[0].value);
    setSelectedRegType(REGISTRATION_OPTIONS[0].value);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const selectProps = [
    {
      selectTitle: "회원 상태",
      options: MEMBER_STATUS_OPTIONS,
      selectedOption: selectedStatus,
      setSelectedOption: handleStatusSearch,
    },
    {
      selectTitle: "등록 구분",
      options: REGISTRATION_OPTIONS,
      selectedOption: selectedRegType,
      setSelectedOption: handleRegTypeSearch,
    },
  ];

  const columns: CustomColumn<Api.Response.UserItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "userIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "프로필",
        accessor: "imgPath",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.UserItem>) => (
          <ImageCellTypes value={value} isUser />
        ),
      },
      {
        Header: "ID",
        accessor: "userId",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value }, row }: CellProps<Api.Response.UserItem>) => (
          <ClickableCell
            value={value}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "닉네임",
        accessor: "nickName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "직업",
        accessor: "job",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "전화번호",
        accessor: "phone",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "국가",
        accessor: "countryName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "OS",
        accessor: "osType",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }: CellProps<Api.Response.UserItem>) => (
          <span>{value ? (value === "mac" ? "MAC" : "WIN") : "-"}</span>
        ),
      },
      {
        Header: "버전명",
        accessor: "version",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }: CellProps<Api.Response.UserItem>) => (
          <span>{value ? value : "-"}</span>
        ),
      },
      {
        Header: "회원상태",
        accessor: "stateFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.UserItem>) => (
          <StatusCell value={value} />
        ),
      },
      {
        Header: "가입일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.UserItem>) => (
          <DateCell value={value} />
        ),
      },
      {
        Header: "등록구분",
        accessor: "regFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.UserItem>) => (
          <UserRegTypeCell value={value} />
        ),
      },
      {
        Header: "등록자",
        accessor: "adminName",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.UserItem>) => {
          return value ? value : "-";
        },
      },
      {
        Header: "사용기록",
        accessor: "popup",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }: CellProps<Api.Response.UserItem>) => (
          <Button
            type="button"
            size="sm"
            color="dark"
            onClick={() => openUserActivity(row.original)}
          >
            보기
          </Button>
        ),
      },
      {
        Header: "대시보드",
        accessor: "dashboard",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }: CellProps<Api.Response.UserItem>) => {
          const { hasLogs } = row.original;
          return hasLogs ? (
            <Button
              type="button"
              size="sm"
              color="dark"
              onClick={() => moveToDashBoard(row.original)}
            >
              보기
            </Button>
          ) : (
            <span>-</span>
          );
        },
      },
    ],
    [handleRowClick],
  );

  return (
    <React.Fragment>
      <PageContainer title="Members" breadcrumbItems={BREADCRUMB_ITEMS}>
        <CommonControlPanel
          placeholder="이름, ID, 전화번호로 검색해주세요."
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          clearFilters={clearFilters}
          onSearch={handleSearch}
          dateRange={selectedDates}
          setDateRange={handleDateSearch}
          select={selectProps}
        />
        <CustomTableContainerTypes
          btnTitle="회원 등록"
          sortByIdx="userIdx"
          handleAddItem={handleAddUser}
          isLoading={loading}
          columns={columns || []}
          data={users || []}
          customPageSize={customPageSize}
          totalPage={totalPage}
          totalRecord={totalUsers}
          setCustomPageSize={setCustomPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          className="custom-header-css table align-middle table-nowrap"
          tableClassName="table-centered align-middle table-nowrap mb-0"
          theadClassName="text-muted table-light"
        />
        <UsageActivityModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          selectedUser={selectedUser}
        />
      </PageContainer>
    </React.Fragment>
  );
};

export default Users;
