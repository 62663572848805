import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Input } from "reactstrap";
import * as Api from "@/api";
import { ActionType } from "@/components/Common/ConfirmModalTypes";
import ToggleSwitch from "@/components/Common/ToggleSwitch";

export type Menu =
  | Api.Request.UpdateMenu
  | Api.Request.AddMenu
  | Api.Response.MenuItem;

type MenuRowProps = {
  menu: Menu;
  onChange: (updatedMenu: Menu) => void;
  isExistingMenu?: boolean;
  openModal: (action: ActionType, menu?: Api.Request.UpdateMenu) => void;
  onRowClick?: () => void;
  isSelected?: boolean;
  isSubMenu?: boolean;
};

export const menuValidation = {
  validateName: (name: string): boolean => {
    return name.trim().length > 0;
  },
  validateDispOrder: (dispOrder: number): boolean => {
    return dispOrder > 0 && !Number.isNaN(dispOrder);
  },

  validate: (menu: Menu): { isValid: boolean; errors: string[] } => {
    const errors: string[] = [];
    if (!menuValidation.validateName(menu.name)) {
      errors.push("메뉴명을 입력해주세요.");
    }
    if (!menuValidation.validateDispOrder(menu.dispOrder)) {
      errors.push("정렬순번을 0보다 큰 숫자로 입력해주세요.");
    }
    return {
      isValid: errors.length === 0,
      errors,
    };
  },
};

const MenuRow = ({
  menu,
  onChange,
  isExistingMenu,
  openModal,
  onRowClick,
  isSelected = false,
  isSubMenu,
}: MenuRowProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [originalData, setOriginalData] = useState(menu);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    const newValue = type === "number" ? parseInt(value, 10) : value;
    onChange({ ...menu, [name]: newValue });
  };

  const handleEditClick = () => {
    if (!isEditing) {
      setOriginalData({ ...menu });
    }
    if (isEditing) {
      const { isValid, errors } = menuValidation.validate(menu);
      if (!isValid) {
        errors.forEach((error) => toast.error(error, { autoClose: 3000 }));
        return;
      }
      openModal("edit");
    }
    setIsEditing(!isEditing);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    if (isExistingMenu) {
      onChange(originalData);
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isExistingMenu || isEditing) {
      const { checked } = e.target;
      onChange({ ...menu, useYn: checked ? "Y" : "N" });
    }
  };

  const renderInputField = (
    value: string | null | undefined,
    name: string,
    placeholder: string,
  ) => (
    <Input
      type="text"
      name={name}
      className="form-control"
      placeholder={placeholder}
      value={value || ""}
      onChange={handleInputChange}
    />
  );

  const renderCell = (
    value: string | null | undefined,
    name: string,
    placeholder: string,
    count?: number,
  ) => {
    if (!isExistingMenu) {
      return renderInputField(value, name, placeholder);
    }

    if (isExistingMenu && isEditing) {
      return renderInputField(value, name, placeholder);
    }

    return (
      <span>
        {value || "-"}{" "}
        {typeof count === "number" && count > 0 && (
          <span className="badge rounded-pill bg-danger">{count}</span>
        )}
      </span>
    );
  };

  const handleRowClick = (e: React.MouseEvent<HTMLTableRowElement>) => {
    const target = e.target as HTMLElement;
    if (
      !target.closest("button") &&
      !target.closest("input") &&
      onRowClick &&
      !isEditing
    ) {
      onRowClick();
    }
  };

  return (
    <tr
      data-menu-idx={
        isExistingMenu ? (menu as Api.Request.UpdateMenu).menuIdx : "new"
      }
      onClick={handleRowClick}
      style={{
        cursor: onRowClick && !isEditing ? "pointer" : "default",
        backgroundColor: isSelected ? "#f8f9fa" : "inherit",
      }}
    >
      <td>{isExistingMenu ? (menu as Api.Request.UpdateMenu).menuIdx : "-"}</td>
      <td>
        {renderCell(
          menu.name,
          "name",
          "메뉴명을 입력해주세요.",
          (menu as Api.Response.MenuItem).subMenuCnt,
        )}
      </td>
      <td>{renderCell(menu.icon, "icon", "아이콘을 입력해주세요.")}</td>
      <td>{renderCell(menu.frontUrl, "frontUrl", "URL을 입력해주세요.")}</td>
      <td style={{ maxWidth: "186px" }}>
        {!isExistingMenu || (isExistingMenu && isEditing) ? (
          <Input
            type="number"
            name="dispOrder"
            className="form-control"
            placeholder="정렬순번을 입력해주세요."
            value={menu.dispOrder}
            onChange={handleInputChange}
          />
        ) : (
          <span>{menu.dispOrder}</span>
        )}
      </td>
      <td>
        <div
          style={{ minWidth: "70px" }}
          className="d-flex justify-content-center"
        >
          <ToggleSwitch
            label=""
            id={`displayFlag-${isSubMenu ? "subMenu" : "mainMenu"}-${
              (menu as Api.Request.UpdateMenu).menuIdx || "new"
            }`}
            checked={menu.useYn === "Y"}
            onChange={handleCheckboxChange}
            extraClass="mt-2"
          />
        </div>
      </td>
      <td>
        {isExistingMenu ? (
          <div className="d-flex flex-wrap justify-content-center align-items-center gap-1">
            <Button
              color={isEditing ? "warning" : "primary"}
              className="btn btn-sm btn-primary mb-2  d-flex align-items-center"
              onClick={handleEditClick}
            >
              <i className="ri-pencil-fill me-2"></i>{" "}
              {isEditing ? "저장" : "수정"}
            </Button>
            <Button
              color={isEditing ? "dark" : "danger"}
              className="btn btn-sm btn-dark mb-2  d-flex align-items-center"
              onClick={isEditing ? handleCancelEdit : () => openModal("delete")}
            >
              <i
                className={
                  isEditing ? "ri-close-line me-2" : "ri-delete-bin-5-fill me-2"
                }
              ></i>
              {isEditing ? "취소" : "삭제"}
            </Button>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Button
              type="button"
              color="success"
              className="waves-effect waves-light btn-sm d-flex align-items-center"
              onClick={() => openModal("save")}
            >
              <i className="ri-add-line me-2"></i>
              등록
            </Button>
          </div>
        )}
      </td>
      <td>{"updName" in menu ? menu.updName || "-" : "-"}</td>
      <td>{"updDt" in menu ? menu.updDt || "-" : "-"}</td>
    </tr>
  );
};

export default MenuRow;
