import { create } from "zustand";
import * as Api from "@/api";
import { MenuItemStructure, transformMenuData } from "@/components/constants";

type MenuState = {
  menuItems: MenuItemStructure[];
  setMenuItems: (items: MenuItemStructure[]) => void;
  refreshMenuItems: () => Promise<void>;
};

export const useMenuStore = create<MenuState>((set) => ({
  menuItems: [],
  setMenuItems: (items: MenuItemStructure[]) => set({ menuItems: items }),
  refreshMenuItems: async () => {
    try {
      const response = await Api.MenuControl.getLeftMenuList();
      const transformedMenu = transformMenuData(response.data);
      set({ menuItems: transformedMenu });
    } catch (error) {
      console.error("Failed to fetch menu data:", error);
    }
  },
}));
