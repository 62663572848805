import { NavItem, NavLink } from "reactstrap";
import { CustomNav } from "./styled";

type NavFieldProps = {
  title: string;
  children?: React.ReactNode;
};
const NavField = ({ title, children }: NavFieldProps) => {
  return (
    <CustomNav tabs className="nav nav-tabs nav-tabs-custom mb-4">
      <NavItem>
        <NavLink
          className="nav-link fw-bold p-3 active"
          href="#"
          style={{ cursor: "pointer" }}
        >
          <span className="d-none d-sm-block">{title}</span>
        </NavLink>
      </NavItem>
      {children}
    </CustomNav>
  );
};

export default NavField;
