import React, { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import * as Api from "@/api";
import ControlPanelTypes from "@/components/Common/ControlPanelTypes";
import CustomTableContainerTypes, {
  CustomColumn,
} from "@/components/Common/CustomTableContainerTypes";
import PageContainer from "@/components/Common/PageContainer";
import { OptionProps } from "@/components/Common/types";
import useQueryParams, { useStateRef } from "@/helpers/hooks";
import { DisplayCell } from "@/pages/Utility/CustomCells";
import { ClickableCell } from "@/pages/Utility/CustomCellsType";
import { SHOW_OPTIONS } from "@/pages/Utility/Options";

const BREADCRUMB_ITEMS = [
  { title: "Notice Management", link: "#" },
  { title: "List", link: "#" },
];

const Notice = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams: queryString.ParsedQuery<string> = queryString.parse(
    location.search,
  );
  const { pageSize, page, keyword, dateRange, parseQueryParam } =
    useQueryParams();

  const [notices, setNotices] = useState<Api.Response.NoticeItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalNotices, setTotalNotices] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const searchKeywordRef = useStateRef(searchKeyword);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(dateRange);
  const [categories, setCategories] = useState<OptionProps[]>([]);
  const [selectedCategory, setSelectedCategory] = useState(
    parseQueryParam(queryParams.category) ?? -1,
  );
  const [selectedShow, setSelectedShow] = useState(() => {
    const parsedShow = parseQueryParam(queryParams.show);
    return parsedShow !== null ? parsedShow : SHOW_OPTIONS[0].value;
  });
  const [clearTrigger, setClearTrigger] = useState(false);

  const handleRowClick = useCallback(
    (item: { boardIdx: number }) => {
      navigate(`/notice-board/${item.boardIdx}${window.location.search}`);
    },
    [navigate],
  );

  const handleAddNotice = () => {
    navigate(`/notice-board/new${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: selectedDates[0],
      endDt: selectedDates[1],
      category: selectedCategory,
      show: selectedShow,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    selectedDates,
    searchKeyword,
    selectedCategory,
    selectedShow,
    location.pathname,
    navigate,
  ]);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await Api.Category.getList();
      const categories: Api.Response.CategoryItem[] = response?.data;
      const updatedCategories: Api.Response.CategoryItem[] = [
        {
          cateIdx: -1,
          orderNum: -1,
          displayFlag: -1,
          names: {
            ko: "전체",
            en: "All",
            ru: "Все",
          },
        },
        ...categories,
      ];
      const options: OptionProps[] = updatedCategories.map((category) => ({
        label: category.names.ko,
        value: category.cateIdx,
      }));
      setCategories(options);
    };
    fetchCategories();
  }, []);

  const fetchNotices = useCallback(async () => {
    setIsLoading(true);
    try {
      const searchKeyword = searchKeywordRef.current;
      const requestData = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        displayFlag: selectedShow,
        cateIdx: selectedCategory,
        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.Notice.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalNotices(response.paging.totalRecord);
      setNotices(response.data);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error fetching Notices list", error);
      } else {
        console.error("Unexpected error:", error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    currentPage,
    customPageSize,
    selectedCategory,
    selectedShow,
    selectedDates[1],
    clearTrigger,
  ]);

  useEffect(() => {
    fetchNotices();
  }, [fetchNotices]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchNotices();
  };

  const handleCategorySearch = (newCategory: number) => {
    setSelectedCategory(newCategory);
    setCurrentPage(0);
  };

  const handleShowSearch = (newShow: number | string) => {
    setCurrentPage(0);
    setSelectedShow(newShow as number);
  };

  const handleDateSearch = (newDateRange: [string, string]) => {
    setSelectedDates(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = async () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setSelectedCategory(-1);
    setSelectedShow(SHOW_OPTIONS[0].value);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const columns: CustomColumn<Api.Response.NoticeItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "boardIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "카테고리명",
        accessor: "cateName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "제목",
        accessor: "title",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
          row,
        }: CellProps<Api.Response.NoticeItem>) => (
          <ClickableCell
            value={value}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "썸네일",
        accessor: "thumImgPath",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "작성자명",
        accessor: "authorName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "표시여부",
        accessor: "displayFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.NoticeItem>) => (
          <DisplayCell value={value} />
        ),
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <PageContainer
        title="Notice Management"
        breadcrumbItems={BREADCRUMB_ITEMS}
      >
        <ControlPanelTypes
          placeholder="제목, 부제목, 카테고리명으로 검색해주세요."
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          clearFilters={clearFilters}
          onSearch={handleSearch}
          dateRange={selectedDates}
          setDateRange={handleDateSearch}
          options1={categories}
          option1Type="string"
          options2={SHOW_OPTIONS}
          option2Type="number"
          selectedOption1={selectedCategory}
          setSelectedOption1={handleCategorySearch}
          selectedOption2={selectedShow}
          setSelectedOption2={handleShowSearch}
          selectTitle1="카테고리"
          selectTitle2="표시 여부"
        />
        <CustomTableContainerTypes
          btnTitle="게시물 등록"
          sortByIdx="boardIdx"
          handleAddItem={handleAddNotice}
          isLoading={isLoading}
          columns={columns || []}
          data={notices || []}
          customPageSize={customPageSize}
          totalPage={totalPage}
          totalRecord={totalNotices}
          setCustomPageSize={setCustomPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          className="custom-header-css table align-middle table-nowrap"
          tableClassName="table-centered align-middle table-nowrap mb-0"
          theadClassName="text-muted table-light"
        />
      </PageContainer>
    </React.Fragment>
  );
};

export default Notice;
