import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import * as Api from "@/api";
import { ActionType } from "@/components/Common/ConfirmModalTypes";
import { TABLE_HEADERS } from "@/pages/MenuPermission";
import {
  ArrowDownIcon,
  CustomSelect,
  CustomSelectContainer,
} from "@/pages/Users/styled";

type AdminRowProps = {
  data: Api.Response.PermissionAdmin[];
  setNewAdmin: (admin: Api.Request.Admin) => void;
  selectedAdmin: number | null;
  setSelectedAdmin: (adminIdx: number | null) => void;
  openModal: (action: ActionType, admin?: Api.Response.PermissionAdmin) => void;
  isLoading: boolean;
  selectedParent?: Api.Response.GroupItem | null;
};

const AdminRow = ({
  selectedParent,
  data,
  setNewAdmin,
  selectedAdmin,
  setSelectedAdmin,
  openModal,
  isLoading,
}: AdminRowProps) => {
  const [availableAdmins, setAvailableAdmins] = useState<
    Api.Response.AdminItem[]
  >([]);

  const getAvailableAdmins = async () => {
    try {
      const response = await Api.MenuPermissionAdmin.getAll();
      setAvailableAdmins(response.data);
    } catch (error) {
      console.error("Failed to fetch available admins:", error);
    }
  };

  useEffect(() => {
    if (selectedParent) {
      getAvailableAdmins();
    }
  }, [selectedParent]);

  const handleAdminSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = parseInt(e.target.value, 10);
    const selectedAdminData = availableAdmins.find(
      (admin) => admin.adminIdx === selectedValue,
    );

    if (selectedAdminData && selectedParent?.roleIdx) {
      setNewAdmin({
        roleIdx: selectedParent?.roleIdx,
        adminIdx: selectedAdminData.adminIdx,
      });
      setSelectedAdmin(selectedValue);
    }
  };

  return (
    <Row>
      <Col className="col-12">
        <div className="card card-body">
          <h4>관리자목록:{selectedParent?.name || ""}</h4>
          <div
            className="table-responsive overflow-row"
            style={{
              height: "400px",
              overflowY: "auto",
              boxShadow: "inset 0 -10px 5px -5px rgba(0, 0, 0, 0.1) ",
            }}
          >
            <table
              className="table table-hover mb-0 text-center align-middle"
              style={{
                minWidth: "33.125rem",
              }}
            >
              <thead
                className="table-light"
                style={{ position: "sticky", top: 0, zIndex: 1 }}
              >
                <tr>
                  {TABLE_HEADERS.map((header) => (
                    <th key={header.key}>{header.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {selectedParent && (
                  <>
                    <tr>
                      <td style={{ width: "23px" }}>-</td>
                      <td className="w-50">
                        <CustomSelectContainer>
                          <CustomSelect
                            className="form-control"
                            value={selectedAdmin || ""}
                            onChange={handleAdminSelect}
                          >
                            <option value="" disabled>
                              Select Admin
                            </option>
                            {availableAdmins.map((admin) => (
                              <option
                                key={admin.adminIdx}
                                value={admin.adminIdx || ""}
                              >
                                {admin.adminName}
                              </option>
                            ))}
                          </CustomSelect>
                          <ArrowDownIcon className="ri-arrow-down-s-line"></ArrowDownIcon>
                        </CustomSelectContainer>
                      </td>
                      <td className="d-flex justify-content-center">
                        <Button
                          color="success"
                          className="waves-effect waves-light btn-sm d-flex align-items-center"
                          onClick={() => openModal("save")}
                          disabled={!selectedAdmin}
                        >
                          <i className="ri-add-line me-2"></i>
                          등록
                        </Button>
                      </td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                    {isLoading ? (
                      <tr>
                        <td colSpan={5}>
                          <Spinner color="secondary" />
                        </td>
                      </tr>
                    ) : (
                      data.map((admin, index) => (
                        <tr key={admin.adminIdx}>
                          <td>{index + 1}</td>
                          <td>{admin.adminName}</td>
                          <td className="d-flex justify-content-center">
                            <Button
                              color="danger"
                              className="btn btn-sm btn-dark d-flex align-items-center"
                              onClick={() => openModal("delete", admin)}
                            >
                              <i className="ri-delete-bin-line me-2"></i>
                              삭제
                            </Button>
                          </td>
                          <td>{admin.updName || admin.regName}</td>
                          <td>{admin.updDt || admin.regDt}</td>
                        </tr>
                      ))
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AdminRow;
