import { useMemo } from "react";
import { CellProps } from "react-table";
import { toast } from "react-toastify";
import { Table } from "reactstrap";
import { Button } from "reactstrap";
import * as Api from "@/api";
import { CustomColumn } from "@/components/Common/CustomTableContainerTypes";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from "@/helpers/constants";
import { ImageCellTypes } from "@/pages/Utility/CustomCellsType";
import { MigrationResult } from "@/utils/api/features/PrintingProfile/types";
import {
  CertifyCell,
  ClickableCell,
  DisplayCell,
  RegTypeCell,
} from "../../Utility/CustomCells";

type DeleteParams = {
  printingIdx: number;
  navigate: (path: string) => void;
  setLoading: (value: boolean) => void;
  getLocalizedMessage: (message: any, defaultMsg: string) => string;
};

type GetColumnsProps = {
  handleRowClick: (item: Api.Response.PrintingProfileItem) => void;
  handleIsModalOpen: (item: Api.Response.PrintingProfileItem) => void;
  isModal?: boolean;
};

const resultTable = (data: MigrationResult[]) => {
  const hasRemoteIdx = data.some((item) => item.remoteIdx !== null);

  return (
    <div className="table-responsive">
      <Table className="mb-0">
        <thead>
          <tr>
            <th>#</th>
            <th>Idx</th>
            {hasRemoteIdx && <th>RemoteIdx</th>}
            <th>State</th>
            <th>Fail Message</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => (
            <tr key={item.reqIdx}>
              <th scope="row">{i + 1}</th>
              <td>{item.reqIdx}</td>
              {hasRemoteIdx && <td>{item.remoteIdx}</td>}
              <td>{`${item.state}`}</td>
              <td>{item.failMsg}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default resultTable;

export const GetColumns = ({
  handleRowClick,
  handleIsModalOpen,
  isModal,
}: GetColumnsProps): CustomColumn<Api.Response.PrintingProfileItem>[] => {
  return useMemo(
    () => [
      {
        Header: "No",
        accessor: "printingIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "MNo",
        accessor: "mngIdx",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
        }: CellProps<Api.Response.PrintingProfileItem>) => {
          return value ? value : "-";
        },
      },
      {
        Header: "고객사",
        accessor: "clientName",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
        }: CellProps<Api.Response.PrintingProfileItem>) => (
          <span style={{ color: value !== "메타몰프" ? "#5664d2" : "" }}>
            {value}
          </span>
        ),
      },
      {
        Header: "구분",
        accessor: "applicationsImgPath",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
        }: CellProps<Api.Response.PrintingProfileItem>) => (
          <ImageCellTypes value={value} />
        ),
      },
      {
        Header: "구분코드",
        accessor: "classCode",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "프로파일명",
        accessor: "profileName",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
          row,
        }: CellProps<Api.Response.PrintingProfileItem>) =>
          isModal ? (
            <span>{value}</span>
          ) : (
            <ClickableCell
              value={value}
              row={row.original}
              handleRowClick={handleRowClick}
            />
          ),
      },
      {
        Header: "프린터",
        accessor: "printerImgPath",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
        }: CellProps<Api.Response.PrintingProfileItem>) => (
          <ImageCellTypes value={value} />
        ),
      },
      {
        Header: "프린터명",
        accessor: "printerName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "프린터제조사",
        accessor: "printerManufName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "소재",
        accessor: "materialImgPath",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
        }: CellProps<Api.Response.PrintingProfileItem>) => (
          <ImageCellTypes value={value} />
        ),
      },
      {
        Header: "소재명",
        accessor: "materialName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "소재제조사",
        accessor: "materialManufName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "레이어두께",
        accessor: "thickVal",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "기본여부",
        accessor: "defaultFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
        }: CellProps<Api.Response.PrintingProfileItem>) => (
          <RegTypeCell value={value} />
        ),
      },
      {
        Header: "표시여부",
        accessor: "displayFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
        }: CellProps<Api.Response.PrintingProfileItem>) => (
          <DisplayCell value={value} />
        ),
      },
      {
        Header: "인증여부",
        accessor: "certifiFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
        }: CellProps<Api.Response.PrintingProfileItem>) => (
          <CertifyCell value={value} />
        ),
      },

      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "선택",
        accessor: "select",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }: CellProps<Api.Response.PrintingProfileItem>) => (
          <Button
            color="danger"
            className="btn btn-sm waves-effect waves-light me-1 d-flex justify-content-center align-items-center"
            onClick={() => handleIsModalOpen(row.original)}
          >
            <i className="ri-delete-bin-line pe-1 " />
            삭제
          </Button>
        ),
      },
    ],
    [handleRowClick],
  );
};

export const handleDeleteProfile = async ({
  printingIdx,
  navigate,
  setLoading,
  getLocalizedMessage,
}: DeleteParams) => {
  setLoading(true);
  try {
    const response = await Api.PrintingProfile.delete(printingIdx);
    const successMessage = getLocalizedMessage(
      response,
      DEFAULT_SUCCESS_MESSAGE,
    );
    toast.success(successMessage, { autoClose: 3000 });
    setTimeout(() => {
      navigate("/printing-profile");
    }, 1000);
  } catch (error) {
    console.error("Error deleting profile:", error);
    const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
    toast.error(errMessage, {
      autoClose: 3000,
    });
  } finally {
    setLoading(false);
  }
};
