import React, { useEffect, useRef, useState } from "react";
import { Input } from "reactstrap";
import { CERTIFY_OPTIONS, REGISTRATION_OPTIONS, SHOW_OPTIONS } from "./Options";
import { ImgContainer, ListHoverImage } from "./styled";
import materialImg from "../../assets/images/default-material.png";
import printerImg from "../../assets/images/default-printer.png";
import noImg from "../../assets/images/no-image.jpg";
import { osTypeOptions } from "../Version";

export const CheckboxCell = React.memo(
  ({ itemIdx, selectedIds, onCheckboxChange }) => {
    return (
      <Input
        type="checkbox"
        className="form-check-input input-mini"
        id={`row-checkbox-${itemIdx}`}
        checked={selectedIds?.includes(itemIdx)}
        onChange={() => onCheckboxChange(itemIdx)}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.row === nextProps.row &&
      prevProps.selectedIds === nextProps.selectedIds
    );
  },
);

export const ClickableCell = ({ value, row, handleRowClick }) => {
  return (
    <span
      style={{ textDecoration: "underline", cursor: "pointer" }}
      onClick={() => handleRowClick(row)}
    >
      {value}
    </span>
  );
};

export const ImageCell = ({ value, isUser, type = "" }) => {
  const containerRef = useRef(null);
  const [shouldLoad, setShouldLoad] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  let defaultImg;

  if (type === "material") {
    defaultImg = materialImg;
  } else if (type === "printer") {
    defaultImg = printerImg;
  } else {
    defaultImg = noImg;
  }

  useEffect(() => {
    if (!containerRef.current) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setShouldLoad(true);
          observer.disconnect();
        }
      },
      { rootMargin: "50px" },
    );

    observer.observe(containerRef.current);
    return () => observer.disconnect();
  }, [value]);

  return (
    <ImgContainer ref={containerRef}>
      {shouldLoad ? (
        <>
          <img
            src={value || defaultImg}
            alt=""
            style={{
              width: "50px",
              height: "50px",
              borderRadius: isUser ? "50%" : "0",
            }}
            loading="lazy"
            onLoad={() => setIsLoaded(true)}
          />
          {isLoaded && value && (
            <ListHoverImage
              style={{
                backgroundImage: `url(${value})`,
              }}
            />
          )}
        </>
      ) : (
        <img
          src={defaultImg}
          alt=""
          style={{ width: "50px", height: "50px", border: "0" }}
        />
      )}
    </ImgContainer>
  );
};

export const RegTypeCell = ({ value }) => {
  let color = "";
  switch (value) {
    case 0: // 사용자등록
      color = "#ff3d5f";
      break;
    case 1: // 기본
      color = "black";
      break;
    default:
      color = "black";
      break;
  }
  return (
    <span style={{ color: color }}>
      {REGISTRATION_OPTIONS.find((option) => option.value === value)?.label}
    </span>
  );
};

export const DisplayCell = ({ value }) => {
  let color = "";
  switch (value) {
    case 0: // 숨김
      color = "#75788d";
      break;
    case 1: // 표시
      color = "#1cbb8c";
      break;
    default:
      color = "black";
      break;
  }

  return (
    <span style={{ color: color }}>
      {SHOW_OPTIONS.find((option) => option.value === value)?.label}
    </span>
  );
};

export const CertifyCell = ({ value }) => {
  let color = "";
  switch (value) {
    case 0: // 미표시
      color = "#75788d";
      break;
    case 1: // 표시
      color = "#5c67f7";
      break;
    default:
      color = "black";
      break;
  }

  return (
    <span style={{ color: color }}>
      {CERTIFY_OPTIONS.find((option) => option.value === value)?.label}
    </span>
  );
};

export const OsTypeCell = ({ value }) => {
  let color = "";
  switch (value) {
    case "WIN": // 숨김
      color = "#ff3d5f";
      break;
    case "MAC": // 표시
      color = "#fcb92c";
      break;
    default:
      color = "black";
      break;
  }

  return (
    <span style={{ color: color }}>
      {osTypeOptions.find((option) => option.value === value)?.value}
    </span>
  );
};
