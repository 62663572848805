import * as Api from "@/api";
import StatisticsBase from "./StatisticsBase";
import RequestByRegion from "../Dashboard/RequestByRegion";

const RequestingRegions = () => (
  <StatisticsBase
    title="Requesting Regions"
    defaultRange={30}
    columns={[
      { key: "regionName", name: "Region", label: "지역" },
      { key: "cnt", name: "Number of requests", label: "요청수" },
    ]}
    ChartComponent={(props) => <RequestByRegion {...props} height="100%" />}
    errorMessage="Failed fetching Requesting Regions data"
    onRequestData={Api.RequestingRegions.getList}
    getDataForExport={(data) => data || []}
    getDataList={(data) => data || []}
  />
);

export default RequestingRegions;
