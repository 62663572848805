import * as ApiBase from "@metamorp/api-base";
import { DailyModelProcessing } from "./type";
import { DateRange } from "../common/type";

const getModelProcessing = (data: DateRange) => {
  type Response = ApiBase.Types.ApiResBase<DailyModelProcessing>;
  return ApiBase.request<Response>({
    method: "POST",
    path: `/api/v1/statis/model/count/state`,
    data: data,
  });
};

export default {
  /**
   * `GET /api/v1/statis/model/count/state`
   *
   * @authorization-required
   *
   * Retrieve ModelProcessing data.
   */
  get: getModelProcessing,
};
