import {
  // Button,
  Col,
  ModalBody,
  ModalHeader,
  Spinner,
  Table,
} from "reactstrap";
import * as Api from "@/api";
import { CustomModal } from "../../../components/Common/styled";

type VersionChangesProps = {
  title: string;
  isLoading: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  data: Api.Response.VersionItem | null;
};

const VersionChangesModal = ({
  title,
  isLoading,
  isOpen,
  setIsOpen,
  data,
}: VersionChangesProps) => {
  const rows = [
    { label: "New Features", value: data?.newFeatures },
    { label: "Improvements", value: data?.improvements },
    { label: "Bug Fixed", value: data?.bugsFixed },
  ];

  const filteredRows = rows.filter(
    (row) => row.value && row.value.trim() !== "",
  );

  return (
    <div>
      <Col sm={6} md={4} xl={3} className="mt-4">
        <CustomModal
          style={{ maxWidth: "600px" }}
          isOpen={isOpen}
          toggle={(prevState: boolean) => setIsOpen(!prevState)}
          centered={true}
        >
          <ModalHeader>
            {title}
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => setIsOpen(false)}
            ></button>
          </ModalHeader>

          <ModalBody>
            {isLoading ? (
              <Spinner className="me-2" color="secondary" />
            ) : (
              <div className="table-responsive">
                <Table className="mb-0">
                  <tbody>
                    {filteredRows.length ? (
                      filteredRows.map((row, index) => (
                        <tr key={index}>
                          <th style={{ minWidth: "120px" }}>{row.label}</th>
                          <td
                            dangerouslySetInnerHTML={{
                              __html: row.value ?? "",
                            }}
                          ></td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>&quot;변경사항 기록 없음&quot;</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            )}
          </ModalBody>
        </CustomModal>
      </Col>
    </div>
  );
};

export default VersionChangesModal;
