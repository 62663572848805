import { Col, Label } from "reactstrap";
import { CustomSelectContainer } from "../../pages/Users/styled";

type Option = {
  value: string | number;
  label: string;
};

type SelectorProps = {
  id: string;
  options: Option[];
  value: number | null | string;
  onChange: (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => void;
  label: string;
  isRequired?: boolean;
  colMd?: number;
};

const SelectorTypes = ({
  id,
  options,
  value,
  onChange,
  label,
  isRequired = false,
  colMd = 10,
}: SelectorProps) => {
  return (
    <>
      <Label className="col-md-2 col-form-label" htmlFor={id}>
        {label}
        {isRequired && (
          <>
            {" "}
            (<span className="text-danger">*</span>)
          </>
        )}
      </Label>
      <Col md={colMd}>
        <CustomSelectContainer>
          <select
            className="form-select"
            id={id}
            name={id}
            value={value ?? undefined}
            onChange={onChange}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </CustomSelectContainer>
      </Col>
    </>
  );
};

export default SelectorTypes;
