import * as Api from "@/api";
import StatisticsBase from "./StatisticsBase";
import TopRequestRegion from "../Dashboard/TopRequestRegion";

const RequestRegionRanking = () => {
  return (
    <StatisticsBase
      title="Request Region Ranking"
      defaultRange={30}
      columns={[
        { key: "rank", name: "Rank", label: "순위" }, // Adding Rank column
        { key: "country", name: "Country", label: "국가" },
        { key: "reqCnt", name: "Requests", label: "요청수" },
        { key: "userCnt", name: "Requesters", label: "요청자수" },
      ]}
      ChartComponent={(props) => <TopRequestRegion {...props} height="100%" />}
      errorMessage="Failed fetching Request Region Ranking data"
      onRequestData={Api.RequestRegionRanking.get}
      getDataList={(data) => data?.list || []}
      getDataForExport={(data) => data?.list || []}
    />
  );
};

export default RequestRegionRanking;
