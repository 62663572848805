import * as ApiBase from "@metamorp/api-base";
import { GetEmailExpList, EmailExpItem } from "./types";

const getEmailList = (data: GetEmailExpList) => {
  type Response = ApiBase.Types.ApiResBase<
    EmailExpItem[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response, GetEmailExpList>({
    method: "POST",
    path: "/api/v1/user/email/expire/list",
    data: data,
  });
};

export default {
  /**
   * `POST /api/v1/user/email/expire/list`
   *
   * 만료 이메일 목록을 조회합니다.
   *
   * Retrieve email list.
   */
  getList: getEmailList,
};
