import * as ApiBase from "@metamorp/api-base";
import {
  PermissionMenuItem,
  RoleItem,
  UpdateMapping,
} from "@/utils/api/features/PermissionMapping/types";

const updatePermissionMapping = (data: UpdateMapping) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, UpdateMapping>({
    method: "PUT",
    path: "/api/v1/role/mapping/update",
    data: data,
  });
};

const getPermissionGroupList = () => {
  type Response = ApiBase.Types.ApiResBase<RoleItem[]>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/role/mapping/role/list",
  });
};

const getMenuList = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<PermissionMenuItem[]>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/role/mapping/menu/${idx}`,
  });
};

export default {
  /**
   * `PUT /api/v1/role/mapping/update`
   *
   * 권한 메뉴를 갱신합니다.
   *
   * Update permission menu.
   */
  update: updatePermissionMapping,
  /**
   * `GET /api/v1/role/mapping/role/list`
   *
   * 권한 전체 목록을 조회합니다.
   *
   * Retrieve a permission group list.
   */
  getList: getPermissionGroupList,

  /**
   * `GET /api/v1/role/mapping/menu/:idx`
   *
   * 권한 메뉴 목록을 조회합니다.
   *
   * Retrive permission menu list.
   */
  getMenuList: getMenuList,
};
