import React, { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import { ToastContainer } from "react-toastify";
import { Button } from "reactstrap";
import * as Api from "@/api";
import CommonControlPanel from "@/components/Common/CommonControlPanel";
import CustomTableContainerTypes, {
  CustomColumn,
} from "@/components/Common/CustomTableContainerTypes";
import DeleteModal from "@/components/Common/DeleteModal";
import useQueryParams, {
  useLocalizedMessage,
  useStateRef,
} from "@/helpers/hooks";
import { ImageCellTypes } from "@/pages/Utility/CustomCellsType";
import { handleDeleteMaterial } from "./utils";
import PageContainer from "../../components/Common/PageContainer";
import useMaterialStore from "../../zustandStore/materialStore";
import {
  ClickableCell,
  DisplayCell,
  RegTypeCell,
} from "../Utility/CustomCells";
import { REGISTRATION_OPTIONS, SHOW_OPTIONS } from "../Utility/Options";

const BREADCRUMB_ITEMS = [
  { title: "Material Management", link: "#" },
  { title: "List", link: "#" },
];

const Material = () => {
  const { materials, setMaterials, loading, setLoading } = useMaterialStore();
  const navigate = useNavigate();
  const location = useLocation();
  const getLocalizedMessage = useLocalizedMessage();
  const queryParams = queryString.parse(location.search);

  const { pageSize, page, keyword, dateRange, parseQueryParam } =
    useQueryParams();

  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalMaterials, setTotalMaterials] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const searchKeywordRef = useStateRef(searchKeyword);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(dateRange);
  const [selectedMaterial, setSelectedMaterial] = useState(() => {
    const parsedMaterial = parseQueryParam(queryParams.material);
    return parsedMaterial !== null
      ? parsedMaterial
      : REGISTRATION_OPTIONS[0].value;
  });
  const [selectedShow, setSelectedShow] = useState(() => {
    const parsedShow = parseQueryParam(queryParams.show);
    return parsedShow !== null ? parsedShow : SHOW_OPTIONS[0].value;
  });
  const [clearTrigger, setClearTrigger] = useState(false);
  const [materialToDelete, setMaterialToDelete] =
    useState<Api.Response.MaterialItem | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRowClick = useCallback(
    (item: { materialIdx: number }) => {
      navigate(`/material/${item.materialIdx}${window.location.search}`);
    },
    [navigate],
  );

  const handleAddMaterial = () => {
    navigate(`/material/new${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: selectedDates[0],
      endDt: selectedDates[1],
      material: selectedMaterial,
      show: selectedShow,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    selectedDates,
    searchKeyword,
    selectedMaterial,
    selectedShow,
    location.pathname,
    navigate,
  ]);

  const fetchMaterials = useCallback(async () => {
    setLoading(true);

    try {
      const searchKeyword = searchKeywordRef.current;
      const requestData: Api.Request.GetMaterialList = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        displayFlag: selectedShow,
        defaultFlag: selectedMaterial,
        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.Material.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalMaterials(response.paging.totalRecord);
      setMaterials(response.data);
    } catch (error) {
      console.error("Error fetching Materials", error);
    } finally {
      setLoading(false);
    }
  }, [
    currentPage,
    customPageSize,
    selectedMaterial,
    selectedShow,
    selectedDates[1],
    clearTrigger,
  ]);

  useEffect(() => {
    fetchMaterials();
  }, [fetchMaterials]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchMaterials();
  };

  const handleMaterialSearch = (newMaterial: number | string) => {
    setCurrentPage(0);
    setSelectedMaterial(newMaterial as number);
  };

  const handleShowSearch = (newShow: number | string) => {
    setCurrentPage(0);
    setSelectedShow(newShow as number);
  };

  const handleDateSearch = (newDateRange: [string, string]) => {
    setSelectedDates(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setSelectedMaterial(REGISTRATION_OPTIONS[0].value);
    setSelectedShow(SHOW_OPTIONS[0].value);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const handleIsModalOpen = (item: Api.Response.MaterialItem) => {
    setMaterialToDelete(item);
    setIsModalOpen(true);
  };

  const handleDelete = async () => {
    if (!materialToDelete) return;
    const materialIdx = materialToDelete.materialIdx;
    await handleDeleteMaterial({
      materialIdx,
      navigate,
      setLoading,
      getLocalizedMessage,
    });
    setIsModalOpen(false);
    fetchMaterials();
  };

  const selectProps = [
    {
      selectTitle: "기본소재여부",
      options: REGISTRATION_OPTIONS,
      selectedOption: selectedMaterial,
      setSelectedOption: handleMaterialSearch,
    },
    {
      selectTitle: "표시여부",
      options: SHOW_OPTIONS,
      selectedOption: selectedShow,
      setSelectedOption: handleShowSearch,
    },
  ];

  const columns: CustomColumn<Api.Response.MaterialItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "materialIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "이미지",
        accessor: "imgPath",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.MaterialItem>) => (
          <ImageCellTypes value={value} type="material" />
        ),
      },
      {
        Header: "소재명",
        accessor: "materialName",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
          row,
        }: CellProps<Api.Response.MaterialItem>) => (
          <ClickableCell
            value={value}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "제조사명",
        accessor: "manufName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "기본소재여부",
        accessor: "defaultFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.MaterialItem>) => (
          <RegTypeCell value={value} />
        ),
      },
      {
        Header: "표시여부",
        accessor: "displayFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.MaterialItem>) => (
          <DisplayCell value={value} />
        ),
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "선택",
        accessor: "select",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }: CellProps<Api.Response.MaterialItem>) => (
          <Button
            color="danger"
            className="btn btn-sm waves-effect waves-light me-1 d-flex justify-content-center align-items-center"
            onClick={() => handleIsModalOpen(row.original)}
          >
            <i className="ri-delete-bin-line pe-1 " />
            삭제
          </Button>
        ),
      },
    ],
    [handleRowClick],
  );

  return (
    <React.Fragment>
      <PageContainer
        breadcrumbItems={BREADCRUMB_ITEMS}
        title="Material Management"
      >
        <CommonControlPanel
          placeholder={
            "소재명, 제조사명, 등록자, 수정자명 등으로 검색해주세요."
          }
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          clearFilters={clearFilters}
          onSearch={handleSearch}
          dateRange={selectedDates}
          setDateRange={handleDateSearch}
          select={selectProps}
        />
        <CustomTableContainerTypes
          sortByIdx="materialIdx"
          btnTitle="소재 등록"
          handleAddItem={handleAddMaterial}
          columns={columns || []}
          data={materials || []}
          customPageSize={customPageSize}
          totalPage={totalPage}
          totalRecord={totalMaterials}
          setCustomPageSize={setCustomPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={loading}
          className="custom-header-css table align-middle table-nowrap"
          tableClassName="table-centered align-middle table-nowrap mb-0"
          theadClassName="text-muted table-light"
        />
        <DeleteModal
          isLoading={loading}
          data={materialToDelete?.materialName}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          onHandleDelete={handleDelete}
        />
        <ToastContainer />
      </PageContainer>
    </React.Fragment>
  );
};

export default Material;
