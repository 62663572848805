import React from "react";
import { Button, Input } from "reactstrap";
import * as Api from "@/api";
import { ActionType } from "@/components/Common/ConfirmModalTypes";
import ToggleSwitch from "@/components/Common/ToggleSwitch";

export type CategoryRowCategory =
  | Api.Request.UpdateCategory
  | Api.Request.AddCategory;

type CategoryRowProps = {
  category: CategoryRowCategory;
  isEditMode?: boolean;
  onChange: (updatedCategory: CategoryRowCategory) => void;
  openModal: (action: ActionType, category?: Api.Request.UpdateAccount) => void;
};

const CategoryRow = ({
  category,
  isEditMode,
  onChange,
  openModal,
}: CategoryRowProps) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    const newValue = type === "number" ? parseInt(value, 10) : value;
    onChange({ ...category, [name]: newValue });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    onChange({ ...category, displayFlag: checked ? 1 : 0 });
  };

  return (
    <tr>
      <td>
        {isEditMode ? (category as Api.Request.UpdateCategory).cateIdx : "-"}
      </td>
      <td>
        <Input
          type="text"
          name="nameKo"
          className="form-control"
          placeholder="카테고리명을 입력해주세요."
          value={category.nameKo || ""}
          onChange={handleInputChange}
        />
      </td>
      <td>
        <Input
          type="text"
          name="nameEn"
          className="form-control"
          placeholder="영문명을 입력해주세요."
          value={category.nameEn || ""}
          onChange={handleInputChange}
        />
      </td>
      <td>
        <Input
          type="text"
          name="nameRu"
          className="form-control"
          placeholder="러시아명을 입력해주세요."
          value={category.nameRu || ""}
          onChange={handleInputChange}
        />
      </td>
      <td>
        <Input
          type="number"
          name="orderNum"
          className="form-control"
          value={category.orderNum}
          onChange={handleInputChange}
        />
      </td>
      <td>
        <div
          style={{ minWidth: "70px" }}
          className="d-flex justify-content-center"
        >
          <ToggleSwitch
            label=""
            id={`displayFlag-${
              (category as Api.Request.UpdateCategory).cateIdx || "new"
            }`}
            checked={category.displayFlag === 1}
            onChange={handleCheckboxChange}
            extraClass="mt-2"
          />
        </div>
      </td>
      <td>
        {isEditMode ? (
          <>
            <Button
              color="primary"
              className="btn btn-sm btn-primary mb-2 me-1"
              onClick={() => openModal("edit")}
            >
              <i className="ri-pencil-fill me-2"></i> 수정
            </Button>
            <Button
              color="danger"
              className="btn btn-sm btn-danger mb-2"
              onClick={() => openModal("delete")}
            >
              <i className="ri-delete-bin-5-fill me-2"></i> 삭제
            </Button>
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <Button
              type="button"
              color="success"
              className="waves-effect waves-light btn-sm d-flex align-items-center"
              onClick={() => openModal("save")}
            >
              <i className="ri-add-line me-3"></i>
              등록
            </Button>
          </div>
        )}
      </td>
    </tr>
  );
};

export default CategoryRow;
