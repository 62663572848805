import React, { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import * as Api from "@/api";
import CommonControlPanel from "@/components/Common/CommonControlPanel";
import CustomTableContainerTypes, {
  CustomColumn,
} from "@/components/Common/CustomTableContainerTypes";
import PageContainer from "@/components/Common/PageContainer";
import useQueryParams, { useStateRef } from "@/helpers/hooks";
import { DisplayCell } from "@/pages/Utility/CustomCells";
import { ClickableCell } from "@/pages/Utility/CustomCellsType";
import { SHOW_OPTIONS } from "@/pages/Utility/Options";

const BREADCRUMB_ITEMS = [
  { title: "Faq Management", link: "#" },
  { title: "List", link: "#" },
];

const Faq = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams: queryString.ParsedQuery<string> = queryString.parse(
    location.search,
  );
  const { pageSize, page, keyword, dateRange, parseQueryParam } =
    useQueryParams();

  const [faqs, setFaqs] = useState<Api.Response.Faq[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalFaqs, setTotalFaqs] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const searchKeywordRef = useStateRef(searchKeyword);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(dateRange);
  const [selectedShow, setSelectedShow] = useState(() => {
    const parsedShow = parseQueryParam(queryParams.show);
    return parsedShow !== null ? parsedShow : SHOW_OPTIONS[0].value;
  });
  const [clearTrigger, setClearTrigger] = useState(false);

  const handleRowClick = useCallback(
    (item: { faqIdx: number }) => {
      navigate(`/faq/${item.faqIdx}${window.location.search}`);
    },
    [navigate],
  );

  const handleAddFaq = () => {
    navigate(`/faq/new${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: selectedDates[0],
      endDt: selectedDates[1],
      show: selectedShow,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    selectedDates,
    searchKeyword,
    selectedShow,
    location.pathname,
    navigate,
  ]);

  const fetchFaqs = useCallback(async () => {
    setIsLoading(true);
    try {
      const searchKeyword = searchKeywordRef.current;
      const requestData = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        displayFlag: selectedShow,
        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.Faq.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalFaqs(response.paging.totalRecord);
      setFaqs(response.data);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error fetching Faqs list", error);
      } else {
        console.error("Unexpected error:", error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    currentPage,
    customPageSize,
    selectedShow,
    selectedDates[1],
    clearTrigger,
  ]);

  useEffect(() => {
    fetchFaqs();
  }, [fetchFaqs]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchFaqs();
  };

  const handleShowSearch = (newShow: number | string) => {
    setCurrentPage(0);
    setSelectedShow(newShow as number);
  };

  const handleDateSearch = (newDateRange: [string, string]) => {
    setSelectedDates(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = async () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setSelectedShow(SHOW_OPTIONS[0].value);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const columns: CustomColumn<Api.Response.FaqItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "faqIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "제목",
        accessor: (row) => row.titles.ko,
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value }, row }: CellProps<Api.Response.FaqItem>) => (
          <ClickableCell
            value={value}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "표시여부",
        accessor: "displayFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.FaqItem>) => (
          <DisplayCell value={value} />
        ),
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
    ],
    [],
  );

  const selectProps = [
    {
      selectTitle: "표시 여부",
      options: SHOW_OPTIONS,
      selectedOption: selectedShow,
      setSelectedOption: handleShowSearch,
    },
  ];

  return (
    <React.Fragment>
      <PageContainer title="Faq Management" breadcrumbItems={BREADCRUMB_ITEMS}>
        <CommonControlPanel
          placeholder="제목, 등록자명으로 검색해주세요."
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          clearFilters={clearFilters}
          onSearch={handleSearch}
          dateRange={selectedDates}
          setDateRange={handleDateSearch}
          select={selectProps}
        />
        <CustomTableContainerTypes
          btnTitle="FAQ 등록"
          sortByIdx="faqIdx"
          handleAddItem={handleAddFaq}
          isLoading={isLoading}
          columns={columns || []}
          data={faqs || []}
          customPageSize={customPageSize}
          totalPage={totalPage}
          totalRecord={totalFaqs}
          setCustomPageSize={setCustomPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          className="custom-header-css table align-middle table-nowrap"
          tableClassName="table-centered align-middle table-nowrap mb-0"
          theadClassName="text-muted table-light"
        />
      </PageContainer>
    </React.Fragment>
  );
};

export default Faq;
