import { Col, ModalBody, ModalHeader, Spinner, Table } from "reactstrap";
import { CustomModal } from "../../../components/Common/styled";

type SlicingProps = {
  title: string;
  isLoading: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  data: string | undefined;
};

const SlicingModal = ({
  title,
  isLoading,
  isOpen,
  setIsOpen,
  data,
}: SlicingProps) => {
  return (
    <div>
      <Col sm={6} md={4} xl={3} className="mt-4">
        <CustomModal
          isOpen={isOpen}
          toggle={(prevState: boolean) => setIsOpen(!prevState)}
          centered={true}
        >
          <ModalHeader>
            {title}
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => setIsOpen(false)}
            ></button>
          </ModalHeader>
          <ModalBody>
            {isLoading ? (
              <Spinner className="me-2" color="secondary" />
            ) : (
              <div className="table-responsive">
                <Table className="mb-0">
                  <tbody>
                    <tr>
                      <td>{data}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}
          </ModalBody>
        </CustomModal>
      </Col>
    </div>
  );
};

export default SlicingModal;
