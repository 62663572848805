import { Col, Row } from "reactstrap";
import * as Api from "@/api";
import Card from "./components/Card";
import {
  AI_REQUEST_CARD_CONFIGS,
  AIPROCESS_REQUEST_CARD_CONFIGS,
  CONNECTION_CARD_CONFIGS,
  REGISTER_CARD_CONFIGS,
  SLICING_REQUEST_CARD_CONFIGS,
} from "./components/constants";
import { formatNumber } from "./utils";

type CardRowProps = {
  registration: Api.Response.UserRegistration | null;
  connection: Api.Response.UserConnection | null;
  ai: Api.Response.UserAiRequest | null;
  slicing: Api.Response.UserSlicingRequest | null;
  aiProcess: Api.Response.UserAiProcessingRequest | null;
};

const CardRow = ({
  registration,
  connection,
  ai,
  slicing,
  aiProcess,
}: CardRowProps) => {
  if (!registration || !connection || !ai || !slicing || !aiProcess) {
    return <p>Loading...</p>;
  }

  return (
    <Row>
      <Col className="col-12 col-lg d-flex flex-column">
        {REGISTER_CARD_CONFIGS.map((config, index) => (
          <Card
            key={index}
            icon={config.icon}
            countLabel={config.countLabel}
            rateLabel={config.rateLabel}
            count={formatNumber(registration[config.countKey])}
            rate={formatNumber(registration[config.rateKey])}
            color={config.color}
            isRatePositive={registration[config.rateKey] > 0}
          />
        ))}
      </Col>
      <Col className="col-12 col-lg d-flex flex-column">
        {CONNECTION_CARD_CONFIGS.map((config, index) => (
          <Card
            key={index}
            icon={config.icon}
            countLabel={config.countLabel}
            rateLabel={config.rateLabel}
            count={formatNumber(connection[config.countKey])}
            rate={formatNumber(connection[config.rateKey])}
            color={config.color}
            isRatePositive={connection[config.rateKey] > 0}
          />
        ))}
      </Col>
      <Col className="col-12 col-lg d-flex flex-column">
        {AI_REQUEST_CARD_CONFIGS.map((config, index) => (
          <Card
            icon={config.icon}
            key={index}
            countLabel={config.countLabel}
            rateLabel={config.rateLabel}
            count={formatNumber(ai[config.countKey])}
            rate={formatNumber(ai[config.rateKey])}
            color={config.color}
            isRatePositive={ai[config.rateKey] > 0}
          />
        ))}
      </Col>
      <Col className="col-12 col-lg d-flex flex-column">
        {SLICING_REQUEST_CARD_CONFIGS.map((config, index) => (
          <Card
            icon={config.icon}
            key={index}
            countLabel={config.countLabel}
            rateLabel={config.rateLabel}
            count={formatNumber(slicing[config.countKey])}
            rate={formatNumber(slicing[config.rateKey])}
            color={config.color}
            isRatePositive={slicing[config.rateKey] > 0}
          />
        ))}
      </Col>
      <Col className="col-12 col-lg d-flex flex-column">
        {AIPROCESS_REQUEST_CARD_CONFIGS.map((config, index) => (
          <Card
            icon={config.icon}
            key={index}
            countLabel={config.countLabel}
            rateLabel={config.rateLabel}
            count={formatNumber(aiProcess[config.countKey])}
            rate={formatNumber(aiProcess[config.rateKey])}
            color={config.color}
            isRatePositive={aiProcess[config.rateKey] > 0}
          />
        ))}
      </Col>
    </Row>
  );
};

export default CardRow;
