import * as ApiBase from "@metamorp/api-base";
import {
  ActiveRate,
  ApiRequestState,
  ConnectionState,
  ModelProcessing,
  NewSubscriber,
  RawData,
  RecentConnectedUser,
  RegistrationState,
  RequestByDay,
  RequestByTime,
  RequestCountry,
  RequestRegion,
  RequestRegKorea,
  RequestRegSeoul,
  TopConnectedUser,
  TopRequestModel,
  TopRequestRegion,
  UsedFunction,
  UserAiProcessingRequest,
  UserAiRequest,
  UserConnection,
  UserRegistration,
  UserSlicingRequest,
} from "./types";

const getRawData = () => {
  type Response = ApiBase.Types.ApiResBase<RawData>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/dashboard/card/raw/data",
  });
};

const getUserRegistration = () => {
  type Response = ApiBase.Types.ApiResBase<UserRegistration>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/dashboard/join/card",
  });
};

const getUserConnection = () => {
  type Response = ApiBase.Types.ApiResBase<UserConnection>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/dashboard/connect/card",
  });
};

const getAiData = () => {
  type Response = ApiBase.Types.ApiResBase<UserAiRequest>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/dashboard/ai/card",
  });
};

const getSlicingData = () => {
  type Response = ApiBase.Types.ApiResBase<UserSlicingRequest>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/dashboard/slicing/card",
  });
};

const getAiProcessingData = () => {
  type Response = ApiBase.Types.ApiResBase<UserAiProcessingRequest>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/dashboard/ai/rate/card",
  });
};

const getConnectionState = (days: number) => {
  type Response = ApiBase.Types.ApiResBase<ConnectionState>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/connect/state/${days}`,
  });
};

const getRegistrationState = (days: number) => {
  type Response = ApiBase.Types.ApiResBase<RegistrationState>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/join/state/${days}`,
  });
};

const getApiRequestState = (days: number) => {
  type Response = ApiBase.Types.ApiResBase<ApiRequestState>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/api/state/${days}`,
  });
};

const getTopRequestModel = (days: number) => {
  type Response = ApiBase.Types.ApiResBase<TopRequestModel>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/model/state/${days}`,
  });
};

const getRequestByTime = (days: number) => {
  type Response = ApiBase.Types.ApiResBase<RequestByTime>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/time/state/${days}`,
  });
};

const getRequestByDay = (days: number) => {
  type Response = ApiBase.Types.ApiResBase<RequestByDay>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/yoil/state/${days}`,
  });
};

const getRequestRegSeoul = (days: number) => {
  type Response = ApiBase.Types.ApiResBase<RequestRegSeoul>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/seoul/state/${days}`,
  });
};

const getRequestRegKorea = (days: number) => {
  type Response = ApiBase.Types.ApiResBase<RequestRegKorea>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/korea/state/${days}`,
  });
};

const getTopRequestRegion = (days: number) => {
  type Response = ApiBase.Types.ApiResBase<TopRequestRegion>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/top/country/state/${days}`,
  });
};

const getTopConnectedUser = () => {
  type Response = ApiBase.Types.ApiResBase<
    TopConnectedUser[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/top/connected`,
  });
};

const getActiveUserRate = (days: number) => {
  type Response = ApiBase.Types.ApiResBase<ActiveRate>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/active/state/${days}`,
  });
};

const getActiveUserWeekRate = (days: number) => {
  type Response = ApiBase.Types.ApiResBase<ActiveRate>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/active/week/state/${days}`,
  });
};

const getNewSubscribers = () => {
  type Response = ApiBase.Types.ApiResBase<
    NewSubscriber[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/top/join`,
  });
};

const getRecentUsers = () => {
  type Response = ApiBase.Types.ApiResBase<
    RecentConnectedUser[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/recent/users`,
  });
};

const getModelProcessing = (days: number) => {
  type Response = ApiBase.Types.ApiResBase<ModelProcessing>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/model/count/state/${days}`,
  });
};

const getRequestByCountry = (days: number) => {
  type Response = ApiBase.Types.ApiResBase<
    RequestCountry[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/req/country/state/${days}`,
  });
};

const getRequestByRegion = (days: number) => {
  type Response = ApiBase.Types.ApiResBase<
    RequestRegion[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/req/korea/state/${days}`,
  });
};

const getUseByFunction = (days: number) => {
  type Response = ApiBase.Types.ApiResBase<UsedFunction>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/action/state/${days}`,
  });
};

export default {
  /**
   * `GET /api/v1/dashboard/card/raw/data`
   *
   * @authorization-required
   *
   * Retrieve Raw Data.
   */
  getRawData: getRawData,
  /**
  /**
   * `GET /api/v1/dashboard/join/card`
   *
   * @authorization-required
   *
   * Retrieve user registration data.
   */
  getUserRegistration: getUserRegistration,
  /**
   * `GET api/v1/dashboard/connect/card`
   *
   * @authorization-required
   *
   * Retrieve user connection data.
   */
  getUserConnection: getUserConnection,
  /**
   * `GET /api/v1/dashboard/ai/card`
   *
   * @authorization-required
   *
   * Retrieve AI request data.
   */
  getAiData: getAiData,
  /**
   * `GET /api/v1/dashboard/slicing/card`
   *
   * @authorization-required
   *
   * Retrieve Slicing request data.
   */
  getSlicingData: getSlicingData,
  /**
   * `GET /api/v1/dashboard/ai/rate/card`
   *
   * @authorization-required
   *
   * Retrieve Ai Processing rate data.
   */
  getAiProcessingData: getAiProcessingData,
  /**
   * `GET api/v1/dashboard/connect/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve Connection state data.
   */
  getConnectionState: getConnectionState,
  /**
   * `GET api/v1/dashboard/join/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve Registration state data.
   */
  getRegistrationState: getRegistrationState,
  /**
   * `GET api/v1/dashboard/api/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve API Request state data.
   */
  getApiRequestState: getApiRequestState,
  /**
   * `GET api/v1/dashboard/model/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve Top Request Model data.
   */
  getTopRequestModel: getTopRequestModel,
  /**
   * `GET api/v1/dashboard/top/country/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve Top Request Region data.
   */
  getTopRequestRegion: getTopRequestRegion,
  /**
   * `GET api/v1/dashboard/top/connected`
   *
   * @authorization-required
   *
   * Retrieve Top Connected Users data.
   */
  getTopConnectedUser: getTopConnectedUser,
  /**
   * `GET api/v1/dashboard/active/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve App Active User Rate data.
   */
  getActiveUserRate: getActiveUserRate,
  /**
   * `GET api/v1/dashboard/active/state/week/${days}`
   *
   * @authorization-required
   *
   * Retrieve App Active User Rate data.
   */
  getActiveUserWeekRate: getActiveUserWeekRate,
  /**
   * `GET /api/v1/dashboard/top/join`
   *
   * @authorization-required
   *
   * Retrieve Recently Joined Users data.
   */
  getNewSubscribers: getNewSubscribers,
  /**
   * `GET /api/v1/dashboard/recent/users`
   *
   * @authorization-required
   *
   * Retrieve Recently Connected Users data.
   */
  getRecentUsers: getRecentUsers,
  /**
   * `GET api/v1/dashboard/req/country/state/%{days}`
   *
   * @authorization-required
   *
   * Retrieve Requests by Country data.
   */
  getRequestByCountry: getRequestByCountry,
  /**
   * `GET api/v1/dashboard/req/korea/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve Requests by Region data.
   */
  getRequestByRegion: getRequestByRegion,
  /**
   * `GET api/v1/dashboard/action/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve Frequency of use by function data.
   */
  getUseByFunction: getUseByFunction,
  /**
   * `GET api/v1/dashboard/time/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve Requests by Time.
   */
  getRequestByTime: getRequestByTime,
  /**
   * `GET api/v1/dashboard/yoil/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve Requests by Day of the week.
   */
  getRequestByDay: getRequestByDay,
  /**
   * `GET api/v1/dashboard/model/count/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve Daily Model Processing data.
   */
  getModelProcessing: getModelProcessing,
  /**
   * `GET api/v1/dashboard/seoul/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve Requests by region Seoul.
   */
  getRequestRegSeoul: getRequestRegSeoul,
  /**
   * `GET api/v1/dashboard/korea/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve Requests by Korea Cities.
   */
  getRequestRegKorea: getRequestRegKorea,
};
