import React, { useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { format } from "date-fns";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import * as Api from "@/api";
import CommonControlPanel from "@/components/Common/CommonControlPanel";
import { OptionProps } from "@/components/Common/types";
import CustomTableContainerTypes, {
  CustomColumn,
} from "../../components/Common/CustomTableContainerTypes";
import PageContainer from "../../components/Common/PageContainer";
import useQueryParams, { useStateRef } from "../../helpers/hooks";
import { ClickableCell } from "../Utility/CustomCellsType";

export const TYPE_OPTIONS = [
  { label: "전체", value: -1 },
  { label: "일반 프로젝트", value: 0 },
  { label: "슬라이싱요청 프로젝트", value: 1 },
];

const BREADCRUMB_ITEMS = [
  { title: "Project", link: "#" },
  { title: "List", link: "#" },
];

const Project = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams: queryString.ParsedQuery<string> = queryString.parse(
    location.search,
  );
  const { pageSize, page, keyword, dateRange, parseQueryParam } =
    useQueryParams();

  const [projects, setProjects] = useState<Api.Response.ProjectItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalProjects, setTotalProjects] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const searchKeywordRef = useStateRef(searchKeyword);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(dateRange);
  const [clients, setClients] = useState<OptionProps[]>([]);
  const [selectedClient, setSelectedClient] = useState<number>(
    parseQueryParam(queryParams.client) ?? -1,
  );
  const [selectedType, setSelectedType] = useState(
    parseQueryParam(queryParams.type) ?? TYPE_OPTIONS[0].value,
  );
  const [clearTrigger, setClearTrigger] = useState(false);

  useEffect(() => {
    const fetchClients = async () => {
      const response = await Api.Common.getClientList();
      const clients = response.data;
      const updatedClients = [
        {
          clientName: "전체",
          clientIdx: -1,
        },
        ...clients,
      ];
      const options = updatedClients.map((client) => ({
        label: client.clientName,
        value: client.clientIdx,
      }));
      setClients(options);
    };
    fetchClients();
  }, []);

  const fetchProjects = useCallback(async () => {
    setLoading(true);
    try {
      const searchKeyword = searchKeywordRef.current;
      const requestData = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        clientIdx: selectedClient,
        type: selectedType,
        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.Project.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalProjects(response.paging.totalRecord);
      setProjects(response.data);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error fetching projects", error);
      } else {
        console.error("Unexpected error:", error);
      }
    } finally {
      setLoading(false);
    }
  }, [
    currentPage,
    customPageSize,
    selectedClient,
    selectedType,
    selectedDates[1],
    clearTrigger,
  ]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchProjects();
  };

  const handleDateSearch = (newDateRange: [string, string]) => {
    setSelectedDates(newDateRange);
    setCurrentPage(0);
  };

  const handleClientSearch = (newClient: number) => {
    setSelectedClient(newClient);
    setCurrentPage(0);
  };

  const handleTypeSearch = (newType: number | string) => {
    setSelectedType(newType as number);
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setSelectedClient(-1);
    setSelectedType(TYPE_OPTIONS[0].value);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const handleDownload = (item: Api.Response.ProjectItem) => {
    const fileUrl = item.fileUrl;
    if (fileUrl) {
      window.location.href = fileUrl;
    } else {
      console.error("Response file URL is missing");
    }
  };

  const columns: CustomColumn<Api.Response.ProjectItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "projectIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "고객사명",
        accessor: "clientName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "프로젝트명",
        accessor: "projectName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "유형",
        accessor: "type",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.ProjectItem>) => {
          if (value === 0) {
            return <span>일반</span>;
          } else {
            return (
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => navigate("/slicing-request")}
              >
                {value === 0 ? "일반" : "슬라이싱"}
              </span>
            );
          }
        },
      },
      {
        Header: "프로젝트파일",
        accessor: "fileUrl",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }: CellProps<Api.Response.ProjectItem>) => (
          <ClickableCell
            value={
              <span>
                {" "}
                다운로드
                <i className="ri-download-line" />
              </span>
            }
            row={row.original}
            handleRowClick={handleDownload}
          />
        ),
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "갱신일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "갱신자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
      },
    ],
    [navigate],
  );

  const selectProps = [
    {
      selectTitle: "고객사",
      options: clients,
      selectedOption: selectedClient,
      setSelectedOption: handleClientSearch,
    },
    {
      selectTitle: "유형",
      options: TYPE_OPTIONS,
      selectedOption: selectedType,
      setSelectedOption: handleTypeSearch,
    },
  ];

  return (
    <React.Fragment>
      <PageContainer breadcrumbItems={BREADCRUMB_ITEMS} title="Project">
        <CommonControlPanel
          placeholder="프로젝트명, 등록자, 수정자명으로 검색해주세요."
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          clearFilters={clearFilters}
          onSearch={handleSearch}
          dateRange={selectedDates}
          setDateRange={handleDateSearch}
          select={selectProps}
        />
        <CustomTableContainerTypes
          sortByIdx="projectIdx"
          columns={columns || []}
          data={projects || []}
          customPageSize={customPageSize}
          totalPage={totalPage}
          totalRecord={totalProjects}
          setCustomPageSize={setCustomPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={loading}
          className="custom-header-css table align-middle table-nowrap"
          tableClassName="table-centered align-middle table-nowrap mb-0"
          theadClassName="text-muted table-light"
        />
      </PageContainer>
    </React.Fragment>
  );
};

export default Project;
