import * as ApiBase from "@metamorp/api-base";
import { GetRestrictedList, RestrictedItem } from "./types";

const getRestrictedList = (data: GetRestrictedList) => {
  type Response = ApiBase.Types.ApiResBase<
    RestrictedItem[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response, GetRestrictedList>({
    method: "POST",
    path: "/api/v1/user/block/list",
    data: data,
  });
};

export default {
  /**
   * `POST /api/v1/user/block/list`
   *
   * 차단 회원 목록을 조회합니다.
   *
   * Retrieve a restricted users list.
   */
  getList: getRestrictedList,
};
