import { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import { ToastContainer } from "react-toastify";
import { Button } from "reactstrap";
import * as Api from "@/api";
import CommonControlPanel from "@/components/Common/CommonControlPanel";
import CustomTableContainerTypes, {
  CustomColumn,
} from "@/components/Common/CustomTableContainerTypes";
import DeleteModal from "@/components/Common/DeleteModal";
import useQueryParams, {
  useLocalizedMessage,
  useStateRef,
} from "@/helpers/hooks";
import { ImageCellTypes } from "@/pages/Utility/CustomCellsType";
import { handleDeletePrinter } from "./utils";
import PageContainer from "../../components/Common/PageContainer";
import usePrinterStore from "../../zustandStore/printerStore";
import {
  ClickableCell,
  DisplayCell,
  RegTypeCell,
} from "../Utility/CustomCells";
import { REGISTRATION_OPTIONS, SHOW_OPTIONS } from "../Utility/Options";

const BREADCRUMB_ITEMS = [
  { title: "Printer Management", link: "#" },
  { title: "List", link: "#" },
];

const Printer = () => {
  const { printers, setPrinters, loading, setLoading } = usePrinterStore();
  const navigate = useNavigate();
  const location = useLocation();
  const getLocalizedMessage = useLocalizedMessage();
  const queryParams = queryString.parse(location.search);

  const { pageSize, page, keyword, dateRange, parseQueryParam } =
    useQueryParams();

  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalPrinters, setTotalPrinters] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const searchKeywordRef = useStateRef(searchKeyword);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(dateRange);
  const [selectedRegType, setSelectedRegType] = useState(() => {
    const parsedRegType = parseQueryParam(queryParams.regType);
    return parsedRegType !== null
      ? parsedRegType
      : REGISTRATION_OPTIONS[0].value;
  });
  const [selectedShow, setSelectedShow] = useState(() => {
    const parsedShow = parseQueryParam(queryParams.show);
    return parsedShow !== null ? parsedShow : SHOW_OPTIONS[0].value;
  });
  const [clearTrigger, setClearTrigger] = useState(false);
  const [printerToDelete, setPrinterToDelete] =
    useState<Api.Response.PrinterItem | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRowClick = useCallback(
    (item: { printerIdx: number }) => {
      navigate(`/printer/${item.printerIdx}${window.location.search}`);
    },
    [navigate],
  );

  const handleAddPrinter = () => {
    navigate(`/printer/new${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: selectedDates[0],
      endDt: selectedDates[1],
      regType: selectedRegType,
      show: selectedShow,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    selectedDates,
    searchKeyword,
    selectedRegType,
    selectedShow,
    location.pathname,
    navigate,
  ]);

  const fetchPrinters = useCallback(async () => {
    setLoading(true);

    try {
      const searchKeyword = searchKeywordRef.current;
      const requestData = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        displayFlag: selectedShow,
        defaultFlag: selectedRegType,
        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.Printer.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalPrinters(response.paging.totalRecord);
      setPrinters(response.data);
    } catch (error) {
      console.error("Error fetching Printers", error);
    } finally {
      setLoading(false);
    }
  }, [
    currentPage,
    customPageSize,
    selectedRegType,
    selectedShow,
    selectedDates[1],
    clearTrigger,
  ]);

  useEffect(() => {
    fetchPrinters();
  }, [fetchPrinters]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchPrinters();
  };

  const handleRegTypeSearch = (newRegType: number | string) => {
    setSelectedRegType(newRegType as number);
    setCurrentPage(0);
  };

  const handleShowSearch = (newShow: number | string) => {
    setSelectedShow(newShow as number);
    setCurrentPage(0);
  };

  const handleDateSearch = (newDateRange: [string, string]) => {
    setSelectedDates(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setSelectedRegType(REGISTRATION_OPTIONS[0].value);
    setSelectedShow(SHOW_OPTIONS[0].value);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const handleIsModalOpen = (item: Api.Response.PrinterItem) => {
    setPrinterToDelete(item);
    setIsModalOpen(true);
  };

  const handleDelete = async () => {
    if (!printerToDelete) return;
    const printerIdx = printerToDelete.printerIdx;
    await handleDeletePrinter({
      printerIdx,
      navigate,
      setLoading,
      getLocalizedMessage,
    });
    setIsModalOpen(false);
    fetchPrinters();
  };

  const selectProps = [
    {
      selectTitle: "기본프린터여부",
      options: REGISTRATION_OPTIONS,
      selectedOption: selectedRegType,
      setSelectedOption: handleRegTypeSearch,
    },
    {
      selectTitle: "표시여부",
      options: SHOW_OPTIONS,
      selectedOption: selectedShow,
      setSelectedOption: handleShowSearch,
    },
  ];

  const columns: CustomColumn<Api.Response.PrinterItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "printerIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "고객사",
        accessor: "clientName",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.PrinterItem>) => (
          <span style={{ color: value !== "메타몰프" ? "#5664d2" : "" }}>
            {value}
          </span>
        ),
      },
      {
        Header: "이미지",
        accessor: "imgPath",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.PrinterItem>) => (
          <ImageCellTypes value={value} type="printer" />
        ),
      },
      {
        Header: "프린터명",
        accessor: "printerName",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
          row,
        }: CellProps<Api.Response.PrinterItem>) => (
          <ClickableCell
            value={value}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "제조사명",
        accessor: "manufName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "기본프린터여부",
        accessor: "defaultFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.PrinterItem>) => (
          <RegTypeCell value={value} />
        ),
      },
      {
        Header: "표시여부",
        accessor: "displayFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.PrinterItem>) => (
          <DisplayCell value={value} />
        ),
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "선택",
        accessor: "select",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }: CellProps<Api.Response.PrinterItem>) => (
          <Button
            color="danger"
            className="btn btn-sm waves-effect waves-light me-1 d-flex justify-content-center align-items-center"
            onClick={() => handleIsModalOpen(row.original)}
          >
            <i className="ri-delete-bin-line pe-1 " />
            삭제
          </Button>
        ),
      },
    ],
    [handleRowClick],
  );

  return (
    <PageContainer
      breadcrumbItems={BREADCRUMB_ITEMS}
      title="Printer Management"
    >
      <CommonControlPanel
        placeholder="프린터명, 제조사명, 등록자, 수정자명으로 검색해주세요."
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        clearFilters={clearFilters}
        onSearch={handleSearch}
        dateRange={selectedDates}
        setDateRange={handleDateSearch}
        select={selectProps}
      />
      <CustomTableContainerTypes
        btnTitle="프린터 등록"
        sortByIdx="printerIdx"
        handleAddItem={handleAddPrinter}
        columns={columns || []}
        data={printers || []}
        customPageSize={customPageSize}
        totalPage={totalPage}
        totalRecord={totalPrinters}
        setCustomPageSize={setCustomPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoading={loading}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
      />
      <DeleteModal
        isLoading={loading}
        data={printerToDelete?.printerName}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onHandleDelete={handleDelete}
      />
      <ToastContainer />
    </PageContainer>
  );
};

export default Printer;
