import * as Api from "@/api";
import RequestByTime from "@/pages/Dashboard/RequestByTime";
import StatisticsBase from "./StatisticsBase";

const ConnectionByTime = () => {
  return (
    <>
      <StatisticsBase
        title="Connection By Time"
        defaultRange={30}
        columns={[
          {
            key: "label",
            name: "Connection By Time",
            label: "시간대",
            suffix: "시",
          },
          { key: "count", name: "Number of requests", label: "요청수" },
        ]}
        ChartComponent={RequestByTime}
        errorMessage="Failed fetching Api Request Connection by Time data"
        onRequestData={Api.ConnectionByTime.get}
        getDataList={(data) => data?.list || []}
        getDataForExport={(data) => data?.list || []}
      />
    </>
  );
};

export default ConnectionByTime;
