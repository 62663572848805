import * as ApiBase from "@metamorp/api-base";
import { ConnectionByDay } from "@/utils/api/features/Statistics/ConnectionByDay/type";
import { DateRange } from "../common/type";

const getConnectionByDay = (data: DateRange) => {
  type Response = ApiBase.Types.ApiResBase<ConnectionByDay>;
  return ApiBase.request<Response>({
    method: "POST",
    path: `/api/v1/statis/yoil/state`,
    data: data,
  });
};

export default {
  /**
   * `GET /api/v1/statis/yoil/state`
   *
   * @authorization-required
   *
   * Retrieve Connection by day data.
   */
  get: getConnectionByDay,
};
