import React, { useEffect, useRef } from "react";
import { CustomSelectContainer } from "../../pages/Users/styled";

const CustomSelector = (props) => {
  const isInitializedRef = useRef(false);

  useEffect(() => {
    if (!props.onSelectDefaultOption) return;
    if (props.options.length === 0) return;
    if (isInitializedRef.current) return;
    if (props.isDetailView) return;

    if (props.value === null) {
      props.onSelectDefaultOption(props.options[0].value);
    } else if (!props.options.some((option) => option.value === props.value)) {
      props.onSelectDefaultOption(-1);
    }

    isInitializedRef.current = true;
  }, [props]);

  return (
    <CustomSelectContainer>
      <select
        data-type="number"
        className="form-select"
        id={props.id}
        name={props.name}
        value={props.value === null ? "" : props.value}
        onChange={props.onChange}
      >
        {props.placeholder && (
          <option value={-1}>
            {props.isLoading ? "Loading..." : props.placeholder}
          </option>
        )}
        {props.options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </CustomSelectContainer>
  );
};

export default CustomSelector;
