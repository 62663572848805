import { ReactNode, useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import { Spinner } from "reactstrap";
import * as Api from "@/api";
import ChartBase from "./ChartBase";
import { getDate } from "./utils";

type ActiveUserWeekRateProps = {
  data: Api.Response.ActiveRate | null;
  selectedDays?: number;
  onDaysSelection?: (days: number) => void;
  isHeader?: boolean;
  isFullWidth?: boolean;
  isLoading?: boolean;
  children?: ReactNode;
};

const ActiveUserWeekRate = ({
  data,
  selectedDays,
  onDaysSelection,
  isHeader,
  isLoading,
  children,
}: ActiveUserWeekRateProps) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstanceRef = useRef<ApexCharts | null>(null);

  useEffect(() => {
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!chartRef.current || !data || isLoading) return;
    const chartOptions = {
      series: [
        {
          name: "앱활성율",
          type: "area", // % 값은 라인 형태로 표시
          data: data?.activeRateList,
        },
        {
          name: "방문자수",
          type: "bar", // 정수 값은 컬럼 형태로 표시
          data: data?.connectedCntList,
        },
      ],
      chart: {
        type: "line",
        height: 290,
        stacked: true,
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 7,
          left: 1,
          blur: 3,
          color: ["#000", "#000"],
          opacity: 0.1,
        },
      },
      grid: {
        show: true,
        borderColor: "#f1f1f1",
        strokeDashArray: 3,
      },
      colors: ["rgb(67,177,255)", "rgb(230,42,119)"],
      fill: {
        colors: ["rgba(67,177,255,0.1)", "rgb(230,42,119)"],
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "20%",
          borderRadius: "4",
        },
      },
      stroke: {
        curve: "smooth",
        width: [1.5, 1.5],
        lineCap: "round",
      },
      dataLabels: {
        enabled: true, // 데이터 레이블 표시 여부
        enabledOnSeries: [0], // 특정 시리즈에만 레이블 표시 (예: 라인 차트)
        formatter: function (val: string) {
          return val + "%";
        },
      },
      legend: {
        show: true,
        position: "top",
        offsetX: 0,
        offsetY: 8,
        markers: {
          width: 8,
          height: 8,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: [
        {
          opposite: true, // 라인 차트 y축을 오른쪽에 배치
          title: {
            text: "앱활성율 (%)", // % 값 y축 레이블
          },
          labels: {
            formatter: (value: string) => {
              return value + "%"; // y축 레이블에 % 추가
            },
          },
        },
        {
          title: {
            text: "방문자수", // 정수 값 y축 레이블
          },
        },
      ],
      xaxis: {
        show: false,
        type: "day",
        categories: data?.dateList,
        labels: {
          formatter: getDate,
        },
        axisBorder: {
          show: false,
          color: "rgba(119, 119, 142, 0.05)",
          offsetX: 0,
          offsetY: 0,
        },
      },
    };

    // If chart instance exists, update it instead of creating a new one
    if (chartInstanceRef.current) {
      chartInstanceRef.current.updateOptions(chartOptions, true, true);
      chartInstanceRef.current.updateSeries(
        [{ data: data?.activeRateList }, { data: data?.connectedCntList }],
        true,
      );
    } else {
      // Create new chart instance if it doesn't exist
      chartInstanceRef.current = new ApexCharts(chartRef.current, chartOptions);
      chartInstanceRef.current.render();
    }
  }, [data]);

  return (
    <ChartBase
      title="(주간)앱활성 사용자율"
      tooltipId="activeUserInfo"
      tooltipContent="선택기간동안 사용자접속률과 사용자수입니다."
      selectedDays={selectedDays}
      onDaysSelection={onDaysSelection}
      dayOptions={[7, 14, 30]}
      redirectUrl="/active-user-week-rate"
      minHeight="20.625rem"
      isHeader={isHeader}
    >
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center position-absolute top-0 start-0 end-0 bottom-0">
          <Spinner className="me-2" color="secondary" />
        </div>
      )}
      <div ref={chartRef}></div>
      {children}
    </ChartBase>
  );
};

export default ActiveUserWeekRate;
