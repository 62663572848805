import { create } from "zustand";
import * as Api from "@/api";

type UserState = {
  users: Api.Response.UserItem[];
  loading: boolean;
  setUsers: (users: Api.Response.UserItem[]) => void;
  setLoading: (loading: boolean) => void;
};

const useUserStore = create<UserState>((set) => ({
  users: [],
  loading: false,
  setUsers: (users) => set({ users }),
  setLoading: (loading) => set({ loading }),
}));

export default useUserStore;
