import React, { useState } from "react";
import classNames from "classnames";
import CardHeader, { CardHeaderProps } from "./components/CardHeader";
import { ChartContainer } from "./styled";

export type BaseProps = {
  isHeader?: boolean;
  isFullWidth?: boolean;
};

type ChartBaseProps = BaseProps &
  Omit<CardHeaderProps, "isHovered" | "setIsHovered"> & {
    height?: string;
    minHeight?: string;
    children?: React.ReactNode;
    cardBodyClass?: string;
  };

const ChartBase = ({
  minHeight,
  height,
  isHeader = false,
  isFullWidth,
  children,
  cardBodyClass,
  ...headerProps
}: ChartBaseProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <ChartContainer>
      <div
        className={classNames("card custom-card", {
          "overflow-hidden":
            headerProps.tooltipId === "recentlyConnectedUserInfo",
        })}
        style={{
          ...(headerProps.tooltipId === "reqRegionInfo" &&
            !isFullWidth && {
              height: "31.38rem",
            }),
          height: height,
        }}
      >
        {isHeader && (
          <CardHeader
            {...headerProps}
            isHovered={isHovered}
            setIsHovered={() => setIsHovered((prev) => !prev)}
          />
        )}
        <div className={`card-body overflow-auto ${cardBodyClass}`}>
          <div
            style={{
              minHeight: minHeight,
              ...(headerProps.tooltipId === "recentlyConnectedUserInfo" &&
              isFullWidth
                ? { minWidth: "31.25rem" }
                : {}),
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </ChartContainer>
  );
};

export default ChartBase;
