import * as Api from "@/api";
import StatisticsBase from "./StatisticsBase";
import RecentUsers from "../Dashboard/RecentUsers";

const RecentUsersList = () => {
  return (
    <StatisticsBase<Api.Response.RecentUsers[], Api.Response.RecentUsers>
      title="Recent Users"
      defaultRange={60}
      columns={[
        { key: "country", name: "Country", label: "Country" },
        { key: "userId", name: "ID", label: "ID" },
        { key: "ip", name: "IP", label: "IP" },
        { key: "timeAgo", name: "Time", label: "Time" },
        { key: "userIdx", name: "Dashboard", label: "Dashboard" },
      ]}
      ChartComponent={(props) => <RecentUsers {...props} height="100%" />}
      errorMessage="Failed fetching Recent Users data"
      onRequestData={Api.RecentUsersList.getList}
      getDataForExport={(data) => data || []}
      getDataList={(data) => data || []}
      showTable={false}
    />
  );
};

export default RecentUsersList;
