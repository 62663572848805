import { create } from "zustand";
import * as Api from "@/api";

type PrintingProfileStoreState = {
  profiles: Api.Response.PrintingProfileItem[];
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setProfiles: (profiles: Api.Response.PrintingProfileItem[]) => void;
  applications: Api.Response.CommonApplicationItem[];
  setApplications: (applications: Api.Response.CommonApplicationItem[]) => void;
  getApplicationsTypes: () => Promise<void>;
};

const usePrintingProfileStore = create<PrintingProfileStoreState>((set) => ({
  profiles: [],
  loading: false,
  error: null,
  applications: [],
  setProfiles: (profiles) => set({ profiles }),
  setLoading: (loading) => set({ loading }),
  setApplications: (applications) => set({ applications }),
  getApplicationsTypes: async () => {
    try {
      const response = await Api.Common.getApplicationList();

      set({ applications: response.data });
    } catch (error) {
      console.error("Error fetching applications:", error);
    }
  },
}));

export default usePrintingProfileStore;
