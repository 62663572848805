import * as ApiBase from "@metamorp/api-base";
import {
  Admin,
  AdminItem,
  PermissionAdmin,
} from "@/utils/api/features/MenuPermissionAdmin/types";

const getAllAdminsList = () => {
  type Response = ApiBase.Types.ApiResBase<AdminItem[]>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/role/admin/all/list",
  });
};

const getPermissionGroupAdmins = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<PermissionAdmin[]>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/role/admin/list/${idx}`,
  });
};

const addPermissionAdmin = (data: Admin) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, Admin>({
    method: "POST",
    path: "/api/v1/role/admin/add",
    data: data,
  });
};

const deletePermissionAdmin = (data: Admin) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, Admin>({
    method: "DELETE",
    path: `/api/v1/role/admin/delete`,
    data: data,
  });
};

export default {
  /**
   * `PUT /api/v1/role/admin/all/list`
   *
   * 관리자 전체 목록을 조회합니다.
   *
   * Retrieve all admins list.
   */
  getAll: getAllAdminsList,
  /**
   * `POST /api/v1/role/admin/list/:idx`
   *
   * 권한 관리자 목록을 조회합니다.
   *
   * Retrieve a permission group admins list.
   */
  getList: getPermissionGroupAdmins,
  /**
   * `POST /api/v1/role/admin/add`
   *
   * 권한 관리자를 추가합니다.
   *
   * Add a permission admin information.
   */
  add: addPermissionAdmin,
  /**
   * `DELETE /api/v1/role/admin/delete/`
   *
   * 권한 관리자를 삭제합니다.
   *
   * Delete a permission admin.
   */
  delete: deletePermissionAdmin,
};
