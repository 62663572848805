import * as ApiBase from "@metamorp/api-base";
import {
  AddPrintManufacturer,
  GetPrintManufacturerList,
  PrintManufacturerItem,
  PrintManufacturer,
  UpdatePrintManufacturer,
} from "@/utils/api/features/PrinterManufacturers/types";

const updateManufacturer = (data: UpdatePrintManufacturer) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, UpdatePrintManufacturer>({
    method: "PUT",
    path: "/api/v1/printer/manuf/update",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getManufacturerList = (data: GetPrintManufacturerList) => {
  type Response = ApiBase.Types.ApiResBase<
    PrintManufacturerItem[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response, GetPrintManufacturerList>({
    method: "POST",
    path: "/api/v1/printer/manuf/list",
    data: data,
  });
};

const addManufacturer = (data: AddPrintManufacturer) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, AddPrintManufacturer>({
    method: "POST",
    path: "/api/v1/printer/manuf/add",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getManufacturer = (pmIdx: number) => {
  type Response = ApiBase.Types.ApiResBase<PrintManufacturer>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/printer/manuf/${pmIdx}`,
  });
};

const deleteManufacturer = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response>({
    method: "DELETE",
    path: `/api/v1/printer/manuf/${idx}`,
  });
};

export default {
  /**
   * `PUT /api/v1/printer/manuf/update`
   *
   * @authorization-required
   *
   * Update a Printer Manufacturer.
   */
  update: updateManufacturer,
  /**
   * `POST /api/v1/printer/manuf/list`
   *
   * @authorization-required
   *
   * Retrieve a list of Manufacturers.
   */
  getList: getManufacturerList,
  /**
   * `POST /api/v1/printer/manuf/add`
   *
   * @authorization-required
   *
   * Add a Manufacturer.
   */
  add: addManufacturer,
  /**
   * `GET /api/v1/printer/manuf/:pmIdx`
   *
   * @authorization-required
   *
   * Retrieve a Manufacturer.
   */
  get: getManufacturer,
  /**
   * `DELETE /api/v1/printer/manuf/:idx`
   *
   * 어플리케이션을 삭제합니다.
   *
   * Delete a Manufacturer.
   */
  delete: deleteManufacturer,
};
