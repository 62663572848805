import React, { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import * as Api from "@/api";
import CommonControlPanel from "@/components/Common/CommonControlPanel";
import CustomTableContainerTypes, {
  CustomColumn,
} from "../../components/Common/CustomTableContainerTypes";
import PageContainer from "../../components/Common/PageContainer";
import useQueryParams, { useStateRef } from "../../helpers/hooks";
import { STATE_OPTIONS } from "../Customers";
import { ImageCell } from "../Utility/CustomCells";
import { ClickableCell } from "../Utility/CustomCellsType";

const BREADCRUMB_ITEMS = [
  { title: "Account Management", link: "#" },
  { title: "List", link: "#" },
];

const Account = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams: queryString.ParsedQuery<string> = queryString.parse(
    location.search,
  );
  const { pageSize, page, keyword, dateRange, parseQueryParam } =
    useQueryParams();

  const [isLoading, setIsLoading] = useState(false);
  const [accounts, setAccounts] = useState<Api.Response.AccountItem[]>([]);
  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalAccounts, setTotalAccounts] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const searchKeywordRef = useStateRef(searchKeyword);
  const [selectedDates, setSelectedDates] = useState(dateRange);
  const [selectedStatus, setSelectedStatus] = useState(
    parseQueryParam(queryParams.status) ?? STATE_OPTIONS[0].value,
  );
  const [clearTrigger, setClearTrigger] = useState(false);

  const handleRowClick = useCallback(
    (item: { adminIdx: number }) => {
      navigate(`/account/${item.adminIdx}${window.location.search}`);
    },
    [navigate],
  );

  const handleAddAccount = () => {
    navigate(`/account/new${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: selectedDates[0],
      endDt: selectedDates[1],
      status: selectedStatus,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    selectedDates,
    searchKeyword,
    selectedStatus,
    location.pathname,
    navigate,
  ]);

  const fetchAccounts = useCallback(async () => {
    setIsLoading(true);

    try {
      const searchKeyword = searchKeywordRef.current;
      const requestData: Api.Request.GetAccountList = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        stateFlag: selectedStatus,
        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.Account.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalAccounts(response.paging.totalRecord);
      setAccounts(response.data);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error fetching accounts", error);
      } else {
        console.error("Unexpected error", error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    currentPage,
    customPageSize,
    selectedStatus,
    selectedDates[1],
    clearTrigger,
  ]);

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchAccounts();
  };

  const handleDateSearch = (newDateRange: [string, string]) => {
    setSelectedDates(newDateRange);
    setCurrentPage(0);
  };

  const handleStatusSearch = (newStatus: number) => {
    setSelectedStatus(newStatus);
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setSelectedStatus(STATE_OPTIONS[0].value);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const columns: CustomColumn<Api.Response.AccountItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "adminIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "프로필",
        accessor: "imgPath",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.AccountItem>) => (
          <ImageCell value={value} isUser={true} />
        ),
      },
      {
        Header: "ID",
        accessor: "adminId",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
          row,
        }: CellProps<Api.Response.AccountItem>) => (
          <ClickableCell
            value={value}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "관리자명",
        accessor: "adminName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "직책",
        accessor: "positionName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "계정상태",
        accessor: "stateFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.AccountItem>) => (
          <span style={{ color: value === 0 ? "#1cbb8c" : "#ff3d5f" }}>
            {value === 0 ? "정상" : "정지"}
          </span>
        ),
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "갱신자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.AccountItem>) =>
          value.length > 0 ? value : "-",
      },
      {
        Header: "갱신일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.AccountItem>) =>
          value.length > 0 ? value : "-",
      },
    ],
    [handleRowClick],
  );

  const selectProps = [
    {
      selectTitle: "계정상태",
      options: STATE_OPTIONS,
      selectedOption: selectedStatus,
      setSelectedOption: handleStatusSearch,
    },
  ];

  return (
    <React.Fragment>
      <PageContainer
        breadcrumbItems={BREADCRUMB_ITEMS}
        title="Account Management"
      >
        <CommonControlPanel
          placeholder="ID, 관리자명으로 검색해주세요."
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          clearFilters={clearFilters}
          onSearch={handleSearch}
          dateRange={selectedDates}
          setDateRange={handleDateSearch}
          select={selectProps}
        />
        <CustomTableContainerTypes
          btnTitle="계정 등록"
          sortByIdx="adminIdx"
          handleAddItem={handleAddAccount}
          columns={columns || []}
          data={accounts || []}
          customPageSize={customPageSize}
          totalPage={totalPage}
          totalRecord={totalAccounts}
          setCustomPageSize={setCustomPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={isLoading}
          className="custom-header-css table align-middle table-nowrap"
          tableClassName="table-centered align-middle table-nowrap mb-0"
          theadClassName="text-muted table-light"
        />
      </PageContainer>
    </React.Fragment>
  );
};

export default Account;
