import { useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { Button } from "reactstrap";
import * as Api from "@/api";
import ConfirmModalTypes, {
  ActionType,
} from "@/components/Common/ConfirmModalTypes";
import FormButtonsTypes from "@/components/Common/FormButtonsTypes";
import ToggleSwitch from "@/components/Common/ToggleSwitch";
import { DetailViewProp } from "@/components/Common/types";
import FormInputTypes from "@/components/Profile/FormInputTypes";
import PrintingProfile from "@/pages/PrintingProfile";
import { ApplicationType } from "@/pages/PrintingProfile/PrintingProfileForm";
import { handleDeleteSupport } from "./utils";
import { PARAMS } from "./utils/params";
import { validateSupport } from "./utils/validate";
import PageContainer from "../../components/Common/PageContainer";
import RenderOptions from "../../components/Profile/classificationOptions";
import CustomInputField from "../../components/Profile/CustomInput";
import NavField from "../../components/Profile/NavField";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from "../../helpers/constants";
import { useLocalizedMessage } from "../../helpers/hooks";
import useSupportProfileStore from "../../zustandStore/supportProfileStore";

type ProfilesType = {
  inputValue: string;
  printingIdx: number | null;
  selectedData: Partial<Api.Response.PrintingProfileItem> | null;
};

export type SupportProps = Api.Request.AddSupportProfile &
  Partial<Pick<Api.Response.SupportProfile, "supportIdx" | "defaultFlag">>;

const defaultSupport: SupportProps = {
  profileName: "",
  displayFlag: 1,
  certifiFlag: 0,
  defaultFlag: 0,

  bumpHeight: null,
  pinDepth: null,
  pinTopDiameter: null,
  pinBottomDiameter: null,
  pinLength: null,
  pinJointDiameter: null,
  useVerticalPin: true,

  voxelSize: null,
  infillDistance: null,
  overhangAngle: null,
  borderOffset: null,
  lineDistance: null,
  minimumHeight: null,

  useBrace: true,
  useBase: true,
  columnTopDiameter: null,
  columnBottomDiameter: null,
  braceDiameter: null,
  baseHeight: null,
  baseDiameter: null,

  useRaft: true,
  raftHeight: null,
  raftOffset: null,
  raftHoleDistance: null,
  raftHoleDiameter: null,

  applicationsIdx: 5,
  type: 0,
  part: 0,
  size: 0,
  palatal: 0,
  bottom: 0,
  printingIdxList: [],
};

const SupportProfileForm = ({ isDetailView }: DetailViewProp) => {
  const navigate = useNavigate();
  const { supportIdx } = useParams();
  const location = useLocation();

  const { applications, getApplicationsTypes, loading, setLoading } =
    useSupportProfileStore();
  const getLocalizedMessage = useLocalizedMessage();

  const breadcrumbItems = [
    { title: "Support Profile", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];
  const [support, setSupport] = useState(defaultSupport);
  const [selectedApplication, setSelectedApplication] = useState({
    applicationsIdx: support.applicationsIdx,
    imgPath:
      applications.find(
        (app: ApplicationType) =>
          app.applicationsIdx === support.applicationsIdx,
      )?.imgPath || "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>("");
  const [validationErrors, setValidationErrors] = useState({});

  const [isOpenProfiles, setIsOpenProfiles] = useState(false);
  const [currentProfileIndex, setCurrentProfileIndex] = useState<number | null>(
    null,
  );
  const [profiles, setProfiles] = useState<ProfilesType[]>([]);

  const addNewProfile = () => {
    setProfiles([
      ...profiles,
      { inputValue: "", selectedData: null, printingIdx: null },
    ]);
  };

  const handleOpenProfiles = (idx: number) => {
    setCurrentProfileIndex(idx);
    setIsOpenProfiles(true);
  };

  const handleSelection = (
    selectedProfiles: Api.Response.PrintingProfileItem[],
  ) => {
    if (!Array.isArray(selectedProfiles) || selectedProfiles.length === 0) {
      console.error("Invalid selectedProfiles:", selectedProfiles);
      return;
    }

    const selectedProfile = selectedProfiles[0];
    if (currentProfileIndex !== null && selectedProfiles) {
      const isDuplicate = profiles.some(
        (profile, idx) =>
          idx !== currentProfileIndex &&
          profile.printingIdx === selectedProfile.printingIdx,
      );

      if (isDuplicate) {
        toast.error("이미 선택된 프로파일입니다.", {
          autoClose: 3000,
        });
        return;
      }

      const newProfiles = [...profiles];
      newProfiles[currentProfileIndex] = {
        inputValue: `${selectedProfile.printingIdx} / ${selectedProfile.profileName} / ${selectedProfile.printerName} / ${selectedProfile.materialName} / ${selectedProfile.classCode}`,
        selectedData: selectedProfile,
        printingIdx: selectedProfile.printingIdx,
      };

      setProfiles(newProfiles);

      const updatedPrintingIdxList = newProfiles
        .map((profile) => profile.printingIdx)
        .filter((idx) => idx !== null);

      setSupport((prev) => ({
        ...prev,
        printingIdxList: updatedPrintingIdxList,
      }));
    }

    setIsOpenProfiles(false);
  };

  const deleteProfile = (index: number) => {
    const newProfiles = profiles.filter((_, idx) => idx !== index);
    setProfiles(newProfiles);

    const updatedPrintingIdxList = newProfiles
      .map((profile) => profile.printingIdx)
      .filter((idx) => idx !== null);

    setSupport((prev) => ({
      ...prev,
      printingIdxList: updatedPrintingIdxList,
    }));
  };

  useEffect(() => {
    const fetchSupportByIdx = async () => {
      if (!supportIdx) return;

      const response = await Api.SupportProfile.get(parseInt(supportIdx, 10));
      const existingSupport = response.data;

      if (existingSupport) {
        const updatedPrintingIdxList = existingSupport.printingInfoList.map(
          (printingInfo) => printingInfo.printingIdx,
        );
        setSupport({
          ...existingSupport,
          printingIdxList: updatedPrintingIdxList,
        });

        setSelectedApplication({
          applicationsIdx: existingSupport.applicationsIdx,
          imgPath: existingSupport.applicationsImgPath,
        });

        if (
          existingSupport.printingInfoList &&
          existingSupport.printingInfoList.length > 0
        ) {
          const existingProfiles = existingSupport.printingInfoList.map(
            (printingInfo) => ({
              inputValue: `${printingInfo.printingIdx} / ${printingInfo.profileName} / ${printingInfo.printerName} / ${printingInfo.materialName} / ${printingInfo.classCode}`,
              selectedData: printingInfo,
              printingIdx: printingInfo.printingIdx,
            }),
          );
          setProfiles(existingProfiles);
        }
      } else {
        setSupport(defaultSupport);
        setProfiles([]);
      }
    };
    if (isDetailView) {
      fetchSupportByIdx();
    }
  }, [isDetailView, supportIdx]);

  useEffect(() => {
    getApplicationsTypes();
  }, [getApplicationsTypes]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    let newValue;

    if (type === "checkbox") {
      newValue = checked;
    } else if (
      value === null ||
      value === ""
      //   ||
      //   (!value?.length && isNaN(value)
      // )
    ) {
      newValue = null;
    } else if (type === "number") {
      newValue = parseFloat(value);
    } else {
      newValue = value;
    }

    setSupport((prevSupport) => ({ ...prevSupport, [name]: newValue }));
  };

  const handleApplicationTypeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedIdx = parseInt(e.target.value, 10);
    const selectedApp = applications.find(
      (app: Api.Response.CommonApplicationItem) =>
        app.applicationsIdx === selectedIdx,
    );

    setSelectedApplication({
      applicationsIdx: selectedIdx,
      imgPath: selectedApp.imgPath,
    });
    setSupport((prevProfile) => ({
      ...prevProfile,
      applicationsIdx: selectedIdx,
    }));
  };

  const openModal = (actionType: ActionType) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleValidationError = (id: string, errorMessage: string) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [id]: errorMessage,
    }));
  };

  const handleSaveSupport = async () => {
    const { isValid } = validateSupport({ support });

    if (!isValid) {
      toast.error("(*) 있는 모든 필드 입력해주세요", {
        autoClose: 3000,
      });
      return;
    }

    if (Object.values(validationErrors).some((error) => error)) {
      toast.error("저장하시기 전에 양식의 오류를 수정해 주세요", {
        autoClose: 3000,
      });
      return;
    }

    setLoading(true);

    try {
      const supportData = {
        profileName: support.profileName,
        displayFlag: support.displayFlag,
        certifiFlag: support.certifiFlag,
        bumpHeight: support.bumpHeight,
        pinDepth: support.pinDepth,
        pinTopDiameter: support.pinTopDiameter,
        pinBottomDiameter: support.pinBottomDiameter,
        pinLength: support.pinLength,
        pinJointDiameter: support.pinJointDiameter,
        useVerticalPin: support.useVerticalPin,

        voxelSize: support.voxelSize,
        infillDistance: support.infillDistance,
        overhangAngle: support.overhangAngle,
        borderOffset: support.borderOffset,
        lineDistance: support.lineDistance,
        minimumHeight: support.minimumHeight,

        useBrace: support.useBrace,
        useBase: support.useBase,
        columnTopDiameter: support.columnTopDiameter,
        columnBottomDiameter: support.columnBottomDiameter,
        braceDiameter: support.braceDiameter ?? 0,
        baseHeight: support.baseHeight ?? 0,
        baseDiameter: support.baseDiameter ?? 0,

        useRaft: support.useRaft,
        raftHeight: support.raftHeight ?? 0,
        raftOffset: support.raftOffset ?? 0,
        raftHoleDistance: support.raftHoleDistance ?? 0,
        raftHoleDiameter: support.raftHoleDiameter ?? 0,

        applicationsIdx: support.applicationsIdx,
        type: support.type ?? 0,
        part: support.part ?? 0,
        size: support.size ?? 0,
        palatal: support.palatal ?? 0,
        bottom: support.bottom ?? 0,
        printingIdxList: support.printingIdxList ?? [0],
      };

      let response;
      if (isDetailView) {
        if (!supportIdx) return;
        response = await Api.SupportProfile.update({
          ...supportData,
          supportIdx: parseInt(supportIdx, 10),
        });
      } else {
        response = await Api.SupportProfile.add(supportData);
      }

      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        if (isDetailView) {
          navigate(`/support-profile${location.search}`);
        } else {
          navigate("/support-profile");
        }
      }, 1000);
    } catch (error) {
      console.error("Error: adding new profile", error);
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = () => {
    if (!supportIdx) return;
    let idx = parseInt(supportIdx, 10);
    handleDeleteSupport({
      supportIdx: idx,
      navigate,
      setLoading,
      getLocalizedMessage,
    });
  };

  const handleCopySupport = async () => {
    setLoading(true);
    if (!supportIdx) return;

    try {
      const response = await Api.SupportProfile.copy(parseInt(supportIdx, 10));
      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, { autoClose: 3000 });
      setTimeout(() => {
        navigate("/support-profile");
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(`/support-profile${location.search}`);
  };

  useEffect(() => {
    if (!support.useBase) {
      setSupport((prevSupport) => ({
        ...prevSupport,
        baseHeight: null,
        baseDiameter: null,
      }));
    }
  }, [support.useBase]);

  useEffect(() => {
    if (!support.useBrace) {
      setSupport((prevSupport) => ({
        ...prevSupport,
        braceDiameter: null,
      }));
    }
  }, [support.useBrace]);

  useEffect(() => {
    if (!support.useRaft) {
      setSupport((prevSupport) => ({
        ...prevSupport,
        raftHeight: null,
        raftOffset: null,
        raftHoleDistance: null,
        raftHoleDiameter: null,
      }));
    }
  }, [support.useRaft]);

  return (
    <PageContainer breadcrumbItems={breadcrumbItems} title="Support Profile">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <AvForm>
                <NavField title="기본설정">
                  <FormButtonsTypes
                    isDetailView={isDetailView}
                    openModal={openModal}
                    handleCancel={handleCancel}
                    showCopyButton={true}
                  />
                </NavField>
                <Row>
                  <Col className="col-12 col-md-6">
                    <FormInputTypes
                      label="프로파일명"
                      id="profileName"
                      type="text"
                      placeholder="프로파일명을 입력해주세요"
                      value={support.profileName}
                      onChange={handleChange}
                      initialErrorMessage="프로파일명을 입력해주세요"
                      isRequired
                      validate={{ required: { value: true } }}
                    />
                  </Col>
                  <>
                    <Col>
                      <ToggleSwitch
                        isFullWidthLabel
                        label="표시여부"
                        id="displayFlag"
                        checked={support.displayFlag === 1}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSupport({ ...support, displayFlag: 1 });
                          } else {
                            setSupport({ ...support, displayFlag: 0 });
                          }
                        }}
                      />
                    </Col>
                    <Col>
                      <ToggleSwitch
                        isFullWidthLabel
                        label="인증여부"
                        id="certifiFlag"
                        checked={support.certifiFlag === 1}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSupport({ ...support, certifiFlag: 1 });
                          } else {
                            setSupport({ ...support, certifiFlag: 0 });
                          }
                        }}
                        disabled={isDetailView && support.defaultFlag === 0}
                        style={{
                          opacity:
                            isDetailView && support.defaultFlag === 0
                              ? "0.5"
                              : "",
                        }}
                      />
                    </Col>
                    {isDetailView && (
                      <Col>
                        <ToggleSwitch
                          label="기본여부"
                          id="defaultFlag"
                          checked={support.defaultFlag === 1}
                          disabled={true}
                          style={{ opacity: "0.5" }}
                          isFullWidthLabel
                        />
                      </Col>
                    )}
                  </>
                </Row>
                <Row className="mb-3 mt-3">
                  <Label htmlFor="addBtn">프린팅프로파일</Label>
                  <Col>
                    <Button
                      type="button"
                      color="custom-color"
                      className="btn me-2 custom-color"
                      onClick={addNewProfile}
                    >
                      추가
                    </Button>
                    <span className="card-title-desc mb-0 pb-0 ml-2">
                      서포트프로파일과 매핑되는 프린팅프로파일을 등록해주세요.
                    </span>
                  </Col>
                </Row>
                {profiles.map((profile, index) => (
                  <Row key={index} className="col-12 col-md-6 mb-2">
                    <InputGroup className="pe-0">
                      <Input
                        type="text"
                        className="form-control"
                        readOnly
                        value={profile.inputValue}
                        placeholder="프로파일번호 / 프로파일명 / 프린터명 / 소재명 / 구분코드 / 두깨"
                      />
                      <InputGroupText
                        type="button"
                        className="custom-color text-white"
                        onClick={() => handleOpenProfiles(index)}
                      >
                        선택
                      </InputGroupText>
                      <InputGroupText
                        type="button"
                        className="btn-danger text-white"
                        onClick={() => deleteProfile(index)}
                      >
                        삭제
                      </InputGroupText>
                    </InputGroup>
                  </Row>
                ))}
                <Row>
                  <Col className="col-12 col-md-6">
                    <NavField title="Hard" />
                    {PARAMS[0]?.params?.map((hard) => {
                      const min =
                        typeof hard.min === "function"
                          ? hard.min(support)
                          : hard.min;
                      const max =
                        typeof hard.max === "function"
                          ? hard.max(support)
                          : hard.max;

                      return hard.type === "number" ? (
                        <div key={hard.name}>
                          <CustomInputField
                            min={min}
                            max={max}
                            step={hard.decimal}
                            label={hard.label}
                            unit={hard.unit}
                            id={hard.name}
                            type={hard.type}
                            value={support[hard.name as keyof SupportProps]}
                            onChange={handleChange}
                            placeholder={hard.placeholder}
                            onValidationError={handleValidationError}
                            isRequired
                          />
                        </div>
                      ) : (
                        <div key={hard.name}>
                          <ToggleSwitch
                            label={hard.label}
                            id={hard.name}
                            checked={
                              Boolean(
                                support[hard.name as keyof SupportProps],
                              ) || false
                            }
                            onChange={(e) =>
                              setSupport({
                                ...support,
                                [hard.name]: e.target.checked,
                              })
                            }
                          />
                        </div>
                      );
                    })}
                  </Col>
                  <Col className="col-12 col-md-6">
                    <NavField title="Supporting Point" />
                    {PARAMS[1]?.params?.map((point) => (
                      <div key={point.name}>
                        <CustomInputField
                          min={point.min}
                          max={point.max}
                          step={point.decimal}
                          label={point.label}
                          unit={point.unit}
                          id={point.name}
                          type={point.type}
                          value={support[point.name as keyof SupportProps]}
                          onChange={handleChange}
                          placeholder={point.placeholder}
                          onValidationError={handleValidationError}
                          isRequired
                        />
                      </div>
                    ))}
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12 col-md-6">
                    <NavField title="Body" />
                    {PARAMS[2]?.params?.map((body) => {
                      const min =
                        typeof body.min === "function"
                          ? body.min(support)
                          : body.min;
                      const max =
                        typeof body.max === "function"
                          ? body.max(support)
                          : body.max;

                      const isDisabled =
                        (body.name === "braceDiameter" && !support.useBrace) ||
                        (body.name === "baseHeight" && !support.useBase) ||
                        (body.name === "baseDiameter" && !support.useBase);

                      return body.type === "number" ? (
                        <div key={body.name}>
                          <CustomInputField
                            min={min}
                            max={max}
                            step={body.decimal}
                            label={body.label}
                            unit={body.unit}
                            id={body.name}
                            type={body.type}
                            value={support[body.name as keyof SupportProps]}
                            onChange={handleChange}
                            placeholder={body.placeholder}
                            onValidationError={handleValidationError}
                            isRequired={!isDisabled}
                            disabled={isDisabled}
                          />
                        </div>
                      ) : (
                        <Col className="mb-3" key={body.name}>
                          <ToggleSwitch
                            label={body.label}
                            id={body.name}
                            checked={
                              Boolean(
                                support[body.name as keyof SupportProps],
                              ) || false
                            }
                            onChange={(e) =>
                              setSupport({
                                ...support,
                                [body.name]: e.target.checked,
                              })
                            }
                          />
                        </Col>
                      );
                    })}
                  </Col>

                  <Col className="col-12 col-md-6">
                    <NavField title="Raft" />
                    {PARAMS[3]?.params?.map((raft) => {
                      const min =
                        typeof raft.min === "function"
                          ? raft.min(support)
                          : raft.min;
                      const max =
                        typeof raft.max === "function"
                          ? raft.max(support)
                          : raft.max;

                      return raft.type === "boolean" ? (
                        <Col className="mb-4" key={raft.name}>
                          <ToggleSwitch
                            label={raft.label}
                            id={raft.name}
                            checked={
                              Boolean(
                                support[raft.name as keyof SupportProps],
                              ) || false
                            }
                            onChange={(e) =>
                              setSupport({
                                ...support,
                                [raft.name]: e.target.checked,
                              })
                            }
                          />
                        </Col>
                      ) : (
                        <div key={raft.name}>
                          <CustomInputField
                            min={min}
                            max={max}
                            step={raft.decimal}
                            label={raft.label}
                            unit={raft.unit}
                            id={raft.name}
                            type={raft.type}
                            value={support[raft.name as keyof SupportProps]}
                            onChange={handleChange}
                            placeholder={raft.placeholder}
                            onValidationError={handleValidationError}
                            isRequired={!support.useRaft ? false : true}
                            disabled={!support.useRaft}
                          />
                        </div>
                      );
                    })}
                  </Col>
                </Row>
                <NavField title="Classification" />
                <Row className="mb-3" style={{ display: "flex", gap: "70px" }}>
                  <Col xl="2">
                    <img
                      src={selectedApplication.imgPath}
                      alt={`application-${support.applicationsIdx}`}
                      className="rounded avatar-lg"
                      style={{ objectFit: "contain" }}
                    />
                  </Col>
                  <Col xl="2" className="mb-4">
                    <Label className="form-label h6">Applications</Label>
                    {applications.map(
                      (type: Api.Response.CommonApplicationItem) => (
                        <div
                          className="form-check mb-2"
                          key={type.applicationsIdx}
                        >
                          <Input
                            type="radio"
                            id={`application-${type.applicationsIdx}`}
                            name="applicationsIdx"
                            className="form-check-input"
                            value={type.applicationsIdx}
                            checked={
                              selectedApplication.applicationsIdx ===
                              type.applicationsIdx
                            }
                            onChange={handleApplicationTypeChange}
                          />
                          <Label
                            htmlFor={`application-${type.applicationsIdx}`}
                            className="form-check-label"
                          >
                            {type.name}
                          </Label>
                        </div>
                      ),
                    )}
                  </Col>
                  <Col>
                    <RenderOptions
                      profile={support}
                      selectedApplication={selectedApplication}
                      handleChange={handleChange}
                    />
                  </Col>
                </Row>
                <FormButtonsTypes
                  isDetailView={isDetailView}
                  openModal={openModal}
                  handleCancel={handleCancel}
                  showCopyButton={true}
                />
                <ConfirmModalTypes
                  isLoading={loading}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  action={action}
                  handleSave={handleSaveSupport}
                  handleDelete={handleDelete}
                  handleCopy={handleCopySupport}
                />
                <Modal
                  size="xl"
                  isOpen={isOpenProfiles}
                  toggle={() => setIsOpenProfiles((prevState) => !prevState)}
                >
                  <ModalHeader
                    toggle={(prevState) => setIsOpenProfiles(!prevState)}
                  >
                    프린팅프로파일찾기
                  </ModalHeader>
                  <ModalBody>
                    <PrintingProfile isModal onChange={handleSelection} />
                  </ModalBody>
                </Modal>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </PageContainer>
  );
};

export default SupportProfileForm;
