import { ReactNode, useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { FeatureCollection } from "geojson";
import { Spinner } from "reactstrap";
import * as topojson from "topojson-client";
import * as Api from "@/api";
import ChartBase from "./ChartBase";
import { getColor } from "./utils";
import { formatNumber } from "./utils/index";
import worldMap from "../../assets/xintra/json/world-50.v1.json";

type RequestByCountryProps = {
  data: Api.Response.RequestCountry[] | null;
  selectedDays?: number;
  onDaysSelection?: (days: number) => void;
  isHeader?: boolean;
  isFullWidth?: boolean;
  isLoading?: boolean;
  children?: ReactNode;
};

const RequestByCountry = ({
  data,
  selectedDays,
  onDaysSelection,
  isHeader,
  isLoading,
  children,
}: RequestByCountryProps) => {
  const worldMapRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState("");
  const chartInstanceRef = useRef<{
    svg: d3.Selection<SVGSVGElement, unknown, null, undefined> | null;
    projection: d3.GeoProjection | null;
    zoom: d3.ZoomBehavior<Element, unknown> | null;
  }>({ svg: null, projection: null, zoom: null });

  useEffect(() => {
    return () => {
      if (chartInstanceRef.current.svg) {
        chartInstanceRef.current.svg.remove();
        chartInstanceRef.current = { svg: null, projection: null, zoom: null };
      }
    };
  }, []);

  useEffect(() => {
    if (!worldMapRef.current || !data || isLoading) return;

    // This is  "mapChart"
    // Clear previous SVG and error message
    d3.select(worldMapRef.current).selectAll("svg").remove();

    // Set dimensions and margins
    const width = worldMapRef.current.clientWidth;
    const height = worldMapRef.current.clientHeight;

    // Create an SVG element with viewBox
    const svg = d3
      .select(worldMapRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", `0 0 ${width} ${height}`) // Add viewBox
      .attr("preserveAspectRatio", "xMidYMid meet"); // Preserve aspect ratio

    // Create a projection
    const projection = d3.geoMercator().translate([width / 2, height / 2]);
    // Create a path generator
    const path = d3.geoPath().projection(projection);
    // Create a group for map and markers
    const g = svg.append("g");

    try {
      // Draw the map
      g.append("path")
        .datum(
          topojson.feature(
            worldMap as any,
            (worldMap as any).objects.countries,
          ),
        )
        .attr("d", path as any)
        .attr("class", "map")
        .attr("fill", "#d6d6d6")
        .attr("stroke", "#8b8b8b")
        .attr("stroke-width", 0.5);

      // Add bg-color for a single country on hover
      const features = (
        topojson.feature(
          worldMap as any,
          (worldMap as any).objects.countries,
        ) as unknown as FeatureCollection
      ).features;

      g.selectAll(".country")
        .data(features)
        .enter()
        .append("path")
        .attr("d", path as any)
        .attr("class", "country")
        .attr("fill", "#d6d6d6")
        .attr("stroke", "#8b8b8b")
        .attr("stroke-width", 0.5)
        .on("mouseover", function () {
          d3.select(this).transition().duration(200).attr("fill", "#8b8b8b");
        })
        .on("mouseout", function () {
          d3.select(this).transition().duration(200).attr("fill", "#d6d6d6");
        });

      // Add markers
      const markerGroup = g
        .selectAll(".marker")
        .data(data)
        .enter()
        .append("circle")
        .attr("class", "marker")
        .attr("cx", (d: Api.Response.RequestCountry) => {
          const proj = projection([Number(d.lon), Number(d.lat)]);
          return proj ? proj[0] : 0;
        })
        .attr("cy", (d: Api.Response.RequestCountry) => {
          const proj = projection([Number(d.lon), Number(d.lat)]);
          return proj ? proj[1] : 0;
        })
        .attr("r", 5)
        .attr("fill", (d) => getColor(d.cnt))
        .attr("stroke", "white")
        .attr("stroke-width", 1.5);

      // Create a tooltip div
      const tooltip = d3
        .select(worldMapRef.current)
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0)
        .style("position", "absolute")
        .style("color", "white")
        .style("background-color", "#333")
        .style("border", "1px solid #ddd")
        .style("padding", "10px")
        .style("border-radius", "5px")
        .style("pointer-events", "none");

      markerGroup
        .on("mouseover", function (event, d: Api.Response.RequestCountry) {
          tooltip.transition().duration(200).style("opacity", 0.9);
          tooltip
            .html(`${d.country}<br/>요청수: ${formatNumber(d.cnt)}회`)
            .style("left", event.offsetX + 20 + "px")
            .style("top", event.offsetY - 10 + "px");
        })
        .on("mouseout", function () {
          tooltip.transition().duration(500).style("opacity", 0);
        });

      // Set up zoom functionality
      const zoom = d3
        .zoom<SVGSVGElement, unknown>()
        .scaleExtent([0.5, 8]) // Set minimum and maximum zoom levels
        .on("zoom", function (event: d3.D3ZoomEvent<SVGSVGElement, unknown>) {
          g.attr("transform", event.transform.toString());
          markerGroup.attr("r", 5 / event.transform.k);
          markerGroup.attr("stroke-width", 1.5 / event.transform.k);
        });

      svg.call(zoom as any);

      // Handle resizing
      const resizeMap = () => {
        if (!worldMapRef.current) return;

        const newWidth = worldMapRef.current.clientWidth;
        const newHeight = worldMapRef.current.clientHeight;

        // Resize SVG
        svg
          .attr("width", newWidth)
          .attr("height", newHeight)
          .attr("viewBox", `0 0 ${newWidth} ${newHeight}`);

        // Update projection
        projection
          .scale(newWidth / 2 / Math.PI)
          .translate([newWidth / 2, newHeight / 2]);

        // Redraw the map and markers
        g.selectAll("path").attr("d", path as any);
        markerGroup
          .attr("cx", (d: Api.Response.RequestCountry) => {
            const proj = projection([Number(d.lon), Number(d.lat)]);
            return proj ? proj[0] : 0;
          })
          .attr("cy", (d: Api.Response.RequestCountry) => {
            const proj = projection([Number(d.lon), Number(d.lat)]);
            return proj ? proj[1] : 0;
          });
      };
      window.addEventListener("resize", resizeMap);

      // Cleanup function
      return () => {
        window.removeEventListener("resize", resizeMap);
        d3.select(worldMapRef.current).select("svg").remove();
      };
    } catch (err) {
      console.error("Error rendering map:", err);
      setError(
        "An error occurred while rendering the map. Please try again later.",
      );
    }
  }, [data]);

  return (
    <ChartBase
      title="국가별 요청분포"
      tooltipId="countryInfo"
      tooltipContent="선택기간동안 국가별 요청분포도입니다."
      selectedDays={selectedDays}
      onDaysSelection={onDaysSelection}
      dayOptions={[30, 60, 90]}
      redirectUrl="/requesting-countries"
      minHeight="25rem"
      isHeader={isHeader}
      cardBodyClass={isHeader ? "pt-0" : "pt-4"}
    >
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center position-absolute top-0 start-0 end-0 bottom-0">
          <Spinner className="me-2" color="secondary" />
        </div>
      )}
      {error ? (
        <div className="alert alert-danger">{error}</div>
      ) : (
        <div ref={worldMapRef} style={{ width: "100%", height: "25rem" }}></div>
      )}
      {children}
    </ChartBase>
  );
};

export default RequestByCountry;
