import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import * as Api from "@/api";
import ChartBase from "./ChartBase";
import noImg from "../../assets/images/no-image.jpg";

type RecentUsersProps = {
  data: Api.Response.RecentConnectedUser[] | null;
  isHeader?: boolean;
  isFullWidth?: boolean;
  isLoading?: boolean;
  children?: ReactNode;
  height?: string;
};

const RecentUsers = ({
  data,
  isHeader,
  isFullWidth = false,
  isLoading,
  children,
  height,
}: RecentUsersProps) => {
  const navigate = useNavigate();
  const moveToDashBoard = (userIdx: number) => {
    navigate(`/users/dashboard/${userIdx}${window.location.search}`);
  };

  return (
    <ChartBase
      title="최근접속자"
      tooltipId="recentlyConnectedUserInfo"
      tooltipContent="최근 접속한 사용자 5명입니다."
      redirectUrl="/recent-users"
      height={height || "25.875rem"}
      isHeader={isHeader}
      cardBodyClass={isFullWidth ? "px-5" : "p-0"}
      isFullWidth={isFullWidth}
    >
      {children}
      {isFullWidth ? (
        <>
          <ul className="list-group list-group-flush mb-0 border-bottom">
            <li className="list-group-item">
              <div className="d-flex flex-wrap align-items-center justify-content-between flex-xl-nowrap pl-3">
                <div
                  className="d-flex align-items-center"
                  style={{ width: isFullWidth && "40%" }}
                >
                  <div className="col-10 col-md-8 text-start">
                    <span className="fs-14 fw-bold">프로필</span>
                  </div>
                </div>
                <div
                  className="col-2 col-md-2 text-start"
                  style={{ width: isFullWidth && "20%" }}
                >
                  <span className="fs-14 1 fw-bold">IP</span>
                </div>
                <div
                  className="col-2 col-md-2 text-start"
                  style={{ width: isFullWidth && "20%" }}
                >
                  <span className=" fs-14  fw-bold">일자</span>
                </div>
                <div
                  className="col-2 col-md-2 text-end"
                  style={{ width: isFullWidth && "15%" }}
                >
                  <span className=" fs-14  fw-bold">대시보드</span>
                </div>
              </div>
            </li>
          </ul>
          <ul className="list-group list-group-flush mb-0">
            {isLoading && (
              <div className="d-flex justify-content-center align-items-center position-absolute top-0 start-0 end-0 bottom-0">
                <Spinner className="me-2" color="secondary" />
              </div>
            )}
            {data?.map((user) => (
              <li
                className="list-group-item border-bottom"
                key={user.userId}
                style={{ width: "100%" }}
              >
                <div className="d-flex flex-wrap align-items-center justify-content-between w-100 pl-3">
                  <div
                    className="d-flex align-items-center"
                    style={{ width: isFullWidth && "40%" }}
                  >
                    <div
                      className="me-2 lh-1 col-2 col-md-2"
                      style={{ width: "2.5rem" }}
                    >
                      <span className="avatar avatar-md avatar-rounded">
                        <img src={user.imgPath || noImg} alt="User Photo" />
                      </span>
                    </div>
                    <div
                      className="col-8 col-md-6"
                      style={{ minWidth: "10rem" }}
                    >
                      <p className="mb-0" style={{ lineHeight: "1.2188rem" }}>
                        <span className="fw-bold text-dark">
                          {user.nickName}
                        </span>{" "}
                        <span className=" fw-bold text-primary">
                          {user.country}
                        </span>
                      </p>
                      <span
                        className="text-muted fs-12 fw-bold"
                        style={{ lineHeight: "1.2188rem" }}
                      >
                        {user.userId}
                      </span>
                    </div>
                  </div>

                  <div
                    className="col-2 text-end "
                    style={{
                      width: isFullWidth && "20%",
                    }}
                  >
                    <span className="text-muted fs-12 mt-1 ms-1 fw-bold d-flex align-right">
                      {user.ip}
                    </span>
                  </div>
                  <div style={{ width: isFullWidth && "20%" }}>
                    <span className="text-muted fs-12 mt-1 ms-1 fw-bold d-flex ">
                      {user.timeAgo}
                    </span>
                  </div>

                  <div
                    className="col-2 col-md-2 text-end"
                    style={{ width: isFullWidth && "15%" }}
                  >
                    <Button
                      type="button"
                      size="sm"
                      color="dark"
                      onClick={() => moveToDashBoard(user.userIdx)}
                    >
                      보기
                    </Button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <ul className="list-group list-group-flush mb-0">
          {isLoading && (
            <div className="d-flex justify-content-center align-items-center position-absolute top-0 start-0 end-0 bottom-0">
              <Spinner className="me-2" color="secondary" />
            </div>
          )}
          {data?.map((user) => (
            <li
              className="list-group-item border-bottom "
              key={user.userId}
              style={{ width: "100%" }}
            >
              <div className="d-flex flex-wrap align-items-center justify-content-between w-100 pl-3">
                <div className="d-flex align-items-center">
                  <div
                    className="me-2 lh-1 col-2 col-md-2"
                    style={{ width: "2.5rem" }}
                  >
                    <span className="avatar avatar-md avatar-rounded">
                      <img src={user.imgPath || noImg} alt="User Photo" />
                    </span>
                  </div>
                  <div className="col-md-10">
                    <p className="mb-0" style={{ lineHeight: "1.2188rem" }}>
                      <span className="fw-bold text-dark">{user.nickName}</span>{" "}
                      <span className=" fw-bold text-primary">
                        {user.country}
                      </span>
                    </p>
                    <span
                      className="text-muted fs-12 fw-bold"
                      style={{ lineHeight: "1.2188rem" }}
                    >
                      {user.userId}
                    </span>
                  </div>
                </div>

                <div
                  className="col-2 text-end "
                  style={{ marginRight: "2.5rem" }}
                >
                  <span className="text-muted fs-12 mt-1 ms-1 fw-bold d-flex align-right">
                    {user.ip}
                  </span>
                </div>
                <div>
                  <span className="text-muted fs-12 mt-1 ms-1 fw-bold d-flex align-right">
                    {user.timeAgo}
                  </span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </ChartBase>
  );
};

export default RecentUsers;
