import { useCallback, useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, Col, Row } from "reactstrap";
import * as Api from "@/api";
import CustomLabel from "@/components/Common/CustomLabel";
import ToggleSwitch from "@/components/Common/ToggleSwitch";
import { DetailViewProp } from "@/components/Common/types";
import CustomCardBody from "./components/CustomCardBody";
import { extractFileDetails } from "./utils";
import ConfirmModalTypes, {
  ActionType,
} from "../../components/Common/ConfirmModalTypes";
import DraggableFileInput from "../../components/Common/DraggableFileInput";
import FormButtonsTypes from "../../components/Common/FormButtonsTypes";
import PageContainer from "../../components/Common/PageContainer";
import FormInputTypes from "../../components/Profile/FormInputTypes";
import Selector from "../../components/Profile/Selector";
import { useLocalizedMessage } from "../../helpers/hooks";
import useVersionStore from "../../zustandStore/versionStore";
import CustomCkEditor from "../EmailTemplate/components/CustomCkEditor";

type VersionProps = Omit<Api.Request.AddVersion, "appFile" | "ymlFile"> &
  Partial<
    Pick<Api.Response.Version, "versionIdx" | "fileUrl" | "ymlUrl"> &
      Pick<Api.Request.AddVersion, "appFile" | "ymlFile">
  >;

export type OsType = "WIN" | "MAC";

const osTypeOptions: { label: string; value: string }[] = [
  { label: "WIN", value: "WIN" },
  { label: "MAC", value: "MAC" },
];

const DEFAULT_VERSION: VersionProps = {
  versionName: "",
  postfix: "",
  osType: osTypeOptions[0].value as OsType,
  appFile: null as unknown as File,
  ymlFile: null as unknown as File,
  improvements: "",
  newFeatures: "",
  bugsFixed: "",
  displayFlag: 0,
};

const VersionForm = ({ isDetailView }: DetailViewProp) => {
  const { loading, setLoading } = useVersionStore();
  const { versionIdx } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const getLocalizedMessage = useLocalizedMessage();

  const breadcrumbItems = [
    { title: "Version Management", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];

  const [version, setVersion] = useState(DEFAULT_VERSION);
  const [appFileName, setAppFileName] = useState("");
  const [ymlFileName, setYmlFileName] = useState("");
  const [downloadFileName, setDownloadFileName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>("");

  const getVersionByIdx = useCallback(async () => {
    try {
      if (!versionIdx) return;
      const response = await Api.Version.get(parseInt(versionIdx));
      const existingVersion = response.data;
      if (existingVersion) {
        setVersion(existingVersion);
        setDownloadFileName(existingVersion?.fileUrl?.split("/").pop() || "");
        setAppFileName(existingVersion?.fileUrl?.split("/").pop() || "");
        setYmlFileName(existingVersion?.ymlUrl?.split("/").pop() || "");
      } else {
        setVersion(DEFAULT_VERSION);
      }
    } catch (error) {
      console.error("Error fetching Version from API", error);
    }
  }, [versionIdx]);

  useEffect(() => {
    if (isDetailView) {
      getVersionByIdx();
    }
  }, [getVersionByIdx, isDetailView]);

  const handleChange = (e: {
    target: {
      name: string;
      value: string;
      type?: string;
      checked?: boolean;
      files?: FileList | null;
    };
  }) => {
    const { name, value, type, checked, files } = e.target;
    const newValue = type === "checkbox" ? checked : files ? files[0] : value;

    if (newValue instanceof File) {
      const { versionName, postfix, osType } = extractFileDetails(
        newValue.name,
      );

      if (name === "appFile") {
        setAppFileName(newValue.name || "name");
        setVersion((prevVer) => ({
          ...prevVer,
          osType: osType || prevVer.osType,
          appFile: newValue,
          versionName: versionName || "",
          postfix: postfix || "",
        }));
      } else if (name === "ymlFile") {
        setYmlFileName(newValue.name || "");
      }
    } else {
      if (name === "appFile") {
        setAppFileName("");
      } else if (name === "ymlFile") {
        setYmlFileName("");
      }
    }
    setVersion((prevVer) => ({ ...prevVer, [name]: newValue }));
  };

  const handleFilesDropped = (files: FileList) => {
    const filesArray = Array.from(files);

    filesArray.forEach((file) => {
      const fileName = file?.name;

      if (fileName === "latest.yml" || fileName === "latest-mac.yml") {
        setVersion((prevVer: VersionProps) => ({
          ...prevVer,
          ymlFile: file,
        }));
        setYmlFileName(fileName);
      } else {
        const { versionName, postfix, osType } = extractFileDetails(fileName);

        if (osType) {
          setVersion((prevVer: VersionProps) => ({
            ...prevVer,
            osType,
            appFile: file,
            versionName: versionName || "",
            postfix: postfix || "",
          }));
          setAppFileName(fileName);
        }
      }
    });
  };

  useEffect(() => {
    if (
      (version.osType === "MAC" && ymlFileName === "latest.yml") ||
      (version.osType === "WIN" && ymlFileName === "latest-mac.yml")
    ) {
      toast.error("Check latest file!", {
        autoClose: 3000,
      });
    }
  }, [version.osType, ymlFileName]);

  const openModal = (actionType: ActionType) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleCancel = () => {
    navigate(`/app-version${location.search}`);
  };

  const handleSaveVersion = async () => {
    if (
      !version.versionName ||
      (!isDetailView && !version.ymlFile) ||
      (!isDetailView && !version.appFile)
    ) {
      toast.error("(*) 필드 모두 입력해주세요", {
        autoClose: 3000,
      });
      return;
    }

    setLoading(true);
    const method = isDetailView ? "PUT" : "POST";

    try {
      const requestData = {
        versionName: version.versionName,
        postfix: version.postfix,
        osType: version.osType,
        appFile: version.appFile,
        ymlFile: version.ymlFile,
        improvements: version.improvements,
        newFeatures: version.newFeatures,
        bugsFixed: version.bugsFixed,
        displayFlag: version.displayFlag,
      };

      let response;
      if (method === "PUT") {
        if (typeof version?.versionIdx !== "number") return;

        response = await Api.Version.update({
          ...(requestData as Api.Request.UpdateVersion),
          versionIdx: (version as Api.Request.UpdateVersion).versionIdx,
        });
      } else if (method === "POST") {
        response = await Api.Version.add(requestData as Api.Request.AddVersion);
      }

      const successMessage = getLocalizedMessage(response, "success");
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        if (isDetailView) {
          navigate(`/app-version${location.search}`);
        } else {
          navigate("/app-version");
        }
      }, 1000);
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        console.error("Error: adding new app version", error);
        const errMessage = getLocalizedMessage(error, "error");
        toast.error(errMessage, {
          autoClose: 3000,
        });
      }
    } finally {
      setLoading(false);
      setIsOpen(false);
    }
  };

  const [focusedEditors, setFocusedEditors] = useState({
    improvements: false,
    newFeatures: false,
    bugsFixed: false,
  });

  const setFocusAs = (editorName: string, focusState: boolean) => {
    setFocusedEditors((prev) => ({ ...prev, [editorName]: focusState }));
  };

  const handleDownload = (fileUrl: string) => {
    if (!fileUrl) {
      console.error("File URL is not available");
      return;
    }
    window.location.href = fileUrl;
  };

  return (
    <PageContainer breadcrumbItems={breadcrumbItems} title="Version Management">
      <Row>
        <Col xs={12}>
          <Card>
            <CustomCardBody onFilesDropped={handleFilesDropped}>
              <h4 className="card-title">앱 정보를 입력해주세요</h4>
              <AvForm className="mt-4">
                <Row>
                  <FormInputTypes
                    id="versionName"
                    label="버전명"
                    placeholder="버전명을 입력해주세요"
                    type="text"
                    value={version.versionName || ""}
                    onChange={handleChange}
                    initialErrorMessage="버전명을 입력해주세요"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    isRequired
                  />
                </Row>
                <Row>
                  <FormInputTypes
                    id="postfix"
                    label="Postfix"
                    placeholder="Postfix를 입력해주세요"
                    type="text"
                    value={version.postfix || ""}
                    onChange={handleChange}
                    initialErrorMessage="Postfix를 입력해주세요"
                  />
                </Row>
                <Row className="mb-3">
                  <Selector
                    label="OS구분"
                    id="osType"
                    options={osTypeOptions}
                    value={version.osType}
                    onChange={handleChange}
                    isRequired
                  />
                </Row>
                <Row className="mb-3">
                  <CustomLabel htmlFor="appFile" title="APP 파일" isRequired />
                  {isDetailView && version?.fileUrl ? (
                    <DraggableFileInput
                      name="appFile"
                      fileName={
                        appFileName ?? version?.fileUrl.split("/").pop()
                      }
                      onChange={handleChange}
                    />
                  ) : (
                    <DraggableFileInput
                      name="appFile"
                      fileName={appFileName}
                      onChange={handleChange}
                      handleFilesDropped={handleFilesDropped}
                    />
                  )}
                </Row>
                <Row className="mb-3">
                  <CustomLabel
                    htmlFor="ymlFile"
                    title="latest 파일"
                    isRequired
                  />
                  {isDetailView && version?.ymlUrl ? (
                    <DraggableFileInput
                      name="ymlFile"
                      fileName={ymlFileName ?? version?.ymlUrl.split("/").pop()}
                      onChange={handleChange}
                    />
                  ) : (
                    <DraggableFileInput
                      name="ymlFile"
                      fileName={ymlFileName}
                      onChange={handleChange}
                      handleFilesDropped={handleFilesDropped}
                    />
                  )}
                </Row>
                <Row className="mb-3">
                  <CustomLabel htmlFor="improvements" title="개선사항" />
                  <Col>
                    <CustomCkEditor
                      id="improvements"
                      data={version.improvements || ""}
                      isFocused={focusedEditors.improvements}
                      handleFocus={() => setFocusAs("improvements", true)}
                      handleBlur={() => setFocusAs("improvements", false)}
                      onChange={(data: string) =>
                        handleChange({
                          target: { name: "improvements", value: data },
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <CustomLabel htmlFor="newFeatures" title="신규기능" />
                  <Col>
                    <CustomCkEditor
                      id="newFeatures"
                      data={version.newFeatures || ""}
                      isFocused={focusedEditors.newFeatures}
                      handleFocus={() => setFocusAs("newFeatures", true)}
                      handleBlur={() => setFocusAs("newFeatures", false)}
                      onChange={(data: string) =>
                        handleChange({
                          target: { name: "newFeatures", value: data },
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <CustomLabel htmlFor="bugsFixed" title="오류수정" />
                  <Col>
                    <CustomCkEditor
                      id="bugsFixed"
                      data={version.bugsFixed || ""}
                      isFocused={focusedEditors.bugsFixed}
                      handleFocus={() => setFocusAs("bugsFixed", true)}
                      handleBlur={() => setFocusAs("bugsFixed", false)}
                      onChange={(data: string) =>
                        handleChange({
                          target: { name: "bugsFixed", value: data },
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <ToggleSwitch
                    label="표시여부"
                    id="displayFlag"
                    checked={version.displayFlag === 1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.checked) {
                        setVersion({ ...version, displayFlag: 1 });
                      } else {
                        setVersion({ ...version, displayFlag: 0 });
                      }
                    }}
                    extraClass="mt-2"
                  />
                </Row>

                <Row>
                  <FormButtonsTypes
                    isDetailView={isDetailView}
                    openModal={openModal}
                    handleCancel={handleCancel}
                    hideDeleteButton={true}
                    showDownloadButton={true}
                    downloadFileName={downloadFileName}
                    handleDownload={() =>
                      handleDownload(version?.fileUrl || "")
                    }
                  />
                </Row>

                <ConfirmModalTypes
                  isLoading={loading}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  action={action}
                  handleSave={handleSaveVersion}
                  loadMessage="업로드 중..."
                  // handleDelete={handleDeleteUser}
                />
              </AvForm>
            </CustomCardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </PageContainer>
  );
};

export default VersionForm;
