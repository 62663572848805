import * as ApiBase from "@metamorp/api-base";
import {
  AddGroup,
  GroupItem,
  UpdateGroup,
} from "@/utils/api/features/MenuPermissionGroup/types";

const updatePermissionGroup = (data: UpdateGroup) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, UpdateGroup>({
    method: "PUT",
    path: "/api/v1/role/update",
    data: data,
  });
};

const getPermissionGroupList = () => {
  type Response = ApiBase.Types.ApiResBase<GroupItem[]>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/role/list",
  });
};

const addPermissionGroup = (data: AddGroup) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, AddGroup>({
    method: "POST",
    path: "/api/v1/role/add",
    data: data,
  });
};

const deletePermissionGroup = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response>({
    method: "DELETE",
    path: `/api/v1/role/delete/${idx}`,
  });
};

export default {
  /**
   * `PUT /api/v1/role/update`
   *
   * 권한 그룹 정보를 수정합니다.
   *
   * Update permission group information.
   */
  update: updatePermissionGroup,
  /**
   * `POST /api/v1/role/list`
   *
   * 권한 그룹을 조회합니다.
   *
   * Retrieve a permission group list.
   */
  getList: getPermissionGroupList,
  /**
   * `POST /api/v1/role/add`
   *
   * 권한 그룹을 추가합니다.
   *
   * Add a permission group information.
   */
  add: addPermissionGroup,
  /**
   * `DELETE /api/v1/role/delete/:idx`
   *
   * 권한 그룹을 삭제합니다.
   *
   * Delete a permission group.
   */
  delete: deletePermissionGroup,
};
