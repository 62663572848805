import * as ApiBase from "@metamorp/api-base";
import { ConnectionBySeoulRegion } from "@/utils/api/features/Statistics/ConnectionBySeoulRegion/type";
import { DateRange } from "../common/type";

const getConnectionBySeoulRegion = (data: DateRange) => {
  type Response = ApiBase.Types.ApiResBase<ConnectionBySeoulRegion>;
  return ApiBase.request<Response>({
    method: "POST",
    path: `/api/v1/statis/seoul/state`,
    data: data,
  });
};

export default {
  /**
   * `GET /api/v1/statis/seoul/state`
   *
   * @authorization-required
   *
   * Retrieve Connection by Seoul Region.
   */
  get: getConnectionBySeoulRegion,
};
