import React, { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import { CellProps } from "react-table";
import { Button } from "reactstrap";
import * as Api from "@/api";
import VersionChangesModal from "./components";
import { OsType } from "./VersionForm";
import ControlPanelTypes from "../../components/Common/ControlPanelTypes";
import CustomTableContainerTypes, {
  type CustomColumn,
} from "../../components/Common/CustomTableContainerTypes";
import PageContainer from "../../components/Common/PageContainer";
import useQueryParams, { useStateRef } from "../../helpers/hooks";
import useVersionStore from "../../zustandStore/versionStore";
import { DisplayCell, OsTypeCell } from "../Utility/CustomCells";
import { ClickableCell } from "../Utility/CustomCellsType";
import { SHOW_OPTIONS } from "../Utility/Options";

export const osTypeOptions: { label: string; value: string }[] = [
  { label: "전체", value: "all" },
  { label: "Windows", value: "WIN" },
  { label: "MacOS", value: "MAC" },
];

const BREADCRUMB_ITEMS = [
  { title: "Version Management", link: "#" },
  { title: "List", link: "#" },
];

const Version = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { versions, setVersions, loading, setLoading, setError } =
    useVersionStore();
  const queryParams: queryString.ParsedQuery<string> = queryString.parse(
    location.search,
  );
  const { pageSize, page, keyword, dateRange, parseQueryParam } =
    useQueryParams();
  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalVersions, setTotalVersions] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const searchKeywordRef = useStateRef(searchKeyword);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(dateRange);
  const [selectedOsType, setSelectedOsType] = useState(
    (queryParams.osType as string) || osTypeOptions[0].value,
  );
  const [selectedShow, setSelectedShow] = useState(() => {
    const parsedShow = parseQueryParam(queryParams.show);
    return parsedShow !== null ? parsedShow : SHOW_OPTIONS[0].value;
  });
  const [clearTrigger, setClearTrigger] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVersion, setSelectedVersion] =
    useState<Api.Response.VersionItem | null>(null);

  const handleRowClick = useCallback(
    (item: { versionIdx: number }) => {
      navigate(`/app-version/${item.versionIdx}${window.location.search}`);
    },
    [navigate],
  );

  const handleAddUser = () => {
    navigate(`/app-version/new${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: selectedDates[0],
      endDt: selectedDates[1],
      osType: selectedOsType,
      show: selectedShow,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    selectedDates,
    searchKeyword,
    selectedOsType,
    selectedShow,
    location.pathname,
    navigate,
  ]);

  const fetchVersions = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const searchKeyword = searchKeywordRef.current;
      const requestData = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        displayFlag: selectedShow,
        osType:
          selectedOsType === "all" ? undefined : (selectedOsType as OsType),

        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.Version.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalVersions(response.paging.totalRecord);
      setVersions(response.data);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
        console.error("Failed fetching App Versions", error);
      } else {
        console.error("Unexpected error:", error);
      }
    } finally {
      setLoading(false);
    }
  }, [
    currentPage,
    customPageSize,
    selectedOsType,
    selectedShow,
    selectedDates[1],
    clearTrigger,
  ]);

  useEffect(() => {
    fetchVersions();
  }, [fetchVersions]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchVersions();
  };

  const handleOsTypeSearch = (newType: string) => {
    setSelectedOsType(newType);
    setCurrentPage(0);
  };

  const handleShowSearch = (newShow: number | string) => {
    setCurrentPage(0);
    setSelectedShow(newShow as number);
  };

  const handleDateSearch = (newDateRange: [string, string]) => {
    setSelectedDates(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = async () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setSelectedOsType(osTypeOptions[0].value);
    setSelectedShow(SHOW_OPTIONS[0].value);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const handleDownload = (item: Api.Response.VersionItem) => {
    const fileUrl = item.fileUrl;
    window.location.href = fileUrl;
  };

  const handleIsModalOpen = (item: Api.Response.VersionItem) => {
    setSelectedVersion(item);
    setIsModalOpen(true);
  };

  const columns: CustomColumn<Api.Response.VersionItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "versionIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "버전명",
        accessor: "versionName",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
          row,
        }: CellProps<Api.Response.VersionItem>) => (
          <ClickableCell
            value={value}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "OS",
        accessor: "osType",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.VersionItem>) => (
          <OsTypeCell value={value} />
        ),
      },
      {
        Header: "Postfix",
        accessor: "postfix",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.VersionItem>) =>
          value ? value : "-",
      },
      {
        Header: "표시여부",
        accessor: "displayFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.VersionItem>) => (
          <DisplayCell value={value} />
        ),
      },
      {
        Header: "변경내용",
        accessor: "bugsFixed",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }: { row: { original: Api.Response.VersionItem } }) => (
          <ClickableCell
            value={
              <Button
                color="primary"
                outline
                className="btn btn-sm waves-effect waves-light me-1 btn-persistent"
              >
                <i className="ri-eye-line" />
              </Button>
            }
            row={row.original}
            handleRowClick={handleIsModalOpen}
          />
        ),
      },
      {
        Header: "다운로드",
        fileUrl: "download",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }: { row: { original: Api.Response.VersionItem } }) => (
          <ClickableCell
            value={
              <span>
                다운로드
                <i className="ri-download-line" />
              </span>
            }
            row={row.original}
            handleRowClick={handleDownload}
          />
        ),
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
    ],
    [handleRowClick],
  );

  return (
    <React.Fragment>
      <PageContainer
        title="Version Management"
        breadcrumbItems={BREADCRUMB_ITEMS}
      >
        <ControlPanelTypes
          placeholder="버전명, Postfix, 등록자, 수정자명으로 검색해주세요."
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          clearFilters={clearFilters}
          onSearch={handleSearch}
          dateRange={selectedDates}
          setDateRange={handleDateSearch}
          options1={osTypeOptions}
          option1Type="string"
          options2={SHOW_OPTIONS}
          option2Type="number"
          selectedOption1={selectedOsType}
          setSelectedOption1={handleOsTypeSearch}
          selectedOption2={selectedShow}
          setSelectedOption2={handleShowSearch}
          selectTitle1="OS 구분"
          selectTitle2="표시 여부"
        />
        <CustomTableContainerTypes
          btnTitle="버전 등록"
          sortByIdx="versionIdx"
          handleAddItem={handleAddUser}
          isLoading={loading}
          columns={columns || []}
          data={versions || []}
          customPageSize={customPageSize}
          totalPage={totalPage}
          totalRecord={totalVersions}
          setCustomPageSize={setCustomPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          className="custom-header-css table align-middle table-nowrap"
          tableClassName="table-centered align-middle table-nowrap mb-0"
          theadClassName="text-muted table-light"
        />
        <VersionChangesModal
          isLoading={loading}
          title="변경사항"
          data={selectedVersion}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
        />
      </PageContainer>
    </React.Fragment>
  );
};

export default Version;
