import * as Api from "@/api";
import StatisticsBase from "./StatisticsBase";
import TopConnected from "../Dashboard/TopConnected";

const TopUserRanking = () => {
  return (
    <StatisticsBase<Api.Response.TopUserRanking[], Api.Response.TopUserRanking>
      title="Top User Ranking"
      defaultRange={60}
      columns={[
        { key: "rank", name: "Rank", label: "순위" }, // Adding Rank column
        { key: "nickName", name: "Nickname", label: "Nickname" },
        { key: "country", name: "Country", label: "Country" },
        { key: "id", name: "ID", label: "ID" },
        { key: "connectedCnt", name: "Connected Days", label: "접속일수" },
        {
          key: "percentageCurrent",
          name: "Connection Rate(%)",
          label: "접속률",
        },
        {
          key: "percentageChange",
          name: "Compared to Previous Month(%)",
          label: "전월대비",
        },
      ]}
      ChartComponent={(props) => <TopConnected {...props} height="100%" />}
      errorMessage="Failed fetching Top User Ranking data"
      onRequestData={Api.TopUserRanking.getList}
      getDataForExport={(data) => data || []}
      getDataList={(data) => data || []}
      showTable={false}
    />
  );
};

export default TopUserRanking;
