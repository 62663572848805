import { useCallback, useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import * as Api from "@/api";
import ConfirmModalTypes, {
  ActionType,
} from "@/components/Common/ConfirmModalTypes";
import CustomFileInputTypes from "@/components/Common/CustomFileInputTypes";
import CustomLabel from "@/components/Common/CustomLabel";
import FormButtonsTypes from "@/components/Common/FormButtonsTypes";
import ToggleSwitch from "@/components/Common/ToggleSwitch";
import { DetailViewProp } from "@/components/Common/types";
import FormInputTypes from "@/components/Profile/FormInputTypes";
import PageContainer from "../../components/Common/PageContainer";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from "../../helpers/constants";
import { useLocalizedMessage } from "../../helpers/hooks";
import useAppTypesStore from "../../zustandStore/appTypesStore";
import { CardImgContainer } from "../Utility/styled";

type AppTypeProps = Api.Request.AddApplication &
  Partial<Pick<Api.Response.Application, "applicationsIdx" | "imgPath">>;

const DEFAULT_APP: AppTypeProps = {
  name: "",
  displayFlag: 1,
  file: null,
};

const AppTypeForm = ({ isDetailView }: DetailViewProp) => {
  const { applicationsIdx } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, setLoading } = useAppTypesStore();
  const getLocalizedMessage = useLocalizedMessage();

  const breadcrumbItems = [
    { title: "App Type Management", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];
  const [app, setApp] = useState(DEFAULT_APP);
  const [fileName, setFileName] = useState(app.file?.name || "");
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>("");

  const getApplicationByIdx = useCallback(async () => {
    if (!applicationsIdx) return;

    try {
      const response = await Api.Application.get(parseInt(applicationsIdx, 10));
      const existingApp = response.data;
      if (existingApp) {
        setApp(existingApp);
      } else {
        setApp({
          name: "",
          displayFlag: 1,
          file: null,
        });
      }
    } catch (error) {
      console.error("Error fetching app from API", error);
    }
  }, [applicationsIdx]);

  useEffect(() => {
    if (isDetailView) {
      getApplicationByIdx();
    }
  }, [getApplicationByIdx, isDetailView]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked, files } = e.target;
    const newValue = type === "checkbox" ? checked : files ? files[0] : value;
    if (name === "file" && newValue instanceof File) {
      setFileName(newValue ? newValue?.name : "");
    }
    setApp((prevUser) => ({ ...prevUser, [name]: newValue }));
  };

  const openModal = (actionType: ActionType) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleSaveApp = async () => {
    setLoading(true);

    try {
      let response;
      const requestData = {
        name: app.name,
        displayFlag: app.displayFlag,
        file: app.file,
      };

      if (isDetailView) {
        if (!app.name) {
          toast.error("어플리케이션명을 입력해주세요", {
            autoClose: 3000,
          });
          return;
        }
        if (typeof app?.applicationsIdx !== "number") return;
        response = await Api.Application.update({
          ...requestData,
          applicationsIdx: app.applicationsIdx,
        });
      } else {
        if (!app.name || !app.file) {
          toast.error("어플리케이션명과 이미지를 입력해주세요", {
            autoClose: 3000,
          });
          return;
        }
        response = await Api.Application.add(requestData);
      }

      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        if (isDetailView) {
          navigate(`/app-type${location.search}`);
        } else {
          navigate("/app-type");
        }
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteApp = async () => {
    if (!app.applicationsIdx) return;
    setLoading(true);
    try {
      const response = await Api.Application.delete(app.applicationsIdx);
      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        navigate("/app-type");
      }, 1000);
    } catch (error) {
      console.error("Error deleting application:", error);
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(`/app-type${location.search}`);
  };

  return (
    <PageContainer
      breadcrumbItems={breadcrumbItems}
      title="App Type Management"
    >
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h4 className="card-title">
                어플리케이션 타입정보를 입력해주세요.
              </h4>
              <AvForm className="mt-4">
                <Row>
                  <FormInputTypes
                    id="name"
                    label="어플리케이션명"
                    placeholder="어플리케이션명을 입력해주세요"
                    type="text"
                    value={app.name || ""}
                    onChange={handleChange}
                    initialErrorMessage="어플리케이션명을 입력해주세요"
                    validate={{ required: { value: true } }}
                    isRequired
                  />
                </Row>
                <Row className="mb-3">
                  <CustomLabel
                    htmlFor="customFileInput"
                    title="이미지"
                    isRequired
                  />
                  <CustomFileInputTypes
                    fileName={fileName}
                    onChange={handleChange}
                  />
                </Row>
                {isDetailView && !app.file && (
                  <Row className="mb-2">
                    <Col>
                      <Label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-form-label"
                      />
                      <CardImgContainer
                        style={{ marginLeft: "4px" }}
                        src={app.imgPath}
                        alt="Application"
                        className="rounded avatar-lg"
                      />
                    </Col>
                  </Row>
                )}
                <Row className="mb-2">
                  <ToggleSwitch
                    label="표시여부"
                    id="displayFlag"
                    checked={app.displayFlag === 1 ? true : false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setApp({ ...app, displayFlag: 1 });
                      } else {
                        setApp({ ...app, displayFlag: 0 });
                      }
                    }}
                    extraClass="mt-2"
                  />
                </Row>
                <FormButtonsTypes
                  isDetailView={isDetailView}
                  openModal={openModal}
                  handleCancel={handleCancel}
                  hideDeleteButton
                />
                <ConfirmModalTypes
                  isLoading={loading}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  action={action}
                  handleSave={handleSaveApp}
                  handleDelete={handleDeleteApp}
                />
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </PageContainer>
  );
};

export default AppTypeForm;
