import { useState, useEffect, useCallback } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import * as Api from "@/api";
import ConfirmModalTypes, {
  ActionType,
} from "@/components/Common/ConfirmModalTypes";
import CustomFileInputTypes from "@/components/Common/CustomFileInputTypes";
import CustomLabel from "@/components/Common/CustomLabel";
import FormButtonsTypes from "@/components/Common/FormButtonsTypes";
import PageContainer from "@/components/Common/PageContainer.js";
import ToggleSwitch from "@/components/Common/ToggleSwitch";
import { DetailViewProp } from "@/components/Common/types";
import FormInputTypes from "@/components/Profile/FormInputTypes";
import SelectorTypes from "@/components/Profile/SelectorTypes";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from "@/helpers/constants";
import { useLocalizedMessage } from "@/helpers/hooks";
import { CardImgContainer } from "@/pages/Utility/styled.js";
import useUserStore from "@/zustandStore/userStore";
import { MEMBER_STATUS_OPTIONS } from "./index";

type UserProps = Api.Request.AddUser &
  Partial<Pick<Api.Response.User, "userIdx" | "stateFlag" | "imgPath">>;

const DEFAULT_USER: UserProps = {
  userId: "",
  userPw: "",
  nickName: "",
  job: "",
  phone: "",
  newsAgree: true,
  adAgree: true,
  file: null,
};

const UserForm = ({ isDetailView }: DetailViewProp) => {
  const { loading, setLoading } = useUserStore();
  const { userIdx } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const getLocalizedMessage = useLocalizedMessage();

  const breadcrumbItems = [
    { title: "Members", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];

  const [user, setUser] = useState(DEFAULT_USER);
  const [fileName, setFileName] = useState(user.file?.name || "");
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>("");

  const filteredStatus = MEMBER_STATUS_OPTIONS.slice(1);

  const getUserByIdx = useCallback(async () => {
    if (!userIdx) return;
    try {
      const response = await Api.User.get(parseInt(userIdx, 10));
      const existingUser = response.data;
      if (existingUser) {
        setUser(existingUser);
      } else {
        setUser(DEFAULT_USER);
      }
    } catch (error) {
      console.error("Error fetching User from API", error);
    }
  }, [userIdx]);

  useEffect(() => {
    if (isDetailView) {
      getUserByIdx();
    }
  }, [getUserByIdx, isDetailView]);

  const handleCancel = () => {
    navigate(`/users${location.search}`);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value, type } = e.target as
      | HTMLInputElement
      | HTMLSelectElement;
    const checked = (e.target as HTMLInputElement).checked;
    const files = (e.target as HTMLInputElement).files;

    const newValue = type === "checkbox" ? checked : files ? files[0] : value;
    if (name === "file" && newValue instanceof File) {
      setFileName(newValue?.name || "");
    }
    setUser((prevUser) => ({ ...prevUser, [name]: newValue }));
  };

  const openModal = (actionType: ActionType) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleSaveUser = async () => {
    if (
      !user.userId ||
      !user.nickName ||
      !user.job ||
      !user.phone ||
      (!isDetailView && !user.userPw)
    ) {
      toast.error("(*) 필드 모두 입력해주세요", {
        autoClose: 3000,
      });
      return;
    }

    setLoading(true);

    try {
      let response;
      const requestData = {
        userId: user.userId,
        userPw: user.userPw,
        nickName: user.nickName,
        phone: user.phone,
        job: user.job,
        newsAgree: user.newsAgree,
        adAgree: user.adAgree,
        file: user.file,
      };
      if (isDetailView) {
        if (!user.userIdx) return;
        response = await Api.User.update({
          ...requestData,
          stateFlag: user?.stateFlag !== undefined ? user.stateFlag : 0,
          userIdx: user?.userIdx,
        });
      } else {
        response = await Api.User.add(requestData);
      }

      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        if (isDetailView) {
          navigate(`/users${location.search}`);
        } else {
          navigate("/users");
        }
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  // Deleting a User is disabled
  const handleDeleteUser = async () => {
    if (!user.userIdx) return;
    setLoading(true);

    try {
      const response = await Api.User.delete(user.userIdx);
      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        navigate("/users");
      }, 1000);
    } catch (error) {
      console.error("Error deleting user:", error);
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, { autoClose: 3000 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer breadcrumbItems={breadcrumbItems} title="Members">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h4 className="card-title">회원 정보를 입력해주세요</h4>
              <AvForm className="mt-4">
                <Row>
                  <FormInputTypes
                    id="userId"
                    label="아이디"
                    placeholder="hello@example.com 이메일형식의 아이디를 입력해주세요"
                    type="email"
                    value={user.userId || ""}
                    onChange={handleChange}
                    initialErrorMessage="아이디를 입력해주세요"
                    validate={{ required: { value: !isDetailView } }}
                    isRequired
                  />
                </Row>
                <Row>
                  <FormInputTypes
                    id="userPw"
                    label="비밀번호"
                    placeholder="비밀번호를 입력해주세요"
                    type="password"
                    value={!isDetailView ? user.userPw ?? "" : "******"}
                    onChange={handleChange}
                    initialErrorMessage={
                      !isDetailView ? `비밀번호를 입력해주세요` : ""
                    }
                    validate={{ required: { value: !isDetailView } }}
                    isRequired={!isDetailView}
                  />
                </Row>
                <Row>
                  <FormInputTypes
                    id="nickName"
                    label="닉네임"
                    placeholder="닉네임을 입력해주세요"
                    type="text"
                    value={user.nickName || ""}
                    onChange={handleChange}
                    initialErrorMessage="닉네임을 입력해주세요"
                    validate={{ required: { value: !isDetailView } }}
                    isRequired
                  />
                </Row>
                <Row>
                  <FormInputTypes
                    id="job"
                    label="직업"
                    placeholder="직업을 입력해주세요"
                    type="text"
                    value={user.job || ""}
                    onChange={handleChange}
                    initialErrorMessage="직업을 입력해주세요"
                    validate={{ required: { value: !isDetailView } }}
                    isRequired
                  />
                </Row>
                <Row>
                  <FormInputTypes
                    id="phone"
                    label="전화번호"
                    placeholder="전화번호 ex) +82-10-1234-1234"
                    type="text"
                    value={user.phone || ""}
                    onChange={handleChange}
                    initialErrorMessage="전화번호를 입력해주세요"
                    validate={{ required: { value: !isDetailView } }}
                    isRequired
                  />
                </Row>
                {isDetailView && (
                  <Row className="mb-3">
                    <SelectorTypes
                      label="회원상태"
                      id="stateFlag"
                      options={filteredStatus}
                      value={user.stateFlag || 0}
                      onChange={handleChange}
                      isRequired
                    />
                  </Row>
                )}
                <Row className="mb-3">
                  <CustomLabel
                    htmlFor="customFileInput"
                    title="프로필 이미지"
                  />
                  <CustomFileInputTypes
                    fileName={fileName}
                    onChange={handleChange}
                  />
                </Row>
                {isDetailView && !user.file && user.imgPath && (
                  <Row className="mb-2">
                    <Col>
                      <Label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-form-label"
                      />
                      <CardImgContainer
                        style={{ marginLeft: "4px" }}
                        src={user.imgPath}
                        alt="Profile"
                        className="rounded avatar-lg"
                      />
                    </Col>
                  </Row>
                )}
                <Row className="mb-2">
                  <ToggleSwitch
                    label="뉴스 알림 동의"
                    id="newsAgree"
                    checked={user.newsAgree}
                    onChange={(e) => {
                      setUser((prevUser) => ({
                        ...prevUser,
                        newsAgree: e.target.checked,
                      }));
                    }}
                    extraClass="mt-2"
                  />
                </Row>
                <Row className="mb-3">
                  <ToggleSwitch
                    label="광고 알림 동의"
                    id="adAgree"
                    checked={user.adAgree}
                    onChange={(e) => {
                      setUser((prevUser) => ({
                        ...prevUser,
                        adAgree: e.target.checked,
                      }));
                    }}
                    extraClass="mt-2"
                  />
                </Row>
                <FormButtonsTypes
                  isDetailView={isDetailView}
                  openModal={openModal}
                  handleCancel={handleCancel}
                  hideDeleteButton={true}
                />
                <ConfirmModalTypes
                  isLoading={loading}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  action={action}
                  handleSave={handleSaveUser}
                  handleDelete={handleDeleteUser}
                />
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </PageContainer>
  );
};

export default UserForm;
