import * as ApiBase from "@metamorp/api-base";
import { ConnectionByKoreaRegion } from "@/utils/api/features/Statistics/ConnectionByKoreaRegion/type";
import { DateRange } from "../common/type";

const getConnectionByKoreaRegion = (data: DateRange) => {
  type Response = ApiBase.Types.ApiResBase<ConnectionByKoreaRegion>;
  return ApiBase.request<Response>({
    method: "POST",
    path: `/api/v1/statis/korea/state`,
    data: data,
  });
};

export default {
  /**
   * `GET /api/v1/statis/korea/state`
   *
   * @authorization-required
   *
   * Retrieve Connection by Korea Regions.
   */
  get: getConnectionByKoreaRegion,
};
