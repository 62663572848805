import { create } from "zustand";
import * as Api from "@/api";

type AppTypesStoreState = {
  appTypes: Api.Response.ApplicationItem[];
  loading: boolean;
  error: string | null;
  setAppTypes: (appTypes: Api.Response.ApplicationItem[]) => void;
  setLoading: (loading: boolean) => void;
};

const useAppTypesStore = create<AppTypesStoreState>((set) => ({
  appTypes: [],
  loading: false,
  error: null,
  setAppTypes: (appTypes) => set({ appTypes }),
  setLoading: (loading) => set({ loading }),
}));

export default useAppTypesStore;
