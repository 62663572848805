import * as ApiBase from "@metamorp/api-base";
import { ActiveUserRate } from "./type";
import { DateRange } from "../common/type";

const getActiveUserRate = (data: DateRange) => {
  type Response = ApiBase.Types.ApiResBase<ActiveUserRate>;
  return ApiBase.request<Response>({
    method: "POST",
    path: `/api/v1/statis/active/state`,
    data: data,
  });
};

const getActiveUserWeekRate = (data: DateRange) => {
  type Response = ApiBase.Types.ApiResBase<ActiveUserRate>;
  return ApiBase.request<Response>({
    method: "POST",
    path: `/api/v1/statis/active/week/state`,
    data: data,
  });
};

export default {
  /**
   * `GET /api/v1/statis/active/state`
   *
   * @authorization-required
   *
   * Retrieve App Active User Rate data.
   */
  get: getActiveUserRate,
  /**
   * `GET /api/v1/statis/active/state`
   *
   * @authorization-required
   *
   * Retrieve App Active User Rate data.
   */
  getActiveUserWeekRate: getActiveUserWeekRate,
};
