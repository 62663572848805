import * as ApiBase from "@metamorp/api-base";

export function createS3UploadAdapter(loader: any) {
  return {
    upload: () => {
      return loader.file.then((file: File) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("file", file);

          return ApiBase.request({
            method: "POST",
            path: "/api/v1/common/temp/file/upload",
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
            .then((response) => {
              if (response && response.data && response.data.filePath) {
                resolve({
                  default: response.data.filePath,
                });
              } else {
                reject("Upload failed");
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      });
    },
    abort: () => {
      console.log("Upload aborted");
    },
  };
}

export function imgUploadAdapterPlugin(editor: any) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
    return createS3UploadAdapter(loader);
  };
}
