import { useEffect, useRef, useState } from "react";
import { OptionProps } from "@/components/Common/types";
import { ImgContainer, ListHoverImage } from "@/pages/Utility/styled";
import { SENDING_STATUS_OPTIONS } from "../SendEmail/EmailList";
import { slicingStatusOptions } from "../Slicing";
import { SHOW_OPTIONS } from "./Options";

import materialImg from "../../assets/images/default-material.png";
import printerImg from "../../assets/images/default-printer.png";
import noImg from "../../assets/images/no-image.jpg";

type ClickableCellProps<T> = {
  value?: React.ReactNode;
  row: T;
  handleRowClick: (item: T) => void;
};

export const ClickableCell = <T,>({
  value,
  row,
  handleRowClick,
}: ClickableCellProps<T>) => {
  return (
    <span
      style={{ textDecoration: "underline", cursor: "pointer" }}
      onClick={() => handleRowClick(row)}
    >
      {value}
    </span>
  );
};

//fix any type
export const SlicingStatusCell = ({ value }: any) => {
  let color = "";
  switch (value) {
    case "receipt":
      color = "#fcb92c";
      break;
    case "progress":
      color = "#5664d2";
      break;
    case "complete":
      color = "#1cbb8c";
      break;
    case "fail":
      color = "#ff3d5f";
      break;
    default:
      color = "black";
      break;
  }

  return (
    <span style={{ color: color }}>
      {slicingStatusOptions.find((option) => option.value === value)?.label}
    </span>
  );
};

export const EmailStatusCell = ({ value }: any) => {
  const COLORS = ["#1cbb8c", "#ff3d5f", "#6e829f"];
  const color = COLORS[value - 1] ?? "black";

  return (
    <span style={{ color: color }}>
      {SENDING_STATUS_OPTIONS.find((option) => option.value === value)?.label}
    </span>
  );
};

type DisplayCellProps = {
  value: string | number;
  options?: OptionProps[];
};

const COLORS = ["#75788d", "#1cbb8c"];
export const DisplayCellType = ({ value, options }: DisplayCellProps) => {
  const color = COLORS[Number(value)] ?? "black";

  return (
    <span style={{ color: color }}>
      {options?.length
        ? options.find((option) => option.value === value)?.label
        : SHOW_OPTIONS.find((option) => option.value === value)?.label}
    </span>
  );
};

type ImageCellProps = {
  value: string;
  isUser?: boolean;
  type?: string;
};

export const ImageCellTypes = ({ value, isUser, type }: ImageCellProps) => {
  const containerRef = useRef(null);
  const [shouldLoad, setShouldLoad] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  let defaultImg;

  if (type === "material") {
    defaultImg = materialImg;
  } else if (type === "printer") {
    defaultImg = printerImg;
  } else {
    defaultImg = noImg;
  }

  useEffect(() => {
    if (!containerRef.current) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setShouldLoad(true);
          observer.disconnect();
        }
      },
      { rootMargin: "50px" },
    );

    observer.observe(containerRef.current);
    return () => observer.disconnect();
  }, [value]);

  return (
    <ImgContainer ref={containerRef}>
      {shouldLoad ? (
        <>
          <img
            src={value || defaultImg}
            alt=""
            style={{
              width: "50px",
              height: "50px",
              borderRadius: isUser ? "50%" : "0",
            }}
            loading="lazy"
            onLoad={() => setIsLoaded(true)}
          />
          {isLoaded && value && (
            <ListHoverImage
              style={{
                backgroundImage: `url(${value})`,
              }}
            />
          )}
        </>
      ) : (
        <img
          src={defaultImg}
          alt=""
          style={{ width: "50px", height: "50px", border: "0" }}
        />
      )}
    </ImgContainer>
  );
};
