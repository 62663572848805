import { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import * as Api from "@/api";
import CommonControlPanel from "@/components/Common/CommonControlPanel";
import CustomTableContainerTypes, {
  CustomColumn,
} from "@/components/Common/CustomTableContainerTypes";
import PageContainer from "@/components/Common/PageContainer";
import useQueryParams, { useStateRef } from "@/helpers/hooks";

const BREADCRUMB_ITEMS = [
  { title: "Email Expiration", link: "#" },
  { title: "List", link: "#" },
];

const EmailExpiration = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pageSize, page, keyword, dateRange } = useQueryParams();

  const [emailList, setEmailList] = useState<Api.Response.EmailExpItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalEmails, setTotalEmails] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const searchKeywordRef = useStateRef(searchKeyword);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(dateRange);
  const [clearTrigger, setClearTrigger] = useState(false);

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: selectedDates[0],
      endDt: selectedDates[1],
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    selectedDates,
    searchKeyword,
    location.pathname,
    navigate,
  ]);

  const fetchEmailList = useCallback(async () => {
    setIsLoading(true);
    try {
      const searchKeyword = searchKeywordRef.current;
      const requestData = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.EmailExpiration.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalEmails(response.paging.totalRecord);
      setEmailList(response.data);
    } catch (error) {
      console.error("Error fetching Email List", error);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, customPageSize, selectedDates[1], clearTrigger]);

  useEffect(() => {
    fetchEmailList();
  }, [fetchEmailList]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchEmailList();
  };

  const clearFilters = async () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const columns: CustomColumn<Api.Response.EmailExpItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "emailIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "이메일",
        accessor: "email",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "요청일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "인증일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.EmailExpItem>) =>
          value ? value : "-",
      },
      {
        Header: "만료일자",
        accessor: "expireDt",
        disableFilters: true,
        filterable: false,
      },
    ],
    [],
  );

  return (
    <PageContainer breadcrumbItems={BREADCRUMB_ITEMS} title="Email Expiration">
      <CommonControlPanel
        placeholder="이메일로 검색해주세요."
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        clearFilters={clearFilters}
        onSearch={handleSearch}
        dateRange={selectedDates}
        setDateRange={setSelectedDates}
      />
      <CustomTableContainerTypes
        columns={columns || []}
        data={emailList || []}
        customPageSize={customPageSize}
        totalPage={totalPage}
        totalRecord={totalEmails}
        setCustomPageSize={setCustomPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoading={isLoading}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
      />
    </PageContainer>
  );
};

export default EmailExpiration;
