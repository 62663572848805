import * as ApiBase from "@metamorp/api-base";
import { RecentUsers } from "@/utils/api/features/Statistics/RecentUsersList/type";
import { DateRange } from "../common/type";

const getRecentUsers = (data: DateRange) => {
  type Response = ApiBase.Types.ApiResBase<
    RecentUsers[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response>({
    method: "POST",
    path: `/api/v1/statis/recent/users`,
    data: data,
  });
};

export default {
  /**
   * `GET /api/v1/statis/recent/users`
   *
   * @authorization-required
   *
   * Retrieve Recently Connected Users data.
   */
  getList: getRecentUsers,
};
