import * as Api from "@/api";
import RequestRegionSeoul from "@/pages/Dashboard/RequestRegionSeoul";
import StatisticsBase from "./StatisticsBase";

const ConnectionBySeoulRegion = () => {
  return (
    <>
      <StatisticsBase
        title="Connection By Seoul Region"
        defaultRange={30}
        columns={[
          {
            key: "label",
            name: "Seoul Region",
            label: "서울 지역",
          },
          { key: "count", name: "Number of requests", label: "요청수" },
        ]}
        ChartComponent={RequestRegionSeoul}
        errorMessage="Failed fetching Api Request Connection by Seoul Regions"
        onRequestData={Api.ConnectionBySeoulRegion.get}
        getDataList={(data) => data?.list || []}
        getDataForExport={(data) => data?.list || []}
      />
    </>
  );
};

export default ConnectionBySeoulRegion;
