import { ReactNode, useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import { Spinner } from "reactstrap";
import * as Api from "@/api";
import ChartBase from "./ChartBase";
import { formatNumber, getDate } from "./utils";

type ApiRequestProps = {
  data: Api.Response.ApiRequestState | null;
  selectedDays?: number;
  onDaysSelection?: (days: number) => void;
  isHeader?: boolean;
  isFullWidth?: boolean;
  isLoading?: boolean;
  children?: ReactNode;
};

const ApiRequest = ({
  data,
  selectedDays,
  onDaysSelection,
  isHeader,
  children,
  isLoading,
}: ApiRequestProps) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstanceRef = useRef<ApexCharts | null>(null);

  useEffect(() => {
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!chartRef.current || !data || isLoading) return;
    const chartOptions = {
      series: [
        {
          type: "line",
          name: "API요청",
          data: data?.apiList,
        },
        {
          type: "line",
          name: "AI요청",
          data: data?.aiList,
        },
      ],
      chart: {
        type: "line",
        height: 290,
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: false,
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 6,
          left: 1,
          blur: 4,
          color: "#000",
          opacity: 0.12,
        },
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        padding: {
          top: 2,
          right: 2,
          bottom: 2,
          left: 2,
        },
        borderColor: "#f1f1f1",
        strokeDashArray: 3,
      },
      markers: {
        size: 4,
        hover: {
          size: 3,
        },
      },
      colors: ["rgb(54,253,48)", "rgb(230,0,125)"],
      stroke: {
        curve: "straight",
        width: 2,
        dashArray: 2,
      },
      legend: {
        show: true,
        position: "top",
        fontSize: "10px",
        fontFamily: "Poppins",
        markers: {
          width: 7,
          height: 7,
        },
      },
      yaxis: {
        Show: true,
        labels: {
          show: true,
          formatter: formatNumber,
        },
      },
      xaxis: {
        show: false,
        type: "day",
        categories: data?.dateList,
        labels: {
          rotate: -45,
          rotateAlways: true,
          formatter: getDate,
        },
        axisBorder: {
          show: false,
          color: "rgba(119, 119, 142, 0.05)",
          offsetX: 0,
          offsetY: 0,
        },
      },
    };

    // If chart instance exists, update it instead of creating a new one
    if (chartInstanceRef.current) {
      chartInstanceRef.current?.updateOptions(chartOptions, true, true);
      chartInstanceRef.current?.updateSeries(
        [{ data: data?.apiList }, { data: data?.aiList }],
        true,
      );
    } else {
      // Create new chart instance if it doesn't exist
      chartInstanceRef.current = new ApexCharts(chartRef.current, chartOptions);
      chartInstanceRef.current.render();
    }
  }, [data]);

  return (
    <ChartBase
      title="API요청현황"
      tooltipId="apiReqInfo"
      tooltipContent="선택기간동안 API를 요청한 수입니다."
      selectedDays={selectedDays}
      onDaysSelection={onDaysSelection}
      dayOptions={[7, 14, 30]}
      redirectUrl="/api-request-status"
      minHeight="20.625rem"
      isHeader={isHeader}
    >
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center position-absolute top-0 start-0 end-0 bottom-0">
          <Spinner className="me-2" color="secondary" />
        </div>
      )}
      <div ref={chartRef}></div>
      {children}
    </ChartBase>
  );
};

export default ApiRequest;
