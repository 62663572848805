import * as Api from "@/api";
import StatisticsBase from "./StatisticsBase";
import ActiveUserWeekRate from "../Dashboard/ActiveUserWeekRate";

const ActiveUserWeekRateStatus = () => {
  return (
    <StatisticsBase
      title="Active User Rate"
      defaultRange={30}
      columns={[
        { key: "date", name: "Date", label: "일자" },
        { key: "activeRate", name: "App Activity(%)", label: "앱활성율" },
        { key: "connectedCnt", name: "Visitors", label: "방문자수" },
      ]}
      ChartComponent={ActiveUserWeekRate}
      errorMessage="Failed fetching Active User Rate data"
      onRequestData={Api.ActiveUserRate.getActiveUserWeekRate}
      getDataList={(data) => data?.list || []}
      getDataForExport={(data) => data?.list || []}
    />
  );
};

export default ActiveUserWeekRateStatus;
