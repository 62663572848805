import { create } from "zustand";
import * as Api from "@/api";

type PrinterStoreState = {
  printers: Api.Response.PrinterItem[];
  loading: boolean;
  setPrinters: (printers: Api.Response.PrinterItem[]) => void;
  setLoading: (loading: boolean) => void;
};

const usePrinterStore = create<PrinterStoreState>((set) => ({
  printers: [],
  loading: false,
  setPrinters: (printers) => set({ printers }),
  setLoading: (loading) => set({ loading }),
}));

export default usePrinterStore;
