import * as ApiBase from "@metamorp/api-base";
import {
  AddMenu,
  LeftMenuItem,
  MenuItem,
  UpdateMenu,
} from "@/utils/api/features/MenuControl/types";

const updateMenu = (data: UpdateMenu) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, UpdateMenu>({
    method: "PUT",
    path: "/api/v1/menu/update",
    data: data,
  });
};

const getMenuList = (data: { pMenuIdx: number }) => {
  type Response = ApiBase.Types.ApiResBase<MenuItem[]>;
  return ApiBase.request<Response>({
    method: "POST",
    path: "/api/v1/menu/list",
    data: data,
  });
};

const addMenu = (data: AddMenu) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, AddMenu>({
    method: "POST",
    path: "/api/v1/menu/add",
    data: data,
  });
};

const deleteMenu = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response>({
    method: "DELETE",
    path: `/api/v1/menu/delete/${idx}`,
  });
};

const getLeftMenuList = () => {
  type Response = ApiBase.Types.ApiResBase<LeftMenuItem[]>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/menu/left/list",
  });
};

export default {
  /**
   * `PUT /api/v1/menu/update`
   *
   * 메뉴 정보를 수정합니다.
   *
   * Update menu information.
   */
  update: updateMenu,
  /**
   * `POST /api/v1/menu/list`
   *
   * 메뉴 목록을 조회합니다.
   *
   * Retrieve menu list(main, sub).
   */
  getList: getMenuList,
  /**
   * `POST /api/v1/menu/add`
   *
   * 메뉴 정보를 추가합니다.
   *
   * Add a menu information.
   */
  add: addMenu,
  /**
   * `DELETE /api/v1/menu/delete/{menuIdx}`
   *
   * 메뉴를 삭제합니다.
   *
   * Delete a menu.
   */
  delete: deleteMenu,
  /**
   * `GET /api/v1/menu/left/list`
   *
   *  좌측메뉴 목록을 조회합니다.
   *
   * Retrieve left side Menu list.
   */
  getLeftMenuList,
};
