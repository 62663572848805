import * as ApiBase from "@metamorp/api-base";
import {
  CommonApplicationItem,
  CommonClassItem,
  CommonClientItem,
  DefaultMaterialItem,
  DefaultPrinterItem,
  MaterialItem,
  SaveFormatItem,
  PrinterItem,
} from "./types";

const getSaveFormatList = () => {
  type Response = ApiBase.Types.ApiResBase<SaveFormatItem[]>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/common/save/format/list",
  });
};

const getMaterialManufList = () => {
  type Response = ApiBase.Types.ApiResBase<MaterialItem[]>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/common/material/manuf/list",
  });
};

const getPrinterManufList = () => {
  type Response = ApiBase.Types.ApiResBase<PrinterItem[]>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/common/printer/manuf/list",
  });
};

const getDefaultPrinterList = () => {
  type Response = ApiBase.Types.ApiResBase<DefaultPrinterItem[]>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/common/default/printer/list",
  });
};

const getManufPrinterList = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<DefaultPrinterItem[]>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/common/default/printer/list/${idx}`,
  });
};

const getDefaultMaterialList = () => {
  type Response = ApiBase.Types.ApiResBase<DefaultMaterialItem[]>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/common/default/material/list",
  });
};

const getManufMaterialList = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<DefaultMaterialItem[]>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/common/default/material/list/${idx}`,
  });
};

const getClientList = () => {
  type Response = ApiBase.Types.ApiResBase<CommonClientItem[]>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/common/client/list",
  });
};

const getClassList = () => {
  type Response = ApiBase.Types.ApiResBase<CommonClassItem[]>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/common/class/list",
  });
};

const getApplicationList = () => {
  type Response = ApiBase.Types.ApiResBase<CommonApplicationItem[]>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/common/application/list",
  });
};

export default {
  /**
   * `GET /api/v1/common/save/format/list`
   *
   * 저장 포맷 목록을 조회합니다.
   *
   * Retrieve a list of save formats.
   */
  getSaveFormatList,
  /**
   * `GET /api/v1/common/material/manuf/list`
   *
   * 소재 제조사 목록을 조회합니다.
   *
   * Retrieve a list of material manufacturers.
   */
  getMaterialManufList,
  /**
   * `GET /api/v1/common/printer/manuf/list`
   *
   * 프린터 제조사 목록을 조회합니다.
   *
   * Retrieve a list of printer manufacturers.
   */
  getPrinterManufList,
  /**
   * `GET /api/v1/common/default/printer/list`
   *
   * 기본 프린터 목록을 조회합니다.
   *
   * Retrieve a list of default printers.
   */
  getDefaultPrinterList,
  /**
   * `GET /api/v1/common/default/printer/list/${pmIdx}`
   *
   * 제조사의 기본 프린터 목록을 조회합니다.
   *
   * Retrieve a list of default printers of a Manufacturer.
   */
  getManufPrinterList,
  /**
   * `GET /api/v1/common/default/material/list`
   *
   * 기본 소재 목록을 조회합니다.
   *
   * Retrieve a list of default materials.
   */
  getDefaultMaterialList,
  /**
   * `GET /api/v1/common/default/material/list/${mmIdx}`
   *
   * 제조사의 기본 소재 목록을 조회합니다.
   *
   * Retrieve a list of default materials of a Manufacturer.
   */
  getManufMaterialList,
  /**
   * `GET /api/v1/common/client/list`
   *
   * 고객사 목록을 조회합니다.
   *
   * Retrieve a list of clients.
   */
  getClientList,
  /**
   * `GET /api/v1/common/class/list`
   *
   * 클래스 목록을 조회합니다.
   *
   * Retrieve a list of classes.
   */
  getClassList,
  /**
   * `GET /api/v1/common/application/list`
   *
   * 어플리케이션 목록을 조회합니다.
   *
   * Retrieve a list of applications.
   */
  getApplicationList,
};
