import { useState } from "react";
import { Button, Input } from "reactstrap";
import * as Api from "@/api";
import { ActionType } from "@/components/Common/ConfirmModalTypes";

export type Group = Api.Response.GroupItem | Api.Request.AddGroup;

type GroupRowProps = {
  data: Group;
  isExistingMenu?: boolean;
  onChange: (groupName: Group) => void;
  openModal: (action: ActionType, groupData?: Api.Response.GroupItem) => void;
  onRowClick?: () => void;
  isSelected?: boolean;
};

const GroupRow = ({
  data,
  isExistingMenu,
  onChange,
  openModal,
  onRowClick,
  isSelected,
}: GroupRowProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [initialData, setInitialData] = useState(data);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    const newValue = type === "number" ? parseInt(value, 10) : value;
    onChange({ ...data, [name]: newValue });
  };

  const renderInputField = (
    value: string | null | undefined,
    name: string,
    placeholder: string,
  ) => (
    <Input
      type="text"
      name={name}
      className="form-control "
      placeholder={placeholder}
      value={value || ""}
      onChange={handleInputChange}
    />
  );

  const renderCell = (
    value: string | null | undefined,
    count: number,
    name: string,
    placeholder: string,
  ) => {
    if (!isExistingMenu) {
      return renderInputField(value, name, placeholder);
    }

    if (isExistingMenu && isEditing) {
      return renderInputField(value, name, placeholder);
    }

    return (
      <span>
        {value || "-"}{" "}
        {count > 0 && (
          <span className="badge rounded-pill bg-danger">{count}</span>
        )}
      </span>
    );
  };

  const handleEditClick = () => {
    if (!isEditing) {
      setInitialData({ ...data });
    }

    if (isEditing) {
      openModal("edit");
    }
    setIsEditing(!isEditing);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    if (isExistingMenu) {
      onChange(initialData);
    }
  };

  const handleRowClick = (e: React.MouseEvent<HTMLTableRowElement>) => {
    const target = e.target as HTMLElement;
    if (
      !target.closest("button") &&
      !target.closest("input") &&
      onRowClick &&
      !isEditing
    ) {
      onRowClick();
    }
  };

  return (
    <tr
      data-group-idx={
        isExistingMenu ? (data as Api.Response.GroupItem).roleIdx : "new"
      }
      onClick={handleRowClick}
      style={{
        cursor: onRowClick && !isEditing ? "pointer" : "default",
        backgroundColor: isSelected ? "#f8f9fa" : "inherit",
      }}
    >
      <td>
        {isExistingMenu ? (data as Api.Response.GroupItem)?.roleIdx : "-"}
      </td>
      <td className="w-50">
        {renderCell(
          data.name,
          (data as Api.Response.GroupItem)?.adminCnt,
          "name",
          "권한명을 입력해주세요.",
        )}
      </td>
      <td>
        {isExistingMenu ? (
          <div className="d-flex flex-wrap justify-content-center align-items-center gap-1">
            <Button
              color={isEditing ? "warning" : "primary"}
              className="btn btn-sm btn-primary mb-2 d-flex align-items-center"
              onClick={handleEditClick}
            >
              <i className="ri-pencil-fill me-2"></i>{" "}
              {isEditing ? "저장" : "수정"}
            </Button>

            <Button
              color={isEditing ? "dark" : "danger"}
              className="btn btn-sm btn-dark mb-2 d-flex align-items-center"
              onClick={isEditing ? handleCancelEdit : () => openModal("delete")}
            >
              <i
                className={
                  isEditing ? "ri-close-line me-2" : "ri-delete-bin-5-fill me-2"
                }
              ></i>
              {isEditing ? "취소" : "삭제"}
            </Button>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Button
              type="button"
              color="success"
              className="waves-effect waves-light btn-sm d-flex align-items-center"
              onClick={() => openModal("save")}
              disabled={!data.name}
            >
              <i className="ri-add-line me-2"></i>
              등록
            </Button>
          </div>
        )}
      </td>
      <td>{"updName" in data ? data.updName || "-" : "-"}</td>
      <td>{"updDt" in data ? data.updDt || "-" : "-"}</td>
    </tr>
  );
};

export default GroupRow;
