import * as ApiBase from "@metamorp/api-base";
import {
  AddManufacturer,
  GetManufacturerList,
  Manufacturer,
  ManufacturerItem,
  UpdateManufacturer,
} from "@/utils/api/features/MaterialManufacturers/types";

const updateManufacturer = (data: UpdateManufacturer) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, UpdateManufacturer>({
    method: "PUT",
    path: "/api/v1/material/manuf/update",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getManufacturerList = (data: GetManufacturerList) => {
  type Response = ApiBase.Types.ApiResBase<
    ManufacturerItem[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response, GetManufacturerList>({
    method: "POST",
    path: "/api/v1/material/manuf/list",
    data: data,
  });
};

const addManufacturer = (data: AddManufacturer) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, AddManufacturer>({
    method: "POST",
    path: "/api/v1/material/manuf/add",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getManufacturer = (mmIdx: number) => {
  type Response = ApiBase.Types.ApiResBase<Manufacturer>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/material/manuf/${mmIdx}`,
  });
};

const deleteManufacturer = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response>({
    method: "DELETE",
    path: `/api/v1/material/manuf/${idx}`,
  });
};

export default {
  /**
   * `PUT /api/v1/material/manuf/update`
   *
   * @authorization-required
   *
   * Update a Material Manufacturer.
   */
  update: updateManufacturer,
  /**
   * `POST /api/v1/material/manuf/list`
   *
   * @authorization-required
   *
   * Retrieve a list of Manufacturers.
   */
  getList: getManufacturerList,
  /**
   * `POST /api/v1/material/manuf/add`
   *
   * @authorization-required
   *
   * Add a Manufacturer.
   */
  add: addManufacturer,
  /**
   * `GET /api/v1/material/manuf/:mmIdx`
   *
   * @authorization-required
   *
   * Retrieve a Manufacturer.
   */
  get: getManufacturer,
  /**
   * `DELETE /api/v1/material/manuf/:idx`
   *
   * 어플리케이션을 삭제합니다.
   *
   * Delete a Manufacturer.
   */
  delete: deleteManufacturer,
};
