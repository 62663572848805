import React, { RefObject, useEffect, useRef, useState } from "react";
import { Container, Row, Spinner } from "reactstrap";
import * as XLSX from "xlsx";
import * as Api from "@/api";
import DailyModelProcessing from "@/pages/Dashboard/DailyModelProcessing";
import RequestByDay from "@/pages/Dashboard/RequestByDay";
import RequestByTime from "@/pages/Dashboard/RequestByTime";
import RequestRegionKorea from "@/pages/Dashboard/RequestRegionKorea";
import RequestRegionSeoul from "@/pages/Dashboard/RequestRegionSeoul";
import ActiveUserRate from "./ActiveUserRate";
import ActiveUserWeekRate from "./ActiveUserWeekRate";
import ApiRequest from "./ApiRequest";
import CardRow from "./CardRow";
import ConnectionChart from "./ConnectionChart";
import JoinChart from "./JoinChart";
import RecentUsers from "./RecentUsers";
import RequestByCountry from "./RequestByCountry";
import RequestByRegion from "./RequestByRegion";
import TopConnected from "./TopConnected";
import TopRequestModel from "./TopRequestModel";
import TopRequestRegion from "./TopRequestRegion";
import UsageByFunction from "./UsageByFunction";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const sheetNameMapping: Record<keyof Api.Response.RawData, string> = {
  cumulativeMembershipList: "누적회원가입목록",
  monthlyMembershipList: "월간가입목록",
  weeklyMembershipList: "주간가입목록",
  monthlyAccessList: "월간접속목록",
  weeklyAccessList: "주간접속목록",
  dailyAccessList: "일간접속목록",
  monthlyAiRequestList: "월간AI요청목록",
  weeklyAiRequestList: "주간AI요청목록",
  dailyAiRequestList: "일간AI요청목록",
  monthlySlicingRequestList: "월간슬라이싱요청목록",
  weeklySlicingRequestList: "주간슬라이싱요청목록",
  dailySlicingRequestList: "일간슬라이싱요청목록",
  monthlyAiSlicingProcessingList: "월간AI슬라이싱처리목록",
  weeklyAiSlicingProcessingList: "주간AI슬라이싱처리목록",
  dailyAiSlicingProcessingList: "일간AI슬라이싱처리목록",
};

type SectionKey =
  | "stats"
  | "connection"
  | "apiRequest"
  | "timeAndDay"
  | "region"
  | "top"
  | "active"
  | "activeweek"
  | "country"
  | "usage";

type RowRefs = {
  [key: string]: RefObject<HTMLDivElement>;
};

const BREADCRUMB_ITEMS = [
  { title: "Metamorp", link: "/" },
  { title: "Dashboard", link: "#" },
];

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);

  const [userRegData, setUserRegData] =
    useState<Api.Response.UserRegistration | null>(null);

  const [userConnectData, setUserConnectData] =
    useState<Api.Response.UserConnection | null>(null);

  const [aiRequestData, setAiRequestData] =
    useState<Api.Response.UserAiRequest | null>(null);

  const [slicingRequestData, setSlicingRequestData] =
    useState<Api.Response.UserSlicingRequest | null>(null);

  const [aiProcessingData, setAiProcessingData] =
    useState<Api.Response.UserAiProcessingRequest | null>(null);

  const [connectionState, setConnectionState] =
    useState<Api.Response.ConnectionState>({
      dateList: [],
      pvList: [],
      uvList: [],
    });

  const [requestByTime, setRequestByTime] =
    useState<Api.Response.RequestByTime>({
      labelList: [],
      cntList: [],
    });

  const [requestByDay, setRequestByDay] = useState<Api.Response.RequestByDay>({
    labelList: [],
    cntList: [],
  });

  const [requestRegSeoul, setRequestRegSeoul] =
    useState<Api.Response.RequestRegSeoul>({
      labelList: [],
      cntList: [],
    });

  const [requestRegKorea, setRequestRegKorea] =
    useState<Api.Response.RequestRegKorea>({
      labelList: [],
      cntList: [],
    });

  const [registrationState, setRegistrationState] =
    useState<Api.Response.RegistrationState>({
      dateList: [],
      cntList: [],
    });

  const [apiRequestState, setApiRequestState] =
    useState<Api.Response.ApiRequestState>({
      dateList: [],
      apiList: [],
      aiList: [],
    });

  const [topRequestModel, setTopRequestModel] =
    useState<Api.Response.TopRequestModel>({
      classCodeList: [],
      cntList: [],
    });

  const [topRequestRegion, setTopRequestRegion] =
    useState<Api.Response.TopRequestRegion>({
      countryList: [],
      reqCntList: [],
      userCntList: [],
    });

  const [topConnected, setTopConnected] = useState<
    Api.Response.TopConnectedUser[]
  >([]);

  const [activeRate, setActiveRate] = useState<Api.Response.ActiveRate>({
    dateList: [],
    connectedCntList: [],
    activeRateList: [],
  });

  const [activeWeekRate, setActiveWeekRate] = useState<Api.Response.ActiveRate>(
    {
      dateList: [],
      connectedCntList: [],
      activeRateList: [],
    },
  );

  const [recentUsers, setRecentUsers] = useState<
    Api.Response.RecentConnectedUser[]
  >([]);

  const [modelProcessing, setModelProcessing] =
    useState<Api.Response.ModelProcessing>({
      labelList: [],
      cntList: [],
    });

  const [requestCountry, setRequestCountry] = useState<
    Api.Response.RequestCountry[]
  >([]);

  const [requestRegion, setRequestRegion] = useState<
    Api.Response.RequestRegion[]
  >([]);

  const [usageByFunction, setUsageByFunction] =
    useState<Api.Response.UsedFunction>({
      dateList: [],
      cnt0List: [],
      cnt1List: [],
      cnt2List: [],
      cnt3List: [],
      cnt4List: [],
      cnt5List: [],
      cnt6List: [],
      cnt7List: [],
      cnt8List: [],
      cnt9List: [],
      cnt10List: [],
      cnt11List: [],
      cnt12List: [],
    });
  const [usageByFunctionLoading, setUsageByFunctionLoading] = useState(false);
  const [isConnectionLoading, setIsConnectionLoading] = useState(false);
  const [isRegistrationLoading, setIsRegistrationLoading] = useState(false);
  const [isApiReqLoading, setIsApiReqLoading] = useState(false);
  const [isReqModelLoading, setIsReqModelLoading] = useState(false);
  const [isReqTimeLoading, setIsReqTimeLoading] = useState(false);
  const [isReqDayLoading, setIsReqDayLoading] = useState(false);
  const [isRegSeoulLoading, setIsRegSeoulLoading] = useState(false);
  const [isRegKoreaLoading, setIsRegKoreaLoading] = useState(false);
  const [isTopRegionLoading, setIsTopRegionLoading] = useState(false);
  const [isTopConnectedLoading, setIsTopConnectedLoading] = useState(false);
  const [isUserRateLoading, setIsUserRateLoading] = useState(false);
  const [isUserWeekRateLoading, setIsUserWekkRateLoading] = useState(false);
  const [isRecentUsersLoading, setIsRecentUsersLoading] = useState(false);
  const [isModelProcessLoading, setIsModelProcessLoading] = useState(false);
  const [isReqByCountryLoading, setIsReqByCountryLoading] = useState(false);
  const [isReqByRegionLoading, setIsReqByRegionLoading] = useState(false);

  const [connectionDays, setConnectionDays] = useState(30);
  const [registrationDays, setRegistrationDays] = useState(30);
  const [apiReqDays, setApiReqDays] = useState(30);
  const [reqModelDays, setReqModelDays] = useState(90);
  const [requestByTimeDays, setRequestByTimeDays] = useState(30);
  const [requestByDayDays, setRequestByDayDays] = useState(30);
  const [requestRegSeoulDays, setRequestRegSeoulDays] = useState(30);
  const [requestRegKoreaDays, setRequestRegKoreaDays] = useState(30);
  const [reqRegionDays, setReqRegionDays] = useState(90);
  const [activeDays, setActiveDays] = useState(30);
  const [activeWeekDays, setActiveWeekDays] = useState(30);
  const [reqCountryDays, setReqCountryDays] = useState(90);
  const [modelProcessDays, setModelProcessDays] = useState(30);
  const [reqKoreaRegionDays, setKoreaReqRegionDays] = useState(90);
  const [usageByFuncDays, setUsageByFuncDays] = useState(14);

  const rowRefs: RowRefs = {
    statsRow: useRef<HTMLDivElement>(null),
    connectionRow: useRef<HTMLDivElement>(null),
    apiRequestRow: useRef<HTMLDivElement>(null),
    timeAndDayRow: useRef<HTMLDivElement>(null),
    regionRow: useRef<HTMLDivElement>(null),
    topRow: useRef<HTMLDivElement>(null),
    activeRow: useRef<HTMLDivElement>(null),
    activeWeekRow: useRef<HTMLDivElement>(null),
    countryRow: useRef<HTMLDivElement>(null),
    usageRow: useRef<HTMLDivElement>(null),
  };

  const [loadedSections, setLoadedSections] = useState({
    stats: false,
    connection: false,
    apiRequest: false,
    timeAndDay: false,
    region: false,
    top: false,
    active: false,
    activeweek: false,
    country: false,
    usage: false,
  });

  const fetchUserRegistration = async () => {
    try {
      const response = await Api.Dashboard.getUserRegistration();
      setUserRegData(response.data);
    } catch (error) {
      console.error("Failed fetching User Registration data", error);
    }
  };

  const fetchUserConnection = async () => {
    try {
      const response = await Api.Dashboard.getUserConnection();
      setUserConnectData(response.data);
    } catch (error) {
      console.error("Failed fetching User Connection data", error);
    }
  };

  const fetchAiRequest = async () => {
    try {
      const response = await Api.Dashboard.getAiData();
      setAiRequestData(response.data);
    } catch (error) {
      console.error("Failed fetching Ai Request data", error);
    }
  };

  const fetchSlicingRequest = async () => {
    try {
      const response = await Api.Dashboard.getSlicingData();
      setSlicingRequestData(response.data);
    } catch (error) {
      console.error("Failed fetching Slicing Request data", error);
    }
  };

  const fetchAiProcessingRequest = async () => {
    try {
      const response = await Api.Dashboard.getAiProcessingData();
      setAiProcessingData(response.data);
    } catch (error) {
      console.error("Failed fetching Ai Processing Rate data", error);
    }
  };

  const fetchConnectionChart = async (days: number) => {
    setIsConnectionLoading(true);
    try {
      const response = await Api.Dashboard.getConnectionState(days);
      setConnectionState(response.data);
    } catch (error) {
      console.error("Failed fetching Connection State data", error);
    } finally {
      setIsConnectionLoading(false);
    }
  };

  const fetchRegistrationChart = async (days: number) => {
    setIsRegistrationLoading(true);
    try {
      const response = await Api.Dashboard.getRegistrationState(days);
      setRegistrationState(response.data);
    } catch (error) {
      console.error("Failed fetching Registration State data", error);
    } finally {
      setIsRegistrationLoading(false);
    }
  };

  const fetchApiRequestChart = async (days: number) => {
    setIsApiReqLoading(true);
    try {
      const response = await Api.Dashboard.getApiRequestState(days);
      setApiRequestState(response.data);
    } catch (error) {
      console.error("Failed fetching API Request State data", error);
    } finally {
      setIsApiReqLoading(false);
    }
  };

  const fetchTopRequestModel = async (days: number) => {
    setIsReqModelLoading(true);
    try {
      const response = await Api.Dashboard.getTopRequestModel(days);
      setTopRequestModel(response.data);
    } catch (error) {
      console.error("Failed fetching Top Request Model data", error);
    } finally {
      setIsReqModelLoading(false);
    }
  };

  const fetchRequestByTime = async (days: number) => {
    setIsReqTimeLoading(true);
    try {
      const response = await Api.Dashboard.getRequestByTime(days);
      setRequestByTime(response.data);
    } catch (error) {
      console.error("Failed fetching Requests by Time data", error);
    } finally {
      setIsReqTimeLoading(false);
    }
  };

  const fetchRequestByDay = async (days: number) => {
    setIsReqDayLoading(true);
    try {
      const response = await Api.Dashboard.getRequestByDay(days);
      setRequestByDay(response.data);
    } catch (error) {
      console.error("Failed fetching Requests by Day of the week data", error);
    } finally {
      setIsReqDayLoading(false);
    }
  };

  const fetchRequestRegSeoul = async (days: number) => {
    setIsRegSeoulLoading(true);
    try {
      const response = await Api.Dashboard.getRequestRegSeoul(days);
      setRequestRegSeoul(response.data);
    } catch (error) {
      console.error("Failed fetching Requests by region Seoul", error);
    } finally {
      setIsRegSeoulLoading(false);
    }
  };

  const fetchRequestRegKorea = async (days: number) => {
    setIsRegKoreaLoading(true);
    try {
      const response = await Api.Dashboard.getRequestRegKorea(days);
      setRequestRegKorea(response.data);
    } catch (error) {
      console.error("Failed fetching Requests by Korea Cities", error);
    } finally {
      setIsRegKoreaLoading(false);
    }
  };

  const fetchTopRequestRegion = async (days: number) => {
    setIsTopRegionLoading(true);
    try {
      const response = await Api.Dashboard.getTopRequestRegion(days);
      setTopRequestRegion(response.data);
    } catch (error) {
      console.error("Failed fetching Top Request Region data", error);
    } finally {
      setIsTopRegionLoading(false);
    }
  };

  const fetchTopConnected = async () => {
    setIsTopConnectedLoading(true);
    try {
      const response = await Api.Dashboard.getTopConnectedUser();
      setTopConnected(response.data);
    } catch (error) {
      console.error("Failed fetching Top Connected Users data", error);
    } finally {
      setIsTopConnectedLoading(false);
    }
  };

  const fetchActiveUserRate = async (days: number) => {
    setIsUserRateLoading(true);
    try {
      const response = await Api.Dashboard.getActiveUserRate(days);
      setActiveRate(response.data);
    } catch (error) {
      console.error("Failed fetching Top Request Region data", error);
    } finally {
      setIsUserRateLoading(false);
    }
  };

  const fetchActiveUserWeekRate = async (days: number) => {
    setIsUserWekkRateLoading(true);
    try {
      const response = await Api.Dashboard.getActiveUserWeekRate(days);
      setActiveWeekRate(response.data);
    } catch (error) {
      console.error("Failed fetching Top Request Region data", error);
    } finally {
      setIsUserWekkRateLoading(false);
    }
  };

  const fetchRecentlyConnectedUsers = async () => {
    setIsRecentUsersLoading(true);
    try {
      const response = await Api.Dashboard.getRecentUsers();
      setRecentUsers(response.data);
    } catch (error) {
      console.error("Failed fetching Recently Connected Users data", error);
    } finally {
      setIsRecentUsersLoading(false);
    }
  };

  const fetchModelProcessing = async (days: number) => {
    setIsModelProcessLoading(true);
    try {
      const response = await Api.Dashboard.getModelProcessing(days);
      setModelProcessing(response.data);
    } catch (error) {
      console.error("Failed fetching Daily Model Processing", error);
    } finally {
      setIsModelProcessLoading(false);
    }
  };

  const fetchRequestCountries = async (days: number) => {
    setIsReqByCountryLoading(true);
    try {
      const response = await Api.Dashboard.getRequestByCountry(days);
      setRequestCountry(response.data);
    } catch (error) {
      console.error("Failed fetching Request By Country data", error);
    } finally {
      setIsReqByCountryLoading(false);
    }
  };

  const fetchRequestRegions = async (days: number) => {
    setIsReqByRegionLoading(true);
    try {
      const response = await Api.Dashboard.getRequestByRegion(days);
      setRequestRegion(response.data);
    } catch (error) {
      console.error("Failed fetching Request By Country data", error);
    } finally {
      setIsReqByRegionLoading(false);
    }
  };

  const fetchUsageByFunction = async (days: number) => {
    setUsageByFunctionLoading(true);
    try {
      const response = await Api.Dashboard.getUseByFunction(days);
      setUsageByFunction(response.data);
    } catch (error) {
      console.error("Failed fetching Frequency of use by Function data", error);
    } finally {
      setUsageByFunctionLoading(false);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await Promise.all([
          fetchUserRegistration(),
          fetchUserConnection(),
          fetchAiRequest(),
          fetchSlicingRequest(),
          fetchAiProcessingRequest(),
        ]);

        // Mark initial data as loaded to enable intersection observers
        setIsInitialDataLoaded(true);
      } catch (error) {
        console.error("Error loading initial data:", error);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (!isInitialDataLoaded) return;
    const cleanupFunctions: Array<() => void> = [];

    const observerOptions = {
      root: null, // viewport
      rootMargin: "100px", // start loading when element is 100px from viewport
      threshold: 0.1, // trigger when at least 10% of the element is visible
    };

    const createObserver = (
      elementRef: RefObject<HTMLDivElement>,
      sectionKey: SectionKey,
      loadFunction: () => void,
    ) => {
      if (!elementRef.current || loadedSections[sectionKey]) return null;

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !loadedSections[sectionKey]) {
            // Load data for this section
            loadFunction();

            // Mark this section as loaded
            setLoadedSections((prev) => ({
              ...prev,
              [sectionKey]: true,
            }));

            // Disconnect observer after loading
            observer.disconnect();
          }
        });
      }, observerOptions);

      observer.observe(elementRef.current);
      return () => observer.disconnect();
    };

    // Set up observers for each row
    if (!loadedSections.connection) {
      const cleanup = createObserver(
        rowRefs.connectionRow,
        "connection",
        () => {
          fetchConnectionChart(connectionDays);
          fetchRegistrationChart(registrationDays);
        },
      );
      if (cleanup) cleanupFunctions.push(cleanup);
    }

    if (!loadedSections.apiRequest) {
      const cleanup = createObserver(
        rowRefs.apiRequestRow,
        "apiRequest",
        () => {
          fetchApiRequestChart(apiReqDays);
          fetchTopRequestModel(reqModelDays);
        },
      );
      if (cleanup) cleanupFunctions.push(cleanup);
    }

    if (!loadedSections.timeAndDay) {
      const cleanup = createObserver(
        rowRefs.timeAndDayRow,
        "timeAndDay",
        () => {
          fetchRequestByTime(requestByTimeDays);
          fetchRequestByDay(requestByDayDays);
        },
      );
      if (cleanup) cleanupFunctions.push(cleanup);
    }

    if (!loadedSections.region) {
      const cleanup = createObserver(rowRefs.regionRow, "region", () => {
        fetchRequestRegSeoul(requestRegSeoulDays);
        fetchRequestRegKorea(requestRegKoreaDays);
      });
      if (cleanup) cleanupFunctions.push(cleanup);
    }

    if (!loadedSections.top) {
      const cleanup = createObserver(rowRefs.topRow, "top", () => {
        fetchTopRequestRegion(reqRegionDays);
        fetchTopConnected();
      });
      if (cleanup) cleanupFunctions.push(cleanup);
    }

    if (!loadedSections.active) {
      const cleanup = createObserver(rowRefs.activeRow, "active", () => {
        fetchActiveUserRate(activeDays);
        fetchRecentlyConnectedUsers();
      });
      if (cleanup) cleanupFunctions.push(cleanup);
    }

    if (!loadedSections.activeweek) {
      const cleanup = createObserver(
        rowRefs.activeWeekRow,
        "activeweek",
        () => {
          fetchActiveUserWeekRate(activeWeekDays);
          fetchModelProcessing(modelProcessDays);
        },
      );
      if (cleanup) cleanupFunctions.push(cleanup);
    }

    if (!loadedSections.country) {
      const cleanup = createObserver(rowRefs.countryRow, "country", () => {
        fetchRequestCountries(reqCountryDays);
        fetchRequestRegions(reqKoreaRegionDays);
      });
      if (cleanup) cleanupFunctions.push(cleanup);
    }

    if (!loadedSections.usage) {
      const cleanup = createObserver(rowRefs.usageRow, "usage", () => {
        fetchUsageByFunction(usageByFuncDays);
      });
      if (cleanup) cleanupFunctions.push(cleanup);
    }

    return () => {
      cleanupFunctions.forEach((cleanup) => cleanup());
    };
  }, [isInitialDataLoaded]);

  const fetchRawData = async () => {
    setIsLoading(true);

    try {
      const response = await Api.Dashboard.getRawData();
      return response.data;
    } catch (error) {
      console.error("Failed fetching Raw data", error);
      setIsLoading(false);
      return null;
    } finally {
      setIsLoading(false);
    }
  };
  const handleExcelDownload = async () => {
    const rawData = await fetchRawData();

    if (!rawData) {
      console.error("No data available to export");
      return;
    }

    const columns = [
      { name: "RegDt", key: "regDt" },
      { name: "UserId", key: "userId" },
      { name: "NickName", key: "nickName" },
      { name: "Url", key: "url" },
      { name: "ApiName", key: "apiName" },
    ];

    const wb = XLSX.utils.book_new();
    Object.entries(rawData).forEach(([key, list]) => {
      const sheetName = sheetNameMapping[key as keyof Api.Response.RawData];
      const header = [columns.map((column) => column.name)];
      const rows = list.map((item) =>
        columns.map(
          (column) => (item as Record<string, string>)[column.key] || "",
        ),
      );
      const ws = XLSX.utils.aoa_to_sheet([...header, ...rows]);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
    });

    XLSX.writeFile(wb, "raw_data.xlsx");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            isButton
            isLoading={isLoading}
            onHandleExcelDownload={handleExcelDownload}
            title="Dashboard"
            breadcrumbItems={BREADCRUMB_ITEMS}
          />
          <div ref={rowRefs.stats}>
            {userRegData &&
            userConnectData &&
            aiRequestData &&
            slicingRequestData &&
            aiProcessingData ? (
              <CardRow
                registration={userRegData}
                connection={userConnectData}
                ai={aiRequestData}
                slicing={slicingRequestData}
                aiProcess={aiProcessingData}
              />
            ) : (
              <div className="d-flex justify-content-center align-items-center ">
                <Spinner className="mb-4" color="secondary" />
              </div>
            )}
          </div>
          <div ref={rowRefs.connectionRow}>
            <Row>
              {(loadedSections.connection || connectionState) && (
                <ConnectionChart
                  data={connectionState}
                  selectedDays={connectionDays}
                  onDaysSelection={(days) => {
                    setConnectionDays(days);
                    fetchConnectionChart(days);
                  }}
                  isHeader
                  isLoading={isConnectionLoading}
                />
              )}
              {(loadedSections.connection || registrationState) && (
                <JoinChart
                  data={registrationState}
                  selectedDays={registrationDays}
                  onDaysSelection={(days) => {
                    setRegistrationDays(days);
                    fetchRegistrationChart(days);
                  }}
                  isHeader
                  isLoading={isRegistrationLoading}
                />
              )}
            </Row>
          </div>
          <div ref={rowRefs.apiRequestRow}>
            <Row>
              {(loadedSections.apiRequest || apiRequestState) && (
                <ApiRequest
                  data={apiRequestState}
                  selectedDays={apiReqDays}
                  onDaysSelection={(days) => {
                    setApiReqDays(days);
                    fetchApiRequestChart(days);
                  }}
                  isHeader
                  isLoading={isApiReqLoading}
                />
              )}
              {(loadedSections.apiRequest || topRequestModel) && (
                <TopRequestModel
                  data={topRequestModel}
                  selectedDays={reqModelDays}
                  onDaysSelection={(days) => {
                    setReqModelDays(days);
                    fetchTopRequestModel(days);
                  }}
                  isHeader
                  isLoading={isReqModelLoading}
                />
              )}
            </Row>
          </div>
          <div ref={rowRefs.timeAndDayRow}>
            <Row>
              {(loadedSections.timeAndDay || requestByTime) && (
                <RequestByTime
                  data={requestByTime}
                  selectedDays={requestByTimeDays}
                  onDaysSelection={(days) => {
                    setRequestByTimeDays(days);
                    fetchRequestByTime(days);
                  }}
                  isHeader
                  isLoading={isReqTimeLoading}
                />
              )}
              {(loadedSections.timeAndDay || requestByDay) && (
                <RequestByDay
                  data={requestByDay}
                  selectedDays={requestByDayDays}
                  onDaysSelection={(days) => {
                    setRequestByDayDays(days);
                    fetchRequestByDay(days);
                  }}
                  isHeader
                  isLoading={isReqDayLoading}
                />
              )}
            </Row>
          </div>
          <div ref={rowRefs.regionRow}>
            <Row>
              {(loadedSections.region || requestRegSeoul) && (
                <RequestRegionSeoul
                  data={requestRegSeoul}
                  selectedDays={requestRegSeoulDays}
                  onDaysSelection={(days) => {
                    setRequestRegSeoulDays(days);
                    fetchRequestRegSeoul(days);
                  }}
                  isHeader
                  isLoading={isRegSeoulLoading}
                />
              )}
              {(loadedSections.region || requestRegKorea) && (
                <RequestRegionKorea
                  data={requestRegKorea}
                  selectedDays={requestRegKoreaDays}
                  onDaysSelection={(days) => {
                    setRequestRegKoreaDays(days);
                    fetchRequestRegKorea(days);
                  }}
                  isHeader
                  isLoading={isRegKoreaLoading}
                />
              )}
            </Row>
          </div>
          <div ref={rowRefs.topRow}>
            <Row>
              {(loadedSections.top || topRequestRegion) && (
                <TopRequestRegion
                  data={topRequestRegion}
                  selectedDays={reqRegionDays}
                  onDaysSelection={(days) => {
                    setReqRegionDays(days);
                    fetchTopRequestRegion(days);
                  }}
                  isHeader
                  isLoading={isTopRegionLoading}
                />
              )}
              {(loadedSections.top || topConnected) && (
                <TopConnected
                  data={topConnected}
                  isHeader
                  isLoading={isTopConnectedLoading}
                />
              )}
            </Row>
          </div>
          <div ref={rowRefs.activeRow}>
            <Row>
              {(loadedSections.active || activeRate) && (
                <ActiveUserRate
                  data={activeRate}
                  selectedDays={activeDays}
                  onDaysSelection={(days) => {
                    setActiveDays(days);
                    fetchActiveUserRate(days);
                  }}
                  isHeader
                  isLoading={isUserRateLoading}
                />
              )}
              {(loadedSections.active || recentUsers) && (
                <RecentUsers
                  data={recentUsers}
                  isHeader
                  isLoading={isRecentUsersLoading}
                />
              )}
            </Row>
          </div>
          <div ref={rowRefs.activeWeekRow}>
            <Row>
              {(loadedSections.activeweek || activeWeekRate) && (
                <ActiveUserWeekRate
                  data={activeWeekRate}
                  selectedDays={activeWeekDays}
                  onDaysSelection={(days) => {
                    setActiveWeekDays(days);
                    fetchActiveUserWeekRate(days);
                  }}
                  isHeader
                  isLoading={isUserWeekRateLoading}
                />
              )}
              {(loadedSections.activeweek || modelProcessing) && (
                <DailyModelProcessing
                  data={modelProcessing}
                  selectedDays={modelProcessDays}
                  onDaysSelection={(days) => {
                    setModelProcessDays(days);
                    fetchModelProcessing(days);
                  }}
                  isHeader
                  isLoading={isModelProcessLoading}
                />
              )}
            </Row>
          </div>
          <div ref={rowRefs.modelRow}>
            <Row></Row>
          </div>
          <div ref={rowRefs.countryRow}>
            <Row>
              {(loadedSections.country || requestCountry) && (
                <RequestByCountry
                  data={requestCountry}
                  selectedDays={reqCountryDays}
                  onDaysSelection={(days) => {
                    setReqCountryDays(days);
                    fetchRequestCountries(days);
                  }}
                  isHeader
                  isLoading={isReqByCountryLoading}
                />
              )}
              {(loadedSections.country || requestRegion) && (
                <RequestByRegion
                  data={requestRegion}
                  isHeader
                  selectedDays={reqKoreaRegionDays}
                  onDaysSelection={(days) => {
                    setKoreaReqRegionDays(days);
                    fetchRequestRegions(days);
                  }}
                  isLoading={isReqByRegionLoading}
                />
              )}
            </Row>
          </div>
          <div ref={rowRefs.usageRow}>
            <Row>
              {(loadedSections.usage || usageByFunction) && (
                <UsageByFunction
                  data={usageByFunction}
                  selectedDays={usageByFuncDays}
                  onDaysSelection={(days) => {
                    setUsageByFuncDays(days);
                    fetchUsageByFunction(days);
                  }}
                  isLoading={usageByFunctionLoading}
                  isHeader
                />
              )}
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Dashboard;
