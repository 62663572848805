import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import userDefault from "@/assets/images/users/userDefault.png";

const ProfileMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const [userName, setUserName] = useState("");
  const toggle = () => setMenu((prevMenu) => !prevMenu);

  // let username = "Admin";
  // const authUser = localStorage.getItem("authUser");
  // if (authUser) {
  //   const obj = JSON.parse(authUser);
  //   const uNm = obj.email.split("@")[0];
  //   username = uNm.charAt(0).toUpperCase() + uNm.slice(1);
  // }

  useEffect(() => {
    const storedUserName = localStorage.getItem("username") || "Admin";
    setUserName(storedUserName);
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="d-inline-block user-dropdown"
      >
        <DropdownToggle
          tag="button"
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
        >
          <img
            className="rounded-circle header-profile-user me-1"
            src={userDefault}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-1 text-transform text-capitalize">
            {userName}
          </span>
          <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem href="#" onClick={() => navigate("/account")}>
            <i className="ri-user-line align-middle me-1"></i> {t("Profile")}
          </DropdownItem>
          {/* <DropdownItem href="#">
            <i className="ri-wallet-2-line align-middle me-1"></i>{" "}
            {t("My Wallet")}
          </DropdownItem>
          <DropdownItem className="d-block" href="#">
            <span className="badge badge-success float-end mt-1">11</span>
            <i className="ri-settings-2-line align-middle me-1"></i>{" "}
            {t("Settings")}
          </DropdownItem>
          <DropdownItem href="#">
            <i className="ri-lock-unlock-line align-middle me-1"></i>{" "}
            {t("Lock screen")}
          </DropdownItem> */}
          <DropdownItem divider />
          <DropdownItem className="text-danger" href="/logout">
            <i className="ri-shut-down-line align-middle me-1 text-danger"></i>{" "}
            {t("Logout")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
