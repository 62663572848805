import { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import { ToastContainer } from "react-toastify";
import { Button } from "reactstrap";
import * as Api from "@/api";
import CommonControlPanel from "@/components/Common/CommonControlPanel";
import CustomTableContainerTypes, {
  CustomColumn,
} from "@/components/Common/CustomTableContainerTypes";
import DeleteModal from "@/components/Common/DeleteModal";
import { OptionProps } from "@/components/Common/types";
import useQueryParams, {
  useLocalizedMessage,
  useStateRef,
} from "@/helpers/hooks";
import { ClickableCell, ImageCellTypes } from "@/pages/Utility/CustomCellsType";
import { handleDeleteSupport } from "./utils";
import PageContainer from "../../components/Common/PageContainer";
import useSupportProfileStore from "../../zustandStore/supportProfileStore";
import { CertifyCell, DisplayCell, RegTypeCell } from "../Utility/CustomCells";
import { REGISTRATION_OPTIONS, SHOW_OPTIONS } from "../Utility/Options";

const BREADCRUMB_ITEMS = [
  { title: "Support Profile", link: "#" },
  { title: "List", link: "#" },
];

const SupportProfile = () => {
  const { supports, setSupports, loading, setLoading } =
    useSupportProfileStore();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const getLocalizedMessage = useLocalizedMessage();

  const { pageSize, page, keyword, dateRange, parseQueryParam } =
    useQueryParams();

  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalSupports, setTotalSupports] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const searchKeywordRef = useStateRef(searchKeyword);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(dateRange);
  const [applicationTypes, setApplicationTypes] = useState<OptionProps[]>([]);
  const [selectedAppType, setSelectedAppType] = useState(() => {
    const parsedAppType = parseQueryParam(queryParams.appType);
    return parsedAppType !== null ? parsedAppType : 0;
  });
  const [selectedRegType, setSelectedRegType] = useState(() => {
    const parsedRegType = parseQueryParam(queryParams.regType);
    return parsedRegType !== null
      ? parsedRegType
      : REGISTRATION_OPTIONS[0].value;
  });
  const [selectedShow, setSelectedShow] = useState(() => {
    const parsedShow = parseQueryParam(queryParams.show);
    return parsedShow !== null ? parsedShow : SHOW_OPTIONS[0].value;
  });
  const [clearTrigger, setClearTrigger] = useState(false);
  const [supportToDelete, setSupportToDelete] =
    useState<Api.Response.SupportProfileItem | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRowClick = useCallback(
    (item: { supportIdx: number }) => {
      navigate(`/support-profile/${item.supportIdx}${window.location.search}`);
    },
    [navigate],
  );

  const handleAddSupport = () => {
    navigate(`/support-profile/new${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: selectedDates[0],
      endDt: selectedDates[1],
      appType: selectedAppType,
      regType: selectedRegType,
      show: selectedShow,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    selectedDates,
    searchKeyword,
    selectedAppType,
    selectedRegType,
    selectedShow,
    location.pathname,
    navigate,
  ]);

  const fetchSupports = useCallback(async () => {
    setLoading(true);

    try {
      const searchKeyword = searchKeywordRef.current;
      const requestData = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        displayFlag: selectedShow,
        defaultFlag: selectedRegType,
        applicationsType: selectedAppType,
        startDt: dateRange[0] ? format(dateRange[0], "yyyy-MM-dd") : "",
        endDt: dateRange[1] ? format(dateRange[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.SupportProfile.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalSupports(response.paging.totalRecord);
      setSupports(response.data);
    } catch (error) {
      console.error("Error fetching Support Profiles", error);
    } finally {
      setLoading(false);
    }
  }, [
    currentPage,
    customPageSize,
    selectedRegType,
    selectedShow,
    dateRange[1],
    selectedAppType,
    clearTrigger,
  ]);

  useEffect(() => {
    fetchSupports();
  }, [fetchSupports]);

  useEffect(() => {
    const fetchApplicationTypes = async () => {
      const response = await Api.Common.getApplicationList();
      const applications = response.data;
      const updatedApplications = [
        {
          label: "전체",
          value: 0,
        },
        ...applications.map((app) => ({
          label: app.name,
          value: app.applicationsIdx,
        })),
      ];
      setApplicationTypes(updatedApplications);
    };
    fetchApplicationTypes();
  }, []);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchSupports();
  };

  const handleAppTypeSearch = (newAppType: number) => {
    setSelectedAppType(newAppType);
    setCurrentPage(0);
  };

  const handleRegTypeSearch = (newRegType: number) => {
    setSelectedRegType(newRegType);
    setCurrentPage(0);
  };

  const handleShowSearch = (newShow: number) => {
    setSelectedShow(newShow);
    setCurrentPage(0);
  };

  const handleDateSearch = (newDateRange: [string, string]) => {
    setSelectedDates(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setSelectedRegType(REGISTRATION_OPTIONS[0].value);
    setSelectedShow(SHOW_OPTIONS[0].value);
    setCurrentPage(0);
    setSelectedAppType(0);
    setClearTrigger((prev) => !prev);
  };

  const handleIsModalOpen = (item: Api.Response.SupportProfileItem) => {
    setSupportToDelete(item);
    setIsModalOpen(true);
  };

  const selectProps = [
    {
      selectTitle: "구분",
      options: applicationTypes,
      selectedOption: selectedAppType,
      setSelectedOption: handleAppTypeSearch,
    },
    {
      selectTitle: "기본프로파일여부",
      options: REGISTRATION_OPTIONS,
      selectedOption: selectedRegType,
      setSelectedOption: handleRegTypeSearch,
    },
    {
      selectTitle: "표시여부",
      options: SHOW_OPTIONS,
      selectedOption: selectedShow,
      setSelectedOption: handleShowSearch,
    },
  ];

  const handleDelete = async () => {
    if (!supportToDelete) return;
    const supportIdx = supportToDelete.supportIdx;
    await handleDeleteSupport({
      supportIdx,
      navigate,
      setLoading,
      getLocalizedMessage,
    });
    setIsModalOpen(false);
    fetchSupports();
  };

  const columns: CustomColumn<Api.Response.SupportProfileItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "supportIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "고객사명",
        accessor: "clientName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "구분",
        accessor: "applicationsImgPath",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
        }: CellProps<Api.Response.SupportProfileItem>) => (
          <ImageCellTypes value={value} />
        ),
      },
      {
        Header: "Matched",
        accessor: "profileCnt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "프로파일명",
        accessor: "profileName",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
          row,
        }: CellProps<Api.Response.SupportProfileItem>) => (
          <ClickableCell
            value={value}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "기본프로파일여부",
        accessor: "defaultFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
        }: CellProps<Api.Response.SupportProfileItem>) => (
          <RegTypeCell value={value} />
        ),
      },
      {
        Header: "표시여부",
        accessor: "displayFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
        }: CellProps<Api.Response.SupportProfileItem>) => (
          <DisplayCell value={value} />
        ),
      },
      {
        Header: "인증여부",
        accessor: "certifiFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
        }: CellProps<Api.Response.SupportProfileItem>) => (
          <CertifyCell value={value} />
        ),
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "선택",
        accessor: "select",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }: CellProps<Api.Response.SupportProfileItem>) => (
          <Button
            color="danger"
            className="btn btn-sm waves-effect waves-light me-1 d-flex justify-content-center align-items-center"
            onClick={() => handleIsModalOpen(row.original)}
          >
            <i className="ri-delete-bin-line pe-1 " />
            삭제
          </Button>
        ),
      },
    ],
    [handleRowClick],
  );

  return (
    <PageContainer breadcrumbItems={BREADCRUMB_ITEMS} title="Support Profile">
      <CommonControlPanel
        placeholder="프로파일명, 등록자, 수정자명으로 검색해주세요."
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        clearFilters={clearFilters}
        onSearch={handleSearch}
        dateRange={selectedDates}
        setDateRange={handleDateSearch}
        select={selectProps}
      />
      <CustomTableContainerTypes
        btnTitle="프로파일 등록"
        sortByIdx="supportIdx"
        handleAddItem={handleAddSupport}
        columns={columns || []}
        data={supports || []}
        customPageSize={customPageSize}
        totalPage={totalPage}
        totalRecord={totalSupports}
        setCustomPageSize={setCustomPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoading={loading}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
      />
      <DeleteModal
        isLoading={loading}
        data={supportToDelete?.profileName}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onHandleDelete={handleDelete}
      />
      <ToastContainer />
    </PageContainer>
  );
};

export default SupportProfile;
