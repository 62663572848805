import * as Api from "@/api";
import RequestByDay from "@/pages/Dashboard/RequestByDay";
import StatisticsBase from "./StatisticsBase";

const ConnectionByDay = () => {
  return (
    <>
      <StatisticsBase
        title="Connection By Day"
        defaultRange={30}
        columns={[
          { key: "label", name: "Connection By Day", label: "요일" },
          { key: "count", name: "Number of requests", label: "요청수" },
        ]}
        ChartComponent={(props) => <RequestByDay {...props} height="100%" />}
        errorMessage="Failed fetching Api Request Connection by Day data"
        onRequestData={Api.ConnectionByDay.get}
        getDataList={(data) => data?.list || []}
        getDataForExport={(data) => data?.list || []}
      />
    </>
  );
};

export default ConnectionByDay;
