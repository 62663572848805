import { ReactNode, useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import { Spinner } from "reactstrap";
import * as Api from "@/api";
import ChartBase from "./ChartBase";
import { formatNumber } from "./utils";

type TopRequestRegionProps = {
  data: Api.Response.TopRequestRegion | null;
  selectedDays?: number;
  onDaysSelection?: (days: number) => void;
  isHeader?: boolean;
  isFullWidth?: boolean;
  isLoading?: boolean;
  children?: ReactNode;
  height?: string;
};

const TopRequestRegion = ({
  data,
  selectedDays,
  onDaysSelection,
  isHeader,
  isFullWidth = false,
  isLoading,
  children,
  height,
}: TopRequestRegionProps) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstanceRef = useRef<ApexCharts | null>(null);

  useEffect(() => {
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (
      !chartRef.current ||
      !data ||
      !data.reqCntList ||
      !data.userCntList ||
      !data.countryList ||
      isLoading
    )
      return;

    const chartOptions = {
      series: [
        {
          name: "요청수",
          data: data?.reqCntList,
        },
        {
          name: "요청자수",
          data: data?.userCntList,
        },
      ],
      chart: {
        // stacked: true, // user goes on top of req
        toolbar: {
          show: false,
        },
        type: "bar",
        fontFamily: "'Roboto', sans-serif",
        fontWeight: "600",
        height: 290,
      },
      colors: [
        "var(--primary-color)",
        "rgb(227, 84, 212)",
        "rgb(255, 142, 111)",
        "rgb(255, 93, 159)",
      ],
      plotOptions: {
        bar: {
          columnWidth: "30%",
          borderRadiusApplication: "around",
          borderRadiusWhenStacked: "all",
          borderRadius: 3,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: "top",
        offsetX: 0,
        offsetY: 8,
        fontSize: "14px",
        markers: {
          width: 9,
          height: 9,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
      },
      grid: {
        borderColor: "rgba(0,0,0,0.1)",
        strokeDashArray: 3,
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        categories: data?.countryList,
      },
      yaxis: {
        labels: {
          show: true,
          formatter: formatNumber,
          tickAmount: 4,
        },
      },
    };

    // If chart instance exists, update it instead of creating a new one
    if (chartInstanceRef.current) {
      chartInstanceRef.current.updateOptions(chartOptions, true, true);
      chartInstanceRef.current.updateSeries(
        [{ data: data?.reqCntList }, { data: data?.userCntList }],
        true,
      );
    } else {
      // Create new chart instance if it doesn't exist
      chartInstanceRef.current = new ApexCharts(chartRef.current, chartOptions);
      chartInstanceRef.current.render();
    }
  }, [data]);

  return (
    <ChartBase
      title="TOP10 요청지역"
      tooltipId="reqRegionInfo"
      tooltipContent="선택기간동안 지역별 요청횟수 및 요청자수입니다."
      selectedDays={selectedDays}
      onDaysSelection={onDaysSelection}
      dayOptions={[30, 60, 90]}
      redirectUrl="/request-region-ranking"
      isHeader={isHeader}
      minHeight="20.625rem"
      height={height || "29.75rem"}
      isFullWidth={isFullWidth}
    >
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center position-absolute top-0 start-0 end-0 bottom-0">
          <Spinner className="me-2" color="secondary" />
        </div>
      )}
      <div>
        <div ref={chartRef}></div>
        {children}
      </div>
    </ChartBase>
  );
};

export default TopRequestRegion;
