import React, { useState } from "react";
import UsageActivityModal from "@/pages/Users/components/UsageActivityModal";
import HistoryCardItem from "@/pages/Users/Dashboard/components/HistoryCardItem";
import { UDHistoryResVo } from "@/utils/api/features/User/Dashboard/types";

type CardProps = {
  data: UDHistoryResVo[];
  userIdx: number;
};

const Card: React.FC<CardProps> = ({ data, userIdx }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openUserActivity = () => {
    setIsModalOpen(true);
  };

  return (
    <React.Fragment>
      <div className="card custom-card">
        <div className="card-header justify-content-between">
          <div className="card-title"> 사용기록</div>
          <button
            type="button"
            className="btn btn-sm bg-light btn-light text-muted"
            onClick={openUserActivity}
          >
            View All<i className="ti ti-arrow-narrow-right ms-1"></i>
          </button>
        </div>
        <div className="card-body">
          <ul className="list-unstyled custom-activity-list">
            {data.map((item) => (
              <HistoryCardItem
                key={item.logIdx}
                logIdx={item.logIdx}
                method={item.method}
                apiName={item.apiName}
                logType={item.logType}
                param={item.param}
                url={item.url}
                regDt={item.regDt}
                ip={item.ip}
              />
            ))}
          </ul>
        </div>
      </div>
      <UsageActivityModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        selectedUser={userIdx}
      />
    </React.Fragment>
  );
};

export default Card;
