import { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import * as Api from "@/api";
import CommonControlPanel from "@/components/Common/CommonControlPanel";
import CustomTableContainerTypes, {
  CustomColumn,
} from "@/components/Common/CustomTableContainerTypes";
import PageContainer from "@/components/Common/PageContainer";
import useQueryParams, { useStateRef } from "@/helpers/hooks";
import { DateCell } from "@/pages/Users";
import { ImageCellTypes } from "@/pages/Utility/CustomCellsType";

export const MEMBER_STATUS_OPTIONS = [
  { label: "정상", value: 0 },
  { label: "탈퇴", value: 1 },
  { label: "정지", value: 2 },
];

export const StatusCell = ({ value }: any) => {
  const COLORS = ["#1cbb8c", "#6e829f", "#ff3d5f"];
  const color = COLORS[value] ?? "black";

  return (
    <span style={{ color: color }}>
      {MEMBER_STATUS_OPTIONS.find((option) => option.value === value)?.label}
    </span>
  );
};

const BREADCRUMB_ITEMS = [
  { title: "Login Restriction", link: "#" },
  { title: "List", link: "#" },
];

const LoginRestriction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pageSize, page, keyword, dateRange } = useQueryParams();

  const [restrictedList, setRestrictedList] = useState<
    Api.Response.RestrictedItem[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const searchKeywordRef = useStateRef(searchKeyword);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(dateRange);
  const [clearTrigger, setClearTrigger] = useState(false);

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: selectedDates[0],
      endDt: selectedDates[1],
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    selectedDates,
    searchKeyword,
    location.pathname,
    navigate,
  ]);

  const fetchRestrictedList = useCallback(async () => {
    setIsLoading(true);
    try {
      const searchKeyword = searchKeywordRef.current;
      const requestData = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.LoginRestriction.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalUsers(response.paging.totalRecord);
      setRestrictedList(response.data);
    } catch (error) {
      console.error("Error fetching Restricted Users", error);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, customPageSize, selectedDates[1], clearTrigger]);

  useEffect(() => {
    fetchRestrictedList();
  }, [fetchRestrictedList]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchRestrictedList();
  };

  const clearFilters = async () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const columns: CustomColumn<Api.Response.RestrictedItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "userIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "프로필",
        accessor: "imgPath",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.RestrictedItem>) => (
          <ImageCellTypes value={value} isUser />
        ),
      },
      {
        Header: "ID",
        accessor: "userId",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "닉네임",
        accessor: "nickName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "전화번호",
        accessor: "phone",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "국가",
        accessor: "countryName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "회원상태",
        accessor: "stateFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.RestrictedItem>) => (
          <StatusCell value={value} />
        ),
      },
      {
        Header: "차단일자",
        accessor: "blockDt",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.RestrictedItem>) => (
          <DateCell value={value} />
        ),
      },
      {
        Header: "만료일자",
        accessor: "lockExpDt",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.RestrictedItem>) => (
          <DateCell value={value} />
        ),
      },
      {
        Header: "가입일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.RestrictedItem>) => (
          <DateCell value={value} />
        ),
      },
    ],
    [],
  );

  return (
    <PageContainer breadcrumbItems={BREADCRUMB_ITEMS} title="Login Restriction">
      <CommonControlPanel
        placeholder="이름, ID, 전화번호로 검색해주세요."
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        clearFilters={clearFilters}
        onSearch={handleSearch}
        dateRange={selectedDates}
        setDateRange={setSelectedDates}
      />
      <CustomTableContainerTypes
        columns={columns || []}
        data={restrictedList || []}
        customPageSize={customPageSize}
        totalPage={totalPage}
        totalRecord={totalUsers}
        setCustomPageSize={setCustomPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoading={isLoading}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
      />
    </PageContainer>
  );
};

export default LoginRestriction;
