import * as Api from "@/api";
import RequestRegionKorea from "@/pages/Dashboard/RequestRegionKorea";
import StatisticsBase from "./StatisticsBase";

const ConnectionByKoreaRegion = () => {
  return (
    <>
      <StatisticsBase
        title="Connection By Korea Region"
        defaultRange={30}
        columns={[
          {
            key: "label",
            name: "Korea Region",
            label: "대한민국 요청지역",
          },
          { key: "count", name: "Number of requests", label: "요청수" },
        ]}
        ChartComponent={(props) => (
          <RequestRegionKorea {...props} height="100%" />
        )}
        errorMessage="Failed fetching Api Request Connection by Korea Regions"
        onRequestData={Api.ConnectionByKoreaRegion.get}
        getDataList={(data) => data?.list || []}
        getDataForExport={(data) => data?.list || []}
      />
    </>
  );
};

export default ConnectionByKoreaRegion;
